import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { Box, Stack, Tooltip, styled } from "@mui/material";
import { Badge, DirectionIcon, IconButton } from "components_new";
import React, { FC, useMemo } from "react";

import { AttributesTable } from "features/zoning-uploader/AttributesTable";

import { BarChart } from "components";

import { LEFT_SIDE_DIRECTIONS, Measure, RoadSegmentDetailsBreakdown, SelectedVolume } from "types";

import { getDirectionLabel } from "utils/ui";

import { getLabelByCategories, getLabelByDimension } from "./ODAnalytics";

export interface RoadAttributeValue {
  label: string;
  value: any;
  mainAttribute?: boolean;
}

export interface RoadAttributeProps {
  values?: RoadAttributeValue[];
  openState: boolean;
  setOpenState: (openState: boolean) => void;
}

interface RoadAnalyticsProps {
  isPedestriansMode: boolean;
  selectedRoadVolume: SelectedVolume;
  selectedRoadVolumeId: string | null;
  selectedSegmentId: string;
  breakdowns: RoadSegmentDetailsBreakdown[];
  roadAttributeProps?: RoadAttributeProps;
  roadMetadataDimensions: Measure[];
  changeRoadVolumeId: (id: string) => void;
}

const RoadAnalyticsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-xxl);
  height: 100%;
`;

const GraphContainer = styled("div")<{ isPedestriansMode?: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-right: -1rem;
  height: ${({ isPedestriansMode }) => (isPedestriansMode ? "calc(100% - 150px + 53px)" : "calc(100% - 150px)")};
  padding-top: ${({ isPedestriansMode }) => (isPedestriansMode ? "var(--padding-lg);" : "0")};
`;

const DirectionGroup = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: var(--padding-lg) 0;
`;

const DirectionLabel = styled("div")`
  font-size: var(--font-size-sm);
  font-weight: bold;
  margin-right: var(--padding-md);
  line-height: 21px;
`;

const ChartWrapper = styled("div")`
  width: 336px;
  border-radius: 4px;
  border: 1px solid var(--color-border);
  margin-bottom: var(--padding-lg);
`;

const ShowMoreAttributeBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RoadAnalytics: FC<RoadAnalyticsProps> = ({
  isPedestriansMode,
  selectedRoadVolume,
  selectedRoadVolumeId,
  roadMetadataDimensions,
  breakdowns = [],
  roadAttributeProps,
  selectedSegmentId,
  changeRoadVolumeId,
}) => {
  const roadAttributes = useMemo(
    () =>
      roadAttributeProps?.values
        ?.filter((attribute) => roadAttributeProps?.openState === true || attribute.mainAttribute === true)
        .reduce((acc, item) => {
          acc[item.label] = item.value;
          return acc;
        }, {} as { [name: string]: any }),
    [roadAttributeProps],
  );

  const getDirectionBadge = (selectedRoadVolume: SelectedVolume, roadSegmentId: string, isFromToDirection: boolean) => {
    const direction = isFromToDirection ? selectedRoadVolume.ftDirection : selectedRoadVolume.tfDirection;

    return (
      <Badge
        clickable
        icon={<DirectionIcon direction={direction} />}
        onClick={() => {
          changeRoadVolumeId(roadSegmentId);
        }}
        variant={selectedRoadVolumeId === roadSegmentId ? "filled" : "outlined"}
        label={getDirectionLabel(direction)}
      />
    );
  };

  const getDirectionBadges = (selectedRoadVolume: SelectedVolume) => {
    if (selectedRoadVolume.tfDirection && LEFT_SIDE_DIRECTIONS.indexOf(selectedRoadVolume.tfDirection) !== -1) {
      return (
        <>
          {getDirectionBadge(selectedRoadVolume, selectedRoadVolume.tfSegmentId || "", false)}
          {getDirectionBadge(selectedRoadVolume, selectedRoadVolume.ftSegmentId, true)}
        </>
      );
    }

    return (
      <>
        {getDirectionBadge(selectedRoadVolume, selectedRoadVolume.ftSegmentId, true)}
        {selectedRoadVolume.tfDirection &&
          getDirectionBadge(selectedRoadVolume, selectedRoadVolume.tfSegmentId || "", false)}
      </>
    );
  };

  const segmentDetailOpenButton = (
    <Tooltip title={roadAttributeProps?.openState ? "See less attributes" : "See all attributes"}>
      <IconButton
        sx={{ padding: "0" }}
        onClick={() => roadAttributeProps?.setOpenState(!roadAttributeProps?.openState)}
        size="small"
      >
        {roadAttributeProps?.openState ? (
          <KeyboardDoubleArrowUpIcon fontSize="small" />
        ) : (
          <KeyboardDoubleArrowDownIcon fontSize="small" />
        )}
      </IconButton>
    </Tooltip>
  );

  return (
    <RoadAnalyticsContainer>
      {!isPedestriansMode && (
        <DirectionGroup>
          <DirectionLabel>Direction</DirectionLabel>
          <Stack direction={"row"} spacing={1}>
            {getDirectionBadges(selectedRoadVolume)}
          </Stack>
        </DirectionGroup>
      )}
      <GraphContainer isPedestriansMode={isPedestriansMode}>
        {roadAttributeProps ? (
          <ChartWrapper>
            <AttributesTable
              columnsRatio={[45, 55]}
              maxWidth={"336px"}
              tableHeadLabels={[
                "Attribute",
                <ShowMoreAttributeBox> Value {segmentDetailOpenButton} </ShowMoreAttributeBox>,
              ]}
              attributes={roadAttributes}
              headerClick={() => roadAttributeProps?.setOpenState(!roadAttributeProps?.openState)}
            />
          </ChartWrapper>
        ) : null}
        {breakdowns.length > 0 &&
          breakdowns.map((breakdown, index) => {
            const dimension = breakdown.dimensions[0];
            const data = breakdown.rows.map((row) => ({
              name: getLabelByCategories(row.categories[0], dimension, roadMetadataDimensions || []),
              value: row.value,
            }));

            return data.length > 0 ? (
              <ChartWrapper key={index}>
                <BarChart
                  key={index}
                  data={data}
                  layout="vertical"
                  title={getLabelByDimension(dimension, roadMetadataDimensions)}
                />
              </ChartWrapper>
            ) : null;
          })}
      </GraphContainer>
    </RoadAnalyticsContainer>
  );
};

export { RoadAnalytics };
