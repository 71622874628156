import {
  AllInclusive,
  ContentCut,
  DateRange,
  Delete,
  InsertDriveFile,
  Place,
  Polyline,
  Settings,
} from "@mui/icons-material";
import { Stack, Tooltip, Typography, styled } from "@mui/material";
import {
  Badge,
  Button,
  CircularProgress,
  ConfirmDialog,
  DialogContentText,
  Divider,
  IconButton,
  Popover,
  Switch,
  TextField,
} from "components_new";
import { FormikHelpers, useFormik } from "formik";
import { isEqual } from "lodash";
import React, { Dispatch, FC, SetStateAction, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { SaveErrorDialog } from "features/dataset-editor";

import { AreaName, FlexContainer, RoadClassSelector, SelectorRoadClasses } from "components";

import { useAppDispatch, useAppSelector, usePrompt } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";

import { themeColors } from "theme/themeConstants";

import { FocusAreaItem } from "types";

import { ZoningSelector } from "./ZoningSelector";

const LoaderContainer = styled(FlexContainer)`
  height: 100%;
  justify-content: center;
`;

const FormWrapper = styled("form")`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FieldsContainer = styled(Stack)`
  height: calc(100% - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 1rem;
  margin-right: -1rem;
`;

const DatasetName = styled("div")`
  display: flex;
  font-size: 16px;
  font-weight: 700;
  & .bp4-editable-text-content,
  .bp4-editable-text-input {
    width: 210px !important;
    padding-right: 0.5rem;
  }
`;

const AreaNameContainer = styled("div")`
  display: flex;
  align-items: center;
`;

const DrawControlsWrapper = styled("div")(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

const DrawControls = styled(FlexContainer)`
  justify-content: space-around;
  background: var(--color-text-field-gray);
  border-radius: 8px;
  padding: 15px 5px;
  margin-top: 0.5rem;
`;

const DrawIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "selected",
})<{ selected?: boolean }>(({ theme, selected }) => ({
  width: "35px",
  height: "35px",
  borderRadius: "8px",
  backgroundColor: selected ? "#000000" : "#ffffff",
  boxShadow: "var(--box-shadow-md)",
  border: `1px solid ${theme.palette.divider}`,

  "&:hover": {
    backgroundColor: selected ? "#000000" : "#ffffff",
  },

  "& svg": {
    height: "20px",
    width: "20px",

    color: selected ? "#ffffff" : "#000000",
  },

  "&.Mui-disabled svg": {
    color: theme.palette.text.disabled,
  },
}));

const ActionButtons = styled(FlexContainer)`
  margin: 1rem 0;
  justify-content: space-between;
`;

const SpaceBetweenContainer = styled(FlexContainer)`
  justify-content: space-between;
`;

const Label = styled("label")`
  font-size: 12px;
  font-weight: 600;
`;

const ComputeButton = styled(Button)`
  width: 100px;
`;

export interface DatasetForm {
  datasetName: string;
  description: string;
}

export interface MainPanelProps {
  draw: any;
  zoningOptions: string[];
  selectedZoning: string | undefined;
  showZones: boolean;
  showRoads: boolean;
  showGates: boolean;
  roadClasses: SelectorRoadClasses | null;
  areaOfInterest: FocusAreaItem | undefined;
  setSelectedZoning: (zoning: string, customZoningId?: string) => void;
  setPolygonSubtractMode: (isSubtractMode: boolean) => void;
  toggleGateEditorPanel: () => void;
  changeShowZones: () => void;
  changeShowRoads: () => void;
  changeShowGates: () => void;
  setRoadClasses: Dispatch<SetStateAction<SelectorRoadClasses | null>>;
  onAddFullEntireAreaPolygon: () => void;
  openValidationModal: () => void;
  setDrawModeActive: (isActive: boolean) => void;
  isDrawModeActive: boolean;
  isPolygonSelected: boolean;
  isPolygonSubtractMode: boolean;
}

export const MainPanel: FC<MainPanelProps> = memo(
  ({
    draw,
    zoningOptions,
    selectedZoning,
    showZones,
    showRoads,
    showGates,
    roadClasses,
    areaOfInterest,
    setSelectedZoning,
    setPolygonSubtractMode,
    toggleGateEditorPanel,
    changeShowZones,
    changeShowRoads,
    changeShowGates,
    setRoadClasses,
    onAddFullEntireAreaPolygon,
    openValidationModal,
    setDrawModeActive,
    isDrawModeActive,
    isPolygonSelected,
    isPolygonSubtractMode,
  }) => {
    const dispatch = useAppDispatch();
    const { datasetId } = useParams();

    const [isUnsavedChangesBeforeCompute, setIsUnsavedChangesBeforeCompute] = useState(false);
    const [isNeedToOpenComputeModalAfterSaving, setIsNeedToOpenComputeModalAfterSaving] = useState(false);
    const [isSaveErrorModalOpen, setIsSaveErrorModalOpen] = useState(false);

    const subareaState = useAppSelector((state) => state.analytics.subareaState);
    const ODDatasetConfiguration = useAppSelector((state) => state.analytics.ODDatasetConfig);
    const initialODDatasetConfiguration = useAppSelector((state) => state.analytics.savedODDatasetConfig);
    const ODDatasetConfigValidation = useAppSelector((state) => state.analytics.ODDatasetConfigValidation);
    const customZoningId = useAppSelector((state) => state.analytics.ODDatasetConfig.data?.customZoningId);
    const timePeriod = useAppSelector((state) => state.global.timePeriod);
    const loadingSubAreaPolygon = useAppSelector((state) => state.analytics.loadingSubareaPolygon);
    const loadingGenerateGates = useAppSelector((state) => state.analytics.loadingGeneratedGates);
    const addGateState = useAppSelector((state) => state.analytics.newGate.state);

    useEffect(() => {
      setIsSaveErrorModalOpen(Boolean(ODDatasetConfiguration.error));
    }, [ODDatasetConfiguration.error]);

    useEffect(() => {
      if (ODDatasetConfiguration.state === DataState.AVAILABLE && isNeedToOpenComputeModalAfterSaving) {
        setIsNeedToOpenComputeModalAfterSaving(false);
        openValidationModal();
      }
    }, [ODDatasetConfiguration.state, isNeedToOpenComputeModalAfterSaving, openValidationModal]);

    const handleSubmit = (
      values: DatasetForm,
      { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
      overwrite?: boolean,
    ) => {
      if (ODDatasetConfiguration.data && selectedZoning) {
        const { gates, subAreaGeometry, timePeriod, gateRoadClasses, licensedAreaId } = ODDatasetConfiguration.data;
        dispatch(
          analyticsActions.updateODDatasetConfig(ODDatasetConfiguration.data.datasetId, {
            datasetName: values.datasetName,
            gates,
            subAreaGeometry,
            description: values.description,
            timePeriod,
            gateRoadClasses,
            licensedAreaId,
            zoningLevel: selectedZoning,
            customZoningId,
            ...(!overwrite && {
              expectedVersion: ODDatasetConfiguration.data.version,
            }),
          }),
        );
        setSubmitting(false);
      }
    };

    const formik = useFormik<DatasetForm>({
      enableReinitialize: true,
      initialValues: {
        datasetName: ODDatasetConfiguration.data?.datasetName || "",
        description: ODDatasetConfiguration.data?.description || "",
      },
      onSubmit: handleSubmit,
    });

    const { values, initialValues, errors, isSubmitting, touched, dirty, setFieldValue, setSubmitting, handleReset } =
      formik;

    const areChangesPending = useMemo(() => {
      if (ODDatasetConfiguration.data && initialValues.datasetName && !dirty) {
        const currentODConfiguration = {
          ...ODDatasetConfiguration.data,
          datasetName: values.datasetName,
          ...(values.description && { description: values.description }),
          zoningLevel: selectedZoning,
          ...(customZoningId ? { customZoningId } : {}),
        };
        return !isEqual(initialODDatasetConfiguration, currentODConfiguration);
      }
      return false;
    }, [
      ODDatasetConfiguration.data,
      initialODDatasetConfiguration,
      selectedZoning,
      customZoningId,
      values.datasetName,
      values.description,
      dirty,
      initialValues.datasetName,
    ]);

    usePrompt("Changes that you made may not be saved.", areChangesPending);

    const handleCreateNewDataset = (datasetName: string, description: string) => {
      if (ODDatasetConfiguration.data) {
        const { folderId, timePeriod, zoningLevel, gateRoadClasses, gates, subAreaGeometry } =
          ODDatasetConfiguration.data;

        dispatch(
          analyticsActions.createODDatasetConfig({
            index: 0,
            folderId,
            datasetName,
            description,
            timePeriod,
            zoningLevel,
            gateRoadClasses,
            gates,
            subAreaGeometry,
          }),
        );
      }
    };

    const handleRevertChanges = useCallback(
      (resetForm: (e: any) => void) => {
        if (initialODDatasetConfiguration) {
          resetForm({});

          dispatch(analyticsActions.updateODDatasetConfigSucceeded(initialODDatasetConfiguration));
        }
      },
      [initialODDatasetConfiguration, dispatch],
    );

    const handleDiscardChanges = useCallback(() => {
      if (datasetId) {
        dispatch(analyticsActions.fetchODDatasetConfig(datasetId));
      }
    }, [datasetId, dispatch]);

    const handleComputeSubmit = () => {
      if (areChangesPending) {
        setIsUnsavedChangesBeforeCompute(true);
      } else {
        openValidationModal();
      }
    };

    const handleCloseConfirmSaveBeforeCompute = useCallback(() => {
      if (isUnsavedChangesBeforeCompute) {
        setIsUnsavedChangesBeforeCompute(false);
      }
    }, [isUnsavedChangesBeforeCompute]);

    const handleConfirmSaveBeforeComputeSubmit = (values: DatasetForm, formikHelpers: FormikHelpers<DatasetForm>) => {
      handleSubmit(values, formikHelpers);
      setIsUnsavedChangesBeforeCompute(false);
      setIsNeedToOpenComputeModalAfterSaving(true);
    };

    const handleDrawMode = () => {
      if (isDrawModeActive) {
        setDrawModeActive(false);
        draw.changeMode("simple_select");
      } else {
        setPolygonSubtractMode(false);
        setDrawModeActive(true);
        draw.changeMode("draw_polygon");
      }
    };

    const handleSubtractMode = () => {
      if (isPolygonSubtractMode) {
        setPolygonSubtractMode(false);
        draw.changeMode("simple_select");
      } else {
        setDrawModeActive(false);
        setPolygonSubtractMode(true);
        draw.changeMode("draw_polygon");
      }
    };

    const loading =
      isSubmitting ||
      loadingSubAreaPolygon ||
      loadingGenerateGates ||
      addGateState === DataState.LOADING ||
      ODDatasetConfiguration.state === DataState.LOADING ||
      subareaState.state === DataState.LOADING ||
      ODDatasetConfigValidation.state === DataState.LOADING;

    return (
      <>
        {isSaveErrorModalOpen && (
          <SaveErrorDialog
            error={ODDatasetConfiguration.error as any}
            open={isSaveErrorModalOpen}
            handleDiscardChanges={handleDiscardChanges}
            handleOverwrite={() => handleSubmit(values, { setSubmitting }, true)}
            handleCreateNewDataset={(datasetName: string) => handleCreateNewDataset(datasetName, values.description)}
            onClose={() => setIsSaveErrorModalOpen(false)}
          />
        )}

        {ODDatasetConfiguration.state === DataState.LOADING ? (
          <LoaderContainer>
            <CircularProgress />
          </LoaderContainer>
        ) : (
          <FormWrapper onSubmit={formik.handleSubmit}>
            <FieldsContainer spacing={2}>
              <DatasetName>
                <InsertDriveFile color="secondary" fontSize="small" sx={{ marginRight: 0.5 }} />
                {values.datasetName}
                {/* <EditableText
                  multiline
                  maxLines={4}
                  placeholder="New Project"
                  value={values.datasetName}
                  disabled={isSubmitting}
                  onChange={(newVal) => setFieldValue("datasetName", newVal)}
                /> */}
                {errors.datasetName && <div className="error">{errors.datasetName}</div>}
              </DatasetName>
              <AreaNameContainer>
                <Place color="secondary" fontSize="small" sx={{ marginRight: 0.5 }} />
                <AreaName>{areaOfInterest?.region}</AreaName>
              </AreaNameContainer>
              <AreaNameContainer>
                <DateRange color="secondary" fontSize="small" sx={{ marginRight: 0.5 }} />
                <Typography variant="body2" fontWeight={500} color={`${themeColors.textSecondary}`}>
                  {timePeriod}
                </Typography>
              </AreaNameContainer>

              <TextField
                fullWidth
                multiline
                placeholder="Dataset description..."
                rows={3}
                value={values.description}
                onChange={(e) => setFieldValue("description", e.target.value)}
              />

              <Divider />

              <DrawControlsWrapper>
                <Label>Define Dataset Subarea</Label>
                <DrawControls>
                  <Tooltip
                    title={
                      ODDatasetConfiguration.data?.subAreaGeometry
                        ? "Draw polygon to extend subarea"
                        : "Draw polygon to define subarea"
                    }
                  >
                    <span>
                      <DrawIconButton selected={isDrawModeActive} size="small" onClick={handleDrawMode}>
                        <Polyline />
                      </DrawIconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Cover entire area">
                    <span>
                      <DrawIconButton color="default" size="small" onClick={() => onAddFullEntireAreaPolygon()}>
                        <AllInclusive />
                      </DrawIconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Draw polygon to remove from subarea">
                    <span>
                      <DrawIconButton
                        selected={isPolygonSubtractMode}
                        size="small"
                        disabled={!ODDatasetConfiguration.data?.subAreaGeometry}
                        onClick={handleSubtractMode}
                      >
                        <ContentCut />
                      </DrawIconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={
                      !isPolygonSelected
                        ? "Select subarea by clicking inside it in order to delete it"
                        : "Delete selected polygon or vertex"
                    }
                  >
                    <span>
                      <DrawIconButton
                        color="default"
                        size="small"
                        disabled={!isPolygonSelected || !ODDatasetConfiguration.data?.subAreaGeometry}
                        onClick={() => draw.trash()}
                      >
                        <Delete />
                      </DrawIconButton>
                    </span>
                  </Tooltip>
                </DrawControls>
              </DrawControlsWrapper>

              <RoadClassSelector
                roadClasses={roadClasses}
                savedRoadClasses={ODDatasetConfiguration.data?.gateRoadClasses || []}
                setRoadClasses={setRoadClasses}
              />

              <SpaceBetweenContainer>
                <Button color="secondary" onClick={toggleGateEditorPanel}>
                  Define gates
                </Button>
                {(ODDatasetConfiguration.data?.gates?.length as number) > 0 && (
                  <Badge color="secondary" label={`${ODDatasetConfiguration.data?.gates?.length} gates`} />
                )}
              </SpaceBetweenContainer>

              <Divider />

              <SpaceBetweenContainer>
                <Label>Zoning</Label>
                <Badge
                  color="secondary"
                  label={
                    subareaState.state === DataState.LOADING
                      ? "loading..."
                      : `${subareaState.data?.zoneIds.length || 0} zones`
                  }
                />
              </SpaceBetweenContainer>
              <ZoningSelector
                activeItem={selectedZoning}
                customZoningId={customZoningId}
                onItemSelect={setSelectedZoning}
                zoningOptions={zoningOptions}
              />
            </FieldsContainer>

            <div>
              <ActionButtons>
                {touched && (
                  <>
                    <Button
                      size="medium"
                      variant="outlined"
                      onClick={() => handleRevertChanges(handleReset)}
                      disabled={loading}
                    >
                      Revert
                    </Button>
                    <Button size="medium" variant="outlined" type="submit" disabled={loading}>
                      Save
                    </Button>
                  </>
                )}
                <ComputeButton
                  size="medium"
                  disabled={loading || !ODDatasetConfiguration.data?.permissions.compute.allow}
                  onClick={handleComputeSubmit}
                >
                  Compute...
                </ComputeButton>
              </ActionButtons>
              <Divider />
              <SpaceBetweenContainer>
                <Label>Map Layers</Label>

                <Popover
                  control={(handleOpen, open) => (
                    <IconButton onClick={handleOpen}>
                      <Settings fontSize="inherit" />
                    </IconButton>
                  )}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                >
                  <Stack rowGap={1} padding={2}>
                    <Switch label="Gates" checked={showGates} onChange={changeShowGates} />
                    <Switch label="Roads" checked={showRoads} onChange={changeShowRoads} />
                    <Switch label="Zones" checked={showZones} onChange={changeShowZones} />
                  </Stack>
                </Popover>
              </SpaceBetweenContainer>
            </div>
            {isUnsavedChangesBeforeCompute && (
              <ConfirmDialog
                open={isUnsavedChangesBeforeCompute}
                title="Confirm compute"
                confirmButtonText="Save and continue"
                onCancel={handleCloseConfirmSaveBeforeCompute}
                onConfirm={() =>
                  handleConfirmSaveBeforeComputeSubmit(values, {
                    setSubmitting,
                  } as unknown as FormikHelpers<DatasetForm>)
                }
              >
                <DialogContentText>
                  You have unsaved configuration changes. Do you want to save them and continue?
                </DialogContentText>
              </ConfirmDialog>
            )}
          </FormWrapper>
        )}
      </>
    );
  },
);
