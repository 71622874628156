import memoryStore, { MemoryStoreKeys } from "api/memoryStore";
import debounce from "lodash/debounce";
import groupBy from "lodash/groupBy";
import mapValues from "lodash/mapValues";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import mapboxgl, { MapLayerMouseEvent, MapboxGeoJSONFeature, Map as MapboxMap, PointLike, Popup } from "mapbox-gl";
import { Dispatch, MutableRefObject, SetStateAction } from "react";

import { closeAllMapboxPopups, getSelectedVolumeObject } from "features/map/utils";

import { RoadsHoverPopupProps, VolumePopupContentProps } from "components";

import { MeasureType, RoadsTileService, SelectedVolume, Volume } from "types";

import { SCREENLINES_INTERSECTIONS_LAYER_ID } from "../../screenlines/map-data/layers";
import {
  LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID,
  ROADS_HAIRLINES_LAYER_ID,
  ROADS_SEGMENTS_LAYER_ID,
  ROADS_VOLUMES_LAYER_ID,
} from "./layers";
import { ROADS_SOURCE_ID } from "./sources";

const RADIUS_FOR_SEGMENT_HIGHLIGHT_IN_PIXELS = 2;
const MIN_ZOOM_FOR_ROADS_VOLUMES = 9;
// The optimal size of the batching mechanism is determined by the number of features that need to be updated in the map per frame. (tested for large datasets)
const OPTIMAL_SIZE_OF_UPDATING_BATCHING = 500;
const OPTIMAL_SIZE_OF_REMOVING_BATCHING = 1500;

export interface RoadLayerIds {
  roadsSourceId: string;
  roadsVolumesLayerId: string;
  limitedAccessRoadsVolumesLayerId: string;
  roadsHairlinesLayerId: string;
  roadsHighlightedVolumesLayerId: string;
  roadsSegmentsLayerId: string;
  highlightedSegmentsLayerId: string;
  roadsMeasureRangeLayerId: string;
}

export const getMetersPerPixelAtLatitude = (map: mapboxgl.Map) => {
  const { lat } = map.getCenter();
  const zoom = map.getZoom();

  return (40075016.686 * Math.abs(Math.cos((lat * Math.PI) / 180))) / Math.pow(2, zoom + 8);
};

export const getRoadHandlers = (
  map: mapboxgl.Map,
  measureRef: React.RefObject<MeasureType>,
  tileService: RoadsTileService,
  volumesSize: number,
  maxVolumeRef: React.RefObject<number>,
  popupRoadSegmentHoverRef: any,
  popupRoadVolumesRef: any,
  mapboxVolumesPopupRef: any,
  setRoadHoverProps: React.RefObject<Dispatch<SetStateAction<RoadsHoverPopupProps | null>>>,
  blockClickEventRef: MutableRefObject<boolean | undefined> | undefined,
  setVolumeProps: Dispatch<SetStateAction<Volume[]>>,
  setRoadsVolumesRef: React.RefObject<Dispatch<SetStateAction<VolumePopupContentProps | null>>>,
  updateRoadsPopupCounts: React.MutableRefObject<
    ((selectedVolume: SelectedVolume | null, volumesProps?: any[]) => void) | null
  >,
  setVolumesLoading: Dispatch<SetStateAction<boolean>>,
  setSelectedRoadVolume?: (selectedRoadVolume: SelectedVolume | null) => void,
  setSelectedRoadVolumeId?: (selectedRoadVolumeId: string) => void,
  setSelectedLink?: React.MutableRefObject<(selectedRoadVolume: SelectedVolume, selectedRoadVolumeId: string) => void>,
  isSelectLink?: boolean,
  isSelectLinkResults?: boolean,
) => {
  let featureStateSet = new Set<string>();
  let isInteracting = false;
  let zoom = map.getZoom();

  let mapboxSegmentHoverPopup: Popup | null = null;
  let selectedRoadVolume: SelectedVolume | null = null;
  let selectedSegments: null | MapboxGeoJSONFeature[] = null;
  let hoveredSegments: null | MapboxGeoJSONFeature[] = null;
  let hoveredSegmentIdFromPopup: string | null = null;

  const removeFeatureState = (id: string, featureName: string) => {
    map.removeFeatureState(
      {
        source: ROADS_SOURCE_ID,
        sourceLayer: tileService.layerName,
        id,
      },
      featureName,
    );
  };

  // The use of batching mechanisms is necessary to prevent excessive CPU load.
  // The segment update process happens asynchronously by delegating the update tasks for the map to the WebGL engine.
  // The actual rendering takes more time than processing the feature state source updates from Mapbox GL.
  // This ensures that performance remains stable, as rendering in WebGL can be more time-consuming than updating feature states in Mapbox GL,
  // especially when dealing with large or complex datasets.
  const batchUpdateFeatureStates = (
    featureStates: [string, { volumeOffset: number; volumeWeight: number }][],
    layerName: string,
  ) => {
    let index = 0;

    function updateNextBatch() {
      const nextBatch = featureStates.slice(index, index + OPTIMAL_SIZE_OF_UPDATING_BATCHING);

      nextBatch.forEach(([id, state]) => {
        const { volumeOffset, volumeWeight } = state as any;

        map.setFeatureState(
          {
            source: ROADS_SOURCE_ID,
            sourceLayer: layerName,
            id,
          },
          {
            volumeOffset,
            volumeWeight,
          },
        );
      });

      index += OPTIMAL_SIZE_OF_UPDATING_BATCHING;

      if (index < featureStates.length) {
        requestAnimationFrame(updateNextBatch);
      } else {
        setVolumesLoading(false);
      }
    }

    updateNextBatch();
  };

  // The use of batching mechanisms is necessary to prevent excessive CPU load. (also, check the comment for batchUpdateFeatureStates)
  const batchRemoveFeatureStates = (featureStates: string[]) => {
    return new Promise<void>((resolve) => {
      let index = 0;

      function removeNextBatch() {
        const nextBatch = featureStates.slice(index, index + OPTIMAL_SIZE_OF_REMOVING_BATCHING);

        nextBatch.forEach((id) => {
          removeFeatureState(id, "volumeOffset");
          removeFeatureState(id, "volumeWeight");
        });

        index += OPTIMAL_SIZE_OF_REMOVING_BATCHING;

        if (index < featureStates.length) {
          requestAnimationFrame(removeNextBatch);
        } else {
          resolve();
        }
      }

      removeNextBatch();
    });
  };

  const updateFeatureStatesByVolumes = (
    isForceUpdate: boolean,
    layerName: string,
    toFromSegmentIdField: string,
    isPedestriansMode: boolean,
  ) => {
    if (!map || !volumesSize || isInteracting) {
      setVolumesLoading(false);
      return;
    }
    // Get all visible features
    const allVisibleFeatures = getVisibleFeatures();

    if ((isForceUpdate || allVisibleFeatures.length === 0) && featureStateSet.size > 0) {
      featureStateSet.clear();
    }

    if (map.getZoom() < MIN_ZOOM_FOR_ROADS_VOLUMES) {
      batchRemoveFeatureStates(Array.from(featureStateSet)).then(() => {
        const roadSegmentLineWidth = map.getPaintProperty(ROADS_HAIRLINES_LAYER_ID, "line-width");

        if (roadSegmentLineWidth === 0) {
          map.setPaintProperty(ROADS_HAIRLINES_LAYER_ID, "line-width", 1);
          map.setPaintProperty(LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID, "line-width", 0);
          map.setPaintProperty(ROADS_VOLUMES_LAYER_ID, "line-width", 0);
        }

        featureStateSet.clear();
        setVolumesLoading(false);
      });

      return;
    }

    // Filter out features that are already visible
    const visibleFeatures = allVisibleFeatures.filter((f) => {
      if (isForceUpdate) {
        return true;
      }

      if (featureStateSet.size > 0 && featureStateSet.has(String(f.id))) {
        return false;
      }

      return true;
    });

    if (visibleFeatures.length === 0) {
      setVolumesLoading(false);
      return;
    }

    const metersPerPixelAtLatitude = getMetersPerPixelAtLatitude(map);
    const MIN_WIDTH_IN_PIXELS = 1;
    const MAX_WIDTH_IN_PIXELS = isPedestriansMode ? 80 : 1000;
    const features: Record<string, { volumeOffset: number; volumeWeight: number }> = {};
    const getWidthInPixelsByVolume = (volume: number) =>
      ((volume / (maxVolumeRef.current! * 2)) * (MAX_WIDTH_IN_PIXELS - MIN_WIDTH_IN_PIXELS)) / metersPerPixelAtLatitude;
    const volumes =
      memoryStore.getItem(
        isSelectLinkResults ? MemoryStoreKeys.SELECT_LINK_SEGMENT_COUNTS : MemoryStoreKeys.ROADS_SEGMENT_COUNTS,
      ) || new Map<string, number>();

    // Get the volumes for the visible segments
    visibleFeatures.forEach((feature: any) => {
      const segmentId = feature.id;
      const toFromSegmentId = feature.properties[toFromSegmentIdField];

      const fromToSegmentWidth = Math.round(
        (volumes.get(segmentId) || 0) > 0 ? getWidthInPixelsByVolume(volumes.get(segmentId) || 0) : 0,
      );
      const toFromSegmentWidth = Math.round(
        (volumes.get(toFromSegmentId) || 0) > 0 ? getWidthInPixelsByVolume(volumes.get(toFromSegmentId) || 0) : 0,
      );

      const sumVolumeWidth = fromToSegmentWidth + toFromSegmentWidth;
      const signFactor = fromToSegmentWidth - toFromSegmentWidth >= 0 ? 1 : -1;

      const scalingFactor = 2;

      features[segmentId] = {
        volumeOffset: isPedestriansMode
          ? 0
          : signFactor * scalingFactor * (sumVolumeWidth / 2 - Math.min(fromToSegmentWidth, toFromSegmentWidth)),
        volumeWeight: sumVolumeWidth > 0 ? sumVolumeWidth * scalingFactor : MIN_WIDTH_IN_PIXELS,
      };
    });

    const newFeatureStateSet = new Set(allVisibleFeatures.map((f) => String(f.id)));

    // Remove feature states that are not visible anymore
    batchRemoveFeatureStates(Array.from(featureStateSet).filter((id) => !newFeatureStateSet.has(id))).then(() => {
      // Update the feature states
      batchUpdateFeatureStates(Object.entries(features), layerName);

      const roadSegmentLineWidth = map.getPaintProperty(ROADS_VOLUMES_LAYER_ID, "line-width");

      if (roadSegmentLineWidth === 0) {
        map.setPaintProperty(ROADS_HAIRLINES_LAYER_ID, "line-width", 1);
        map.setPaintProperty(LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID, "line-width", [
          "number",
          ["feature-state", "volumeWeight"],
          0,
        ]);
        map.setPaintProperty(ROADS_VOLUMES_LAYER_ID, "line-width", ["number", ["feature-state", "volumeWeight"], 0]);
      }

      // Update the feature state set
      featureStateSet = newFeatureStateSet;
    });
  };

  const getVisibleFeatures = () => {
    return map.queryRenderedFeatures(undefined, {
      layers: [ROADS_HAIRLINES_LAYER_ID],
    });
  };

  const getUniqSegments = (segments: MapboxGeoJSONFeature[], tileService: RoadsTileService) => {
    return uniqBy(segments, "id").map((s) => {
      const volumeCounts = memoryStore.getItem(
        isSelectLinkResults ? MemoryStoreKeys.SELECT_LINK_SEGMENT_COUNTS : MemoryStoreKeys.ROADS_SEGMENT_COUNTS,
      ) as Map<string, number>;
      const fromToSegmentId = s.properties?.[tileService.fromToSegmentIdField];
      const toFromSegmentId = s.properties?.[tileService.toFromSegmentIdField];

      return {
        ...s.properties,
        fromToId: fromToSegmentId,
        toFromId: toFromSegmentId,
        volumeFT: volumeCounts.get(fromToSegmentId) || 0,
        volumeTF: volumeCounts.get(toFromSegmentId) || 0,
      };
    });
  };

  const closeSegmentHoverPopup = () => {
    if (mapboxSegmentHoverPopup?.isOpen()) {
      mapboxSegmentHoverPopup.remove();
    }

    const popups = document.getElementsByClassName("mapbox-segment-hover-popup");
    if (popups.length > 0) {
      popups[0].remove();
    }
  };

  const getVolumesPropsFromSegments = (
    segments: MapboxGeoJSONFeature[],
    tileService: RoadsTileService,
    map: MapboxMap,
    lngLat: number[],
  ) => {
    const uniqSegments = getUniqSegments(segments, tileService);
    const volumesPropsOrderedByStreetName = orderBy(uniqSegments, ["st_name"], ["asc"]);

    const visibleFeatureIds = volumesPropsOrderedByStreetName.map(
      (s) =>
        (
          s as {
            fromToId: any;
            toFromId: any;
          }
        ).fromToId,
    );
    const visibleFeatures =
      visibleFeatureIds.length > 0
        ? map.queryRenderedFeatures(undefined, {
            layers: [ROADS_VOLUMES_LAYER_ID],
            filter: ["in", tileService.fromToSegmentIdField, ...visibleFeatureIds],
          })
        : [];

    const featureWeights: Record<string, number> = {};

    if (visibleFeatures.length > 0) {
      visibleFeatures.forEach((f: any) => {
        const { geometry } = f;
        const latitudes = [...geometry.coordinates].map((c: any) => c[1]);

        featureWeights[f.id] = Math.max(0, ...latitudes);
      });
    }

    const groupedVolumesProps: any = groupBy(volumesPropsOrderedByStreetName, "st_name");

    const result = Object.values(
      mapValues(groupedVolumesProps, (group) => {
        return [...group]
          .map((el: any) => ({
            ...el,
            weight: featureWeights[el.fromToId],
            lngLat,
          }))
          .sort((a: any, b: any) => {
            return b.weight - a.weight;
          });
      }),
    ).flat();

    return result.length > 0 ? result : volumesPropsOrderedByStreetName;
  };

  const updateVolumes = (forceUpdate: boolean = false) => {
    if (
      !map ||
      !map.isStyleLoaded() ||
      !volumesSize ||
      !map.getLayer(ROADS_VOLUMES_LAYER_ID) ||
      map.getLayoutProperty(ROADS_VOLUMES_LAYER_ID, "visibility") === "none"
    ) {
      if (
        map &&
        map.isStyleLoaded() &&
        volumesSize &&
        map.getLayer(ROADS_HAIRLINES_LAYER_ID) &&
        map.getPaintProperty(ROADS_HAIRLINES_LAYER_ID, "line-width") === 0
      ) {
        map.setPaintProperty(ROADS_HAIRLINES_LAYER_ID, "line-width", 1);
      }

      return;
    }

    const isZoomDiff = Math.abs(map.getZoom() - zoom) > 0.25;
    const isForceUpdate = isZoomDiff || forceUpdate;

    setVolumesLoading(true);

    if (isForceUpdate) {
      batchRemoveFeatureStates(Array.from(featureStateSet)).then(() => {
        updateFeatureStatesByVolumes(
          isForceUpdate,
          tileService.layerName,
          tileService.toFromSegmentIdField,
          measureRef.current === MeasureType.PEDESTRIANS,
        );
      });
    } else {
      updateFeatureStatesByVolumes(
        isForceUpdate,
        tileService.layerName,
        tileService.toFromSegmentIdField,
        measureRef.current === MeasureType.PEDESTRIANS,
      );
    }

    if (isZoomDiff) {
      zoom = map.getZoom();
    }
  };

  const debounceUpdateVolumes = debounce(updateVolumes, 500);

  const updateSegmentsStateWithHover = (e: MapLayerMouseEvent, segments: any[], status: boolean) => {
    if (!map.isStyleLoaded() || !map.getSource(ROADS_SOURCE_ID)) return;

    segments.forEach((segment) => {
      map.setFeatureState(
        {
          source: ROADS_SOURCE_ID,
          sourceLayer: tileService.layerName,
          id: segment.id,
        },
        { hover: status },
      );
    });

    if (status && typeof setRoadHoverProps.current === "function") {
      const volumes = getVolumesPropsFromSegments(segments, tileService, map, e.lngLat.toArray());
      setRoadHoverProps.current({
        volume: volumes.find((s) => s[tileService.fromToSegmentIdField] === segments[0].id),
        isPedestriansMode: measureRef.current === MeasureType.PEDESTRIANS,
      });
    }
  };

  const updateSegmentsStateWithSelected = (segments: any[]) => {
    if (!map.isStyleLoaded() || !map.getSource(ROADS_SOURCE_ID)) return;

    segments.forEach((segment) => {
      map.removeFeatureState(
        {
          source: ROADS_SOURCE_ID,
          sourceLayer: tileService.layerName,
          id: segment.id,
        },
        "selectHighlight",
      );
    });
  };

  const handleUpdateVolumesBySourceData = (e: any) => {
    if (e.sourceId !== ROADS_SOURCE_ID) {
      return;
    }

    if (!e.tile || e.tile.state !== "loaded") {
      return;
    }

    debounceUpdateVolumes();
  };

  const handleUpdateVolumesByZoomEnd = () => {
    debounceUpdateVolumes();
  };

  const handleInteractionStart = () => {
    isInteracting = true;
  };

  const handleInteractionEnd = () => {
    isInteracting = false;
    debounceUpdateVolumes();
  };

  const handleMousemove = (e: MapLayerMouseEvent) => {
    if (selectedRoadVolume && !selectedSegments) {
      selectedSegments = map.queryRenderedFeatures(undefined, {
        layers: [ROADS_VOLUMES_LAYER_ID],
        filter: ["in", tileService.fromToSegmentIdField, selectedRoadVolume.ftSegmentId],
      });
    }

    if (e.features && e.features.length > 0) {
      const feature = e.features.sort((a, b) => b.state.volumeWeight - a.state.volumeWeight)[0];

      const featureId = feature?.id as string;
      if (hoveredSegments !== null) {
        map.getCanvas().style.cursor = "";
        hoveredSegments.forEach((hoveredSegment) => {
          if (
            selectedSegments &&
            selectedSegments.length > 0 &&
            selectedSegments.find((s) => s.id === hoveredSegment.id)
          )
            return;

          map.setFeatureState(
            {
              source: ROADS_SOURCE_ID,
              sourceLayer: tileService.layerName,
              id: hoveredSegment.id,
            },
            { hover: false },
          );
        });
      }
      if (feature.layer.id === SCREENLINES_INTERSECTIONS_LAYER_ID) return;

      if (
        featureId &&
        featureId !== selectedSegments?.[0].id &&
        memoryStore.getItem(MemoryStoreKeys.ROADS_SEGMENT_IDS)?.has(featureId)
      ) {
        // Set `bbox` as 25px rectangle area around clicked point.
        const bbox = [
          [e.point.x - RADIUS_FOR_SEGMENT_HIGHLIGHT_IN_PIXELS, e.point.y - RADIUS_FOR_SEGMENT_HIGHLIGHT_IN_PIXELS],
          [e.point.x + RADIUS_FOR_SEGMENT_HIGHLIGHT_IN_PIXELS, e.point.y + RADIUS_FOR_SEGMENT_HIGHLIGHT_IN_PIXELS],
        ] as [PointLike, PointLike];
        // Find features intersecting the bounding box.
        const selectedFeatures = map.queryRenderedFeatures(bbox, {
          layers: [ROADS_SEGMENTS_LAYER_ID],
        });
        if (selectedFeatures.length > 0) {
          hoveredSegments = selectedFeatures;
          updateSegmentsStateWithHover(e, [hoveredSegments[0]], true);
        }

        if (popupRoadSegmentHoverRef.current) {
          if (
            mapboxSegmentHoverPopup &&
            mapboxSegmentHoverPopup.isOpen() &&
            e.lngLat.lat === mapboxSegmentHoverPopup.getLngLat().lat &&
            e.lngLat.lng === mapboxSegmentHoverPopup.getLngLat().lng
          ) {
            map.getCanvas().style.cursor = "default";
            return;
          } else {
            closeSegmentHoverPopup();

            mapboxSegmentHoverPopup = new Popup({
              closeButton: false,
              closeOnClick: false,
              offset: 15,
            })
              .setLngLat(e.lngLat)
              .setDOMContent(popupRoadSegmentHoverRef.current as Node);

            mapboxSegmentHoverPopup.addClassName("mapbox-segment-hover-popup");
            mapboxSegmentHoverPopup.addTo(map);
          }
        }
      }

      map.getCanvas().style.cursor = "default";
    }
  };

  const handleMouseleave = () => {
    closeSegmentHoverPopup();

    if (hoveredSegments !== null) {
      map.getCanvas().style.cursor = "";
      hoveredSegments.forEach((hoveredSegment) => {
        if (selectedSegments && selectedSegments.length > 0) {
          const isSelected = selectedSegments.some((selectedSegment) => selectedSegment.id === hoveredSegment.id);
          if (!isSelected) {
            map.setFeatureState(
              {
                source: ROADS_SOURCE_ID,
                sourceLayer: tileService.layerName,
                id: hoveredSegment.id,
              },
              { hover: false },
            );
          }
        } else {
          map.setFeatureState(
            {
              source: ROADS_SOURCE_ID,
              sourceLayer: tileService.layerName,
              id: hoveredSegment.id,
            },
            { hover: false },
          );
        }
      });

      hoveredSegments = null;
    }
  };

  const handleMouseMoveOnVolumesLayer = () => {
    map.getCanvas().style.cursor = "default";
  };

  const handleMouseLeaveVolumesLayer = () => {
    map.getCanvas().style.cursor = "";
  };

  const handleHoverSegmentFromPopup = (segmentId: string | null) => {
    if (map?.getLayer(ROADS_SEGMENTS_LAYER_ID)) {
      if (hoveredSegmentIdFromPopup) {
        map.removeFeatureState(
          {
            source: ROADS_SOURCE_ID,
            sourceLayer: tileService.layerName,
            id: hoveredSegmentIdFromPopup,
          },
          "hoverHighlight",
        );
      }

      if (segmentId) {
        map.setFeatureState(
          {
            source: ROADS_SOURCE_ID,
            sourceLayer: tileService.layerName,
            id: segmentId,
          },
          { hoverHighlight: true },
        );
      }
    }

    hoveredSegmentIdFromPopup = segmentId;
  };

  const handleClickSegmentFromPopup = (selectedVolume: SelectedVolume, id: string, volumesProps?: Volume[]) => {
    if (!selectedVolume || !id || !(setSelectedRoadVolumeId || (isSelectLink && setSelectedLink))) {
      return;
    }

    if (isSelectLink && setSelectedLink) {
      setSelectedLink.current(selectedVolume, id);
    } else if (setSelectedRoadVolumeId) {
      setSelectedRoadVolumeId(id);
    }

    if (map && JSON.stringify(selectedRoadVolume) !== JSON.stringify(selectedVolume)) {
      if (selectedRoadVolume) {
        map.removeFeatureState(
          {
            source: ROADS_SOURCE_ID,
            sourceLayer: tileService.layerName,
            id: selectedRoadVolume.ftSegmentId,
          },
          "selectHighlight",
        );
      }

      setSelectedRoadVolume!(selectedVolume);
      selectedRoadVolume = selectedVolume;

      if (selectedVolume) {
        map.setFeatureState(
          {
            source: ROADS_SOURCE_ID,
            sourceLayer: tileService.layerName,
            id: selectedVolume.ftSegmentId,
          },
          { selectHighlight: true },
        );
      }

      if (typeof updateRoadsPopupCounts.current === "function") {
        updateRoadsPopupCounts.current(selectedVolume, volumesProps);
      }
    }
  };

  const handleMouseClickOnSegmentsLayer = (e: MapLayerMouseEvent) => {
    if (blockClickEventRef?.current) return;

    if (mapboxSegmentHoverPopup) {
      mapboxSegmentHoverPopup.remove();
    }

    if (mapboxVolumesPopupRef.current && mapboxVolumesPopupRef.current.isOpen()) {
      mapboxVolumesPopupRef.current.remove();
    }

    if (!map.getLayer(ROADS_SEGMENTS_LAYER_ID) || !map.getLayoutProperty(ROADS_SEGMENTS_LAYER_ID, "visibility")) {
      return;
    }

    if (selectedSegments && selectedSegments.length > 0) {
      updateSegmentsStateWithHover(e, selectedSegments, false);
    }

    selectedSegments = hoveredSegments;

    if (selectedSegments && selectedSegments.length > 0) {
      updateSegmentsStateWithHover(e, selectedSegments, true);
    }

    if (selectedSegments && selectedSegments.length > 0) {
      const volumesProps = getVolumesPropsFromSegments(selectedSegments, tileService, map, e.lngLat.toArray());
      const selectedVolume = volumesProps.find((s) => s[tileService.fromToSegmentIdField] === selectedSegments?.[0].id);
      const volumeObj = getSelectedVolumeObject(selectedVolume);

      if (setSelectedRoadVolume && setSelectedRoadVolumeId) {
        setSelectedRoadVolumeId(volumeObj.ftSegmentId); // select the "from to" direction by clicking on the segment
        selectedRoadVolume = volumeObj;
        setSelectedRoadVolume(volumeObj);
      }

      if (typeof updateRoadsPopupCounts.current === "function") {
        updateRoadsPopupCounts.current(volumeObj, volumesProps);
      }

      if (mapboxVolumesPopupRef.current && mapboxVolumesPopupRef.current.isOpen()) {
        mapboxVolumesPopupRef.current.remove();
      }

      if (popupRoadVolumesRef.current) {
        mapboxVolumesPopupRef.current = new Popup({
          closeOnClick: false,
        })
          .setLngLat(e.lngLat)
          .setDOMContent(popupRoadVolumesRef.current as Node)
          .setOffset([0, -25])
          .addTo(map);

        mapboxVolumesPopupRef.current.on("close", function () {
          if (selectedSegments && selectedSegments.length > 0) {
            updateSegmentsStateWithHover(e, selectedSegments, false);
            updateSegmentsStateWithSelected(selectedSegments);
          }

          selectedSegments = null;
          selectedRoadVolume = null;
          if (setSelectedRoadVolume) setSelectedRoadVolume(null);
        });
      }

      if (selectedRoadVolume) {
        map.setFeatureState(
          {
            source: ROADS_SOURCE_ID,
            sourceLayer: tileService.layerName,
            id: selectedRoadVolume.ftSegmentId,
          },
          { selectHighlight: true, hover: true },
        );
      }
    }
  };

  const updateRoadsVolumes = () => {
    updateVolumes(true);
  };

  updateRoadsPopupCounts.current = (selectedVolume: SelectedVolume | null, volumesProps?: any[]) => {
    if (typeof setRoadsVolumesRef.current === "function") {
      const volumesPropsList = volumesProps || getVolumesPropsFromSegments(selectedSegments!, tileService, map, []);

      setVolumeProps(volumesPropsList);

      setRoadsVolumesRef.current({
        selectedVolume,
        volume: volumesPropsList,
        isPedestriansMode: measureRef.current === MeasureType.PEDESTRIANS,
        onHover: handleHoverSegmentFromPopup,
        onClick: handleClickSegmentFromPopup,
      });
    }
  };

  map.on("sourcedata", handleUpdateVolumesBySourceData);
  map.on("zoomend", handleUpdateVolumesByZoomEnd);
  map.on("dragstart", handleInteractionStart);
  map.on("dragend", handleInteractionEnd);
  map.on("movestart", handleInteractionStart);
  map.on("moveend", handleInteractionEnd);
  map.on("mousemove", [ROADS_SEGMENTS_LAYER_ID, SCREENLINES_INTERSECTIONS_LAYER_ID], handleMousemove);
  map.on("mouseleave", ROADS_SEGMENTS_LAYER_ID, handleMouseleave);
  map.on("mousemove", [ROADS_HAIRLINES_LAYER_ID, ROADS_VOLUMES_LAYER_ID], handleMouseMoveOnVolumesLayer);
  map.on("mouseleave", [ROADS_HAIRLINES_LAYER_ID, ROADS_VOLUMES_LAYER_ID], handleMouseLeaveVolumesLayer);

  if (!isSelectLinkResults) {
    map.on("click", ROADS_SEGMENTS_LAYER_ID, handleMouseClickOnSegmentsLayer);
  }

  return {
    cleanRoadsHandlers: () => {
      closeAllMapboxPopups(map);

      if (popupRoadSegmentHoverRef.current) {
        popupRoadSegmentHoverRef.current.remove();
      }

      if (popupRoadVolumesRef.current) {
        popupRoadVolumesRef.current.remove();
      }

      map.off("sourcedata", handleUpdateVolumesBySourceData);
      map.off("zoomend", handleUpdateVolumesByZoomEnd);
      map.off("dragstart", handleInteractionStart);
      map.off("dragend", handleInteractionEnd);
      map.off("movestart", handleInteractionStart);
      map.off("moveend", handleInteractionEnd);
      map.off("mousemove", ROADS_SEGMENTS_LAYER_ID, handleMousemove);
      map.off("mouseleave", ROADS_SEGMENTS_LAYER_ID, handleMouseleave);
      map.off("mousemove", [ROADS_HAIRLINES_LAYER_ID, ROADS_VOLUMES_LAYER_ID], handleMouseMoveOnVolumesLayer);
      map.off("mouseleave", [ROADS_HAIRLINES_LAYER_ID, ROADS_VOLUMES_LAYER_ID], handleMouseLeaveVolumesLayer);
      if (!isSelectLinkResults) {
        map.off("click", ROADS_SEGMENTS_LAYER_ID, handleMouseClickOnSegmentsLayer);
      }
    },
    updateRoadsVolumes,
  };
};
