import { styled } from "@mui/material/styles";
import { Button, Divider, TextField, TextFieldProps } from "components_new";
import React, { useCallback, useEffect, useState } from "react";

import { FlexContainer } from "components";

import { SelectLinkAnalysisOptions } from "types";

const TopMarginDivider = styled(Divider)`
  margin-top: 32px;
`;

const SelectLinkOptions = styled("div")`
  display: flex;
  gap: 10px;
  padding-top: 12px;
`;

const ActionButtonsContainer = styled(FlexContainer)`
  margin: 1rem 0;
  justify-content: space-between;
`;

const ActionButtons = styled(FlexContainer)`
  gap: 0.5rem;
`;

const ComputeButton = styled(Button)`
  width: 100px;
`;

interface Props {
  isResults: boolean;
  loadingResults: boolean;
  savingConfig: boolean;
  minVolume: number;
  minAllowedVolume: number;
  canRunAnalysis: boolean;
  areConfigChangesSinceSave: boolean;
  onChangeOptions: (options: SelectLinkAnalysisOptions) => void;
  onSaveConfiguration: (options: SelectLinkAnalysisOptions) => void;
  onRevertConfiguration: () => void;
  onRunAnalysis: (options: SelectLinkAnalysisOptions) => void;
  onConfigureAnalysis: () => void;
}

const MinVolumeTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  "& .MuiFormHelperText-root": {
    fontSize: "10px",
    margin: "0px",
    marginLeft: "2px",
    lineHeight: "10px",
    padding: "0px",
    paddingTop: "2px",
    color: theme.palette.warning.main,
  },
}));

export const SelectLinkConfigSettings = ({
  isResults,
  loadingResults,
  savingConfig,
  minVolume,
  minAllowedVolume,
  canRunAnalysis,
  areConfigChangesSinceSave,
  onChangeOptions,
  onSaveConfiguration,
  onRevertConfiguration,
  onRunAnalysis,
  onConfigureAnalysis,
}: Props) => {
  const [errors, setErrors] = useState({
    min: "",
  });

  const isVolumeValid = minVolume >= minAllowedVolume;

  const handleMinVolumeChange = (e: any) => {
    const newMinVolume = +e.target.value;
    validateMinValue(newMinVolume, minAllowedVolume);
    onChangeOptions({ minVolume: newMinVolume });
  };

  const validateMinValue = useCallback(
    (value: number, minValue: number) => {
      setErrors({ min: "" });

      if (value < minValue) {
        setErrors((prevState) => ({ ...prevState, min: `Less than ${minAllowedVolume}` }));
      }
    },
    [minAllowedVolume],
  );

  useEffect(() => {
    validateMinValue(minVolume, minAllowedVolume);
  }, [validateMinValue, minVolume, minAllowedVolume]);

  return (
    <>
      <TopMarginDivider />
      <SelectLinkOptions>
        <MinVolumeTextField
          type="number"
          name="minVolume"
          size="small"
          label="Min Volume"
          value={minVolume ?? 0}
          disabled={typeof minVolume !== "number" || !canRunAnalysis || isResults || loadingResults || savingConfig}
          InputLabelProps={{ shrink: true }}
          onChange={handleMinVolumeChange}
          helperText={errors.min === "" ? " " : errors.min}
          color={errors.min !== "" ? "warning" : "secondary"}
          sx={{
            maxWidth: 110,
            marginBottom: 1,
          }}
        />
      </SelectLinkOptions>
      <Divider />
      <ActionButtonsContainer>
        <ComputeButton
          type="button"
          disabled={!canRunAnalysis || !(isResults || isVolumeValid) || savingConfig}
          loading={loadingResults}
          onClick={() => (isResults ? onConfigureAnalysis() : onRunAnalysis({ minVolume }))}
        >
          <>{isResults ? "Configure" : "Run Analysis"}</>
        </ComputeButton>
        <ActionButtons>
          {areConfigChangesSinceSave && (
            <Button
              type="submit"
              variant="outlined"
              disabled={loadingResults || savingConfig}
              onClick={() => onRevertConfiguration()}
            >
              Revert
            </Button>
          )}
          <Button
            type="submit"
            variant="outlined"
            disabled={!areConfigChangesSinceSave || loadingResults || !isVolumeValid}
            loading={savingConfig}
            onClick={() => onSaveConfiguration({ minVolume })}
          >
            Save
          </Button>
        </ActionButtons>
      </ActionButtonsContainer>
    </>
  );
};
