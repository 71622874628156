import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { MemoryStore, MemoryStoreKeys } from "api/memoryStore";
import { MutableRefObject, useCallback } from "react";
import { useDispatch } from "react-redux";

import { buildFilters, getCurrentMeasure } from "features/filters/utils";

import { analyticsActions } from "store/sections/analytics";

import {
  CustomRoadsTileService,
  CustomSelectLinkResultsTileService,
  FiltersType,
  FocusAreaItem,
  MeasureType,
  RoadSegmentDetailsRequestBreakdown,
  RoadsMetadata,
  SelectedVolume,
} from "types";

import { ProfileMode, profileTimeStart } from "utils/profile";

import {
  LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID,
  ROADS_HAIRLINES_LAYER_ID,
  ROADS_SEGMENTS_LAYER_ID,
  ROADS_VOLUMES_LAYER_ID,
  getRoadsLayers,
} from "./map-data/layers";
import { ROADS_SOURCE_ID } from "./map-data/sources";

export const useGetRoadSegmentIds = (selectedFocusArea: FocusAreaItem | null, timePeriod: string | null) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    if (timePeriod) {
      profileTimeStart(ProfileMode.ROADS_MODE_GETTING_SEGMENT_IDS);

      dispatch(
        analyticsActions.fetchSegmentIds({
          timePeriod,
          areaOfInterest: selectedFocusArea?.areas || null,
          onlyFromTo: true,
          includeReverse: true,
          compression: "gzip",
          datasetId: selectedFocusArea?.datasetId ?? undefined,
        }),
      );
    }
  }, [selectedFocusArea?.areas, selectedFocusArea?.datasetId, timePeriod, dispatch]);
};

export const useGetVisibleRoadIds = (memoryStore: MemoryStore) => {
  return useCallback(
    (isSelectLinkSegmentCounts?: boolean) => {
      const roadSegmentIds = memoryStore.getItem(MemoryStoreKeys.ROADS_SEGMENT_IDS);
      const roadsVolumes = memoryStore.getItem(
        isSelectLinkSegmentCounts ? MemoryStoreKeys.SELECT_LINK_SEGMENT_COUNTS : MemoryStoreKeys.ROADS_SEGMENT_COUNTS,
      );

      if (roadSegmentIds && roadsVolumes) {
        const mainSegmentIds = Array.from(roadSegmentIds.keys());
        const visibleSegmentsIds = mainSegmentIds.filter((segmentId) => {
          const reverseSegmentId = roadSegmentIds!.get(segmentId)?.reverseSegmentId;
          const segmentVolume = roadsVolumes.get(segmentId) || 0;
          const reverseSegmentVolume = reverseSegmentId ? roadsVolumes.get(reverseSegmentId) || 0 : 0;

          return segmentVolume + reverseSegmentVolume > 0;
        });

        return visibleSegmentsIds;
      } else {
        return [];
      }
    },
    [memoryStore],
  );
};

export const useGetRoadsVolumes = (selectedFocusArea: FocusAreaItem | null, timePeriod: string | null) => {
  const dispatch = useDispatch();

  return useCallback(
    (filters: FiltersType, measure: MeasureType) => {
      if (timePeriod) {
        profileTimeStart(ProfileMode.ROADS_MODE_GETTING_SEGMENT_COUNTS);

        dispatch(
          analyticsActions.fetchRoadsVolumes({
            timePeriod,
            compression: "gzip",
            areaOfInterest: selectedFocusArea?.areas || null,
            filter: buildFilters(filters),
            datasetId: selectedFocusArea?.datasetId ?? undefined,
            measure,
          }),
        );
      }
    },
    [selectedFocusArea, timePeriod, dispatch],
  );
};

export const useGetSegmentsDetails = (
  roadsMetadata: RoadsMetadata | null,
  currentRoadFilters: FiltersType | null,
  selectedFocusArea: FocusAreaItem | null,
  timePeriod: string | null,
) => {
  const dispatch = useDispatch();

  return useCallback(
    (selectedVolume: SelectedVolume, measure: MeasureType) => {
      if (timePeriod) {
        const segmentIds = [selectedVolume.ftSegmentId];
        const currentMeasure = getCurrentMeasure(roadsMetadata?.measures, measure);
        const dimensions = currentMeasure?.dimensions?.filter((d) => d.enabled).map((d) => d.columnName) || [];
        const breakdowns: RoadSegmentDetailsRequestBreakdown[] = dimensions.map((d) => ({
          dimensions: [d],
          includeUnfiltered: false,
        }));

        if (selectedVolume?.tfSegmentId) {
          segmentIds.push(selectedVolume.tfSegmentId);
        }

        dispatch(
          analyticsActions.fetchSegmentsDetails({
            timePeriod,
            summary: {
              breakdowns,
              filteredTotal: true,
              unfilteredTotal: false,
            },
            measure,
            segmentIds,
            filter: buildFilters(currentRoadFilters),
            datasetId: selectedFocusArea?.datasetId ?? undefined,
          }),
        );
        dispatch(
          analyticsActions.fetchSegmentsFeatureDetails({
            timePeriod,
            segmentIds,
          }),
        );
      }
    },
    [roadsMetadata?.measures, selectedFocusArea?.datasetId, currentRoadFilters, timePeriod, dispatch],
  );
};

export const useGetVisibilityStatus = (showRoadVolumesRef: MutableRefObject<boolean>) => {
  return useCallback(
    (layerId: string) => {
      switch (layerId) {
        case ROADS_VOLUMES_LAYER_ID:
        case LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID:
        case ROADS_HAIRLINES_LAYER_ID:
          return showRoadVolumesRef.current ? "visible" : "none";
        default:
          return "visible";
      }
    },
    [showRoadVolumesRef],
  );
};

export const useSelectRoadVolume = () => {
  const dispatch = useDispatch();

  return useCallback(
    (selectedRoadVolume: SelectedVolume | null) => {
      dispatch(analyticsActions.setSelectedRoadVolume(selectedRoadVolume));
    },
    [dispatch],
  );
};

export const useSelectRoadVolumeId = () => {
  const dispatch = useDispatch();

  return useCallback(
    (selectedRoadVolumeId: string | null) => {
      dispatch(analyticsActions.setSelectedRoadVolumeId(selectedRoadVolumeId));
    },
    [dispatch],
  );
};

export const useChangeRoadVolumeId = (
  selectedRoadVolumeId: string | null,
  selectRoadVolumeId: (selectedRoadVolumeId: string) => void,
) => {
  return useCallback(
    (id: string) => {
      if (id !== selectedRoadVolumeId) {
        selectRoadVolumeId(id);
      }
    },
    [selectedRoadVolumeId, selectRoadVolumeId],
  );
};

export const useChangeShowRoadVolumes = (
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
  updateRoadsModeCounts: MutableRefObject<(() => void) | null>,
) => {
  return useCallback(
    (showRoadVolumes: boolean) => {
      if (mapboxLayerManager.current) {
        mapboxLayerManager.current.updateLayerLayout(
          ROADS_HAIRLINES_LAYER_ID,
          "visibility",
          showRoadVolumes ? "visible" : "none",
        );

        mapboxLayerManager.current.updateLayerLayout(
          ROADS_VOLUMES_LAYER_ID,
          "visibility",
          showRoadVolumes ? "visible" : "none",
        );

        mapboxLayerManager.current.updateLayerLayout(
          LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID,
          "visibility",
          showRoadVolumes ? "visible" : "none",
        );

        if (showRoadVolumes) {
          updateRoadsModeCounts.current?.();
        }
      }
    },
    [mapboxLayerManager, updateRoadsModeCounts],
  );
};

export const useUpdateFeatureStateForRoads = (
  map: MutableRefObject<mapboxgl.Map | null>,
  layerName: string | undefined,
) => {
  return useCallback(
    (segmentId: string | null, stateName: string, status?: boolean) => {
      if (status !== undefined && map.current && layerName && typeof map.current?.setFeatureState === "function") {
        map.current.setFeatureState(
          {
            source: ROADS_SOURCE_ID,
            sourceLayer: layerName,
            id: segmentId ?? undefined,
          },
          {
            [stateName]: status,
          },
        );
      } else if (map.current && layerName && typeof map.current?.removeFeatureState === "function") {
        map.current.removeFeatureState(
          {
            source: ROADS_SOURCE_ID,
            sourceLayer: layerName,
            id: segmentId ?? undefined,
          },
          stateName,
        );
      }
    },
    [map, layerName],
  );
};

export const useHighlightSelectedSegment = (
  map: MutableRefObject<mapboxgl.Map | null>,
  selectedRoadVolume: SelectedVolume | null,
  updateFeatureStateForRoads: (segmentId: string | null, stateName: string, status?: boolean | undefined) => void,
) => {
  return useCallback(
    (selectedVolume: SelectedVolume | null) => {
      if (map.current && typeof map.current?.getLayer === "function" && map.current.getLayer(ROADS_SEGMENTS_LAYER_ID)) {
        if (selectedRoadVolume) {
          updateFeatureStateForRoads(selectedRoadVolume.ftSegmentId, "selectHighlight");
        }

        if (selectedVolume) {
          updateFeatureStateForRoads(selectedVolume.ftSegmentId, "selectHighlight", true);
        }
      }
    },
    [map, selectedRoadVolume, updateFeatureStateForRoads],
  );
};

export const useCloseRoadsAnalyticsPanel = (
  map: MutableRefObject<mapboxgl.Map | null>,
  selectedRoadVolume: SelectedVolume | null,
  mapboxVolumesPopupRef: MutableRefObject<mapboxgl.Popup | null>,
  selectRoadVolume: (selectedRoadVolume: SelectedVolume | null) => void,
  selectRoadVolumeId: (selectedRoadVolumeId: string | null) => void,
  updateFeatureStateForRoads: (segmentId: string | null, stateName: string, status?: boolean | undefined) => void,
) => {
  return useCallback(() => {
    if (selectedRoadVolume) {
      selectRoadVolume(null);

      if (map.current && typeof map.current?.getLayer === "function" && map.current.getLayer(ROADS_SEGMENTS_LAYER_ID)) {
        updateFeatureStateForRoads(selectedRoadVolume.ftSegmentId, "selectHighlight");
      }

      if (mapboxVolumesPopupRef.current && mapboxVolumesPopupRef.current.isOpen()) {
        mapboxVolumesPopupRef.current.remove();
      }

      selectRoadVolumeId(null);
    }
  }, [
    map,
    mapboxVolumesPopupRef,
    selectedRoadVolume,
    selectRoadVolume,
    selectRoadVolumeId,
    updateFeatureStateForRoads,
  ]);
};

export const useFilterRoadSegmentsByRoadClasses = (
  map: MutableRefObject<mapboxgl.Map | null>,
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
  tileServer?: CustomRoadsTileService,
) => {
  return useCallback(
    (selectedRoadClasses: number[]) => {
      if (map.current && tileServer) {
        getRoadsLayers(tileServer.layerName).forEach((layer) => {
          if (map.current!.getLayer(layer.id)) {
            mapboxLayerManager.current?.updateLayerFilter(
              layer.id,
              ["in", tileServer.facilityTypeField, ...selectedRoadClasses],
              "road_classes",
            );
          }
        });
      }
    },
    [map, mapboxLayerManager, tileServer],
  );
};

export const useFilterRoadSegmentsByRange = (
  map: MutableRefObject<mapboxgl.Map | null>,
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
  tileServer?: CustomRoadsTileService | CustomSelectLinkResultsTileService,
) => {
  return useCallback(
    (allSegmentIds: string[] | null, limitedAccessSegmentIds: string[], restAccessSegmentIds: string[]) => {
      if (map && tileServer && allSegmentIds) {
        getRoadsLayers(tileServer.layerName).forEach((layer) => {
          if (map.current!.getLayer(layer.id)) {
            switch (layer.id) {
              case ROADS_HAIRLINES_LAYER_ID:
                mapboxLayerManager.current?.updateLayerFilter(
                  layer.id,
                  ["in", tileServer.fromToSegmentIdField, ...allSegmentIds],
                  "range_filter",
                );
                break;
              case ROADS_VOLUMES_LAYER_ID:
                mapboxLayerManager.current?.updateLayerFilter(
                  layer.id,
                  ["in", tileServer.fromToSegmentIdField, ...restAccessSegmentIds],
                  "range_filter",
                );
                break;
              case LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID:
                mapboxLayerManager.current?.updateLayerFilter(
                  layer.id,
                  ["in", tileServer.fromToSegmentIdField, ...limitedAccessSegmentIds],
                  "range_filter",
                );
                break;
              default:
                break;
            }
          }
        });
      } else if (map && tileServer) {
        getRoadsLayers(tileServer.layerName).forEach((layer) => {
          if (
            map.current!.getLayer(layer.id) &&
            (layer.id === ROADS_HAIRLINES_LAYER_ID ||
              layer.id === ROADS_VOLUMES_LAYER_ID ||
              layer.id === LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID)
          ) {
            mapboxLayerManager.current?.removeLayerFilter(layer.id, "range_filter");
          }
        });
      }
    },
    [map, mapboxLayerManager, tileServer],
  );
};
