export const selectLinkODVolumesFileColumns = [
  {
    column: "start_zone_id",
    type: "Text",
    description: "Zone ID of the flow start zone",
    notes: "",
  },
  {
    column: "end_zone_id",
    type: "Text",
    description: "Zone ID of the flow end zone",
    notes: "",
  },
  {
    column: "<entitled dimension>",
    type: "",
    description: 'Textual attribute value (e.g. "NHB" for "Purpose")',
    notes: "",
  },
  {
    column: "<entitled dimension>_code",
    type: "",
    description: 'Numeric attribute value (e.g. 7 for "NHB" for "Purpose")',
    notes: "",
  },
  {
    column: "hour",
    type: "Text",
    description: "HH format of the hour of the day",
    notes: "",
  },
  {
    column: "trips",
    type: "Double",
    description: "Vehicle count",
    notes: "",
  },
];
