import { DirectionIcon } from "components_new";

import { getSelectedVolumeObject } from "features/map/utils";

import { VolumeCount } from "components";

import { LEFT_SIDE_DIRECTIONS, SegmentDirection, SelectedSegmentConfig, SelectedVolume, Volume } from "types";

export const getOpenChevronIcon = (isOpen: boolean, placement: "bottom" | "top") => {
  if (placement === "bottom") {
    return isOpen ? "chevron-up" : "chevron-down";
  } else {
    return isOpen ? "chevron-down" : "chevron-up";
  }
};

export const getDirectionLabel = (direction: SegmentDirection | undefined): string => {
  switch (direction) {
    case "N":
      return "North";
    case "S":
      return "South";
    case "E":
      return "East";
    case "W":
      return "West";
    case "NE":
      return "North-East";
    case "NW":
      return "North-West";
    case "SE":
      return "South-East";
    case "SW":
      return "South-West";
    default:
      return "";
  }
};

//Mapping between Blueprint JS icon name and SegmentDirection
export const segmentDirectionIconToSegmentDirection: { [key: string]: SegmentDirection } = {
  "arrow-up": SegmentDirection.N,
  "arrow-top-right": SegmentDirection.NE,
  "arrow-right": SegmentDirection.E,
  "arrow-bottom-right": SegmentDirection.SE,
  "arrow-down": SegmentDirection.S,
  "arrow-bottom-left": SegmentDirection.SW,
  "arrow-left": SegmentDirection.W,
  "arrow-top-left": SegmentDirection.NW,
};

//Mapping between SegmentDirection and Blueprint JS icon name
export const segmentDirectionToSegmentDirectionIcon: { [key: string]: string } = {
  [SegmentDirection.N]: "arrow-up",
  [SegmentDirection.NE]: "arrow-top-right",
  [SegmentDirection.E]: "arrow-right",
  [SegmentDirection.SE]: "arrow-bottom-right",
  [SegmentDirection.S]: "arrow-down",
  [SegmentDirection.SW]: "arrow-bottom-left",
  [SegmentDirection.W]: "arrow-left",
  [SegmentDirection.NW]: "arrow-top-left",
};

export const getVolumesByDirections = (
  volume: Volume,
  isPedestriansMode: boolean,
  couldBeAdded?: boolean,
  onClick?: (selectedVolume: SelectedVolume, id: string) => void,
  loading?: boolean,
) => {
  const handleClick = (segmentId: string) => {
    if (onClick) {
      onClick(getSelectedVolumeObject(volume), segmentId);
    }
  };

  if (isPedestriansMode) {
    return (
      <>
        <VolumeCount
          count={volume.seg_pair ? volume.volumeTF + volume.volumeFT : volume.volumeFT}
          couldBeAdded={couldBeAdded}
          onClick={() => handleClick(volume.fromToId)}
          loading={loading}
        />
      </>
    );
  }

  if (volume.seg_pair && LEFT_SIDE_DIRECTIONS.indexOf(volume.tf_dir) !== -1) {
    return (
      <>
        <VolumeCount
          icon={<DirectionIcon direction={volume.tf_dir} />}
          count={volume.volumeTF}
          onClick={() => handleClick(volume.toFromId)}
          couldBeAdded={couldBeAdded}
          loading={loading}
        />
        <VolumeCount
          icon={<DirectionIcon direction={volume.ft_dir} />}
          count={volume.volumeFT}
          onClick={() => handleClick(volume.fromToId)}
          couldBeAdded={couldBeAdded}
          loading={loading}
        />
      </>
    );
  }

  return (
    <>
      {!volume.seg_pair && <td onClick={() => handleClick(volume.fromToId)}></td>}
      <VolumeCount
        icon={<DirectionIcon direction={volume.ft_dir} />}
        count={volume.volumeFT}
        onClick={() => handleClick(volume.fromToId)}
        couldBeAdded={couldBeAdded}
        loading={loading}
      />
      {volume.seg_pair && (
        <VolumeCount
          icon={<DirectionIcon direction={volume.tf_dir} />}
          count={volume.volumeTF}
          onClick={() => handleClick(volume.toFromId)}
          couldBeAdded={couldBeAdded}
          loading={loading}
        />
      )}
    </>
  );
};

export const getSelectedSegmentConfig = (selectedVolume: SelectedVolume, id: string): SelectedSegmentConfig => {
  return {
    segmentId: id,
    streetName: selectedVolume.streetName,
    directionIcon:
      id === selectedVolume.tfSegmentId && selectedVolume.tfDirection
        ? segmentDirectionToSegmentDirectionIcon[selectedVolume.tfDirection]
        : segmentDirectionToSegmentDirectionIcon[selectedVolume.ftDirection],
    lon: selectedVolume.lngLat[0],
    lat: selectedVolume.lngLat[1],
    fromToId: selectedVolume.feature.fromToId,
  };
};
