import {
  Check,
  Clear,
  Crop54,
  FileUpload,
  Folder,
  HorizontalRule,
  MoreVertOutlined,
  Polyline,
  Save,
  SaveAs,
} from "@mui/icons-material";
import {
  Box, // FormControlLabel,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import { bbox, bboxPolygon } from "@turf/turf";
import { Screenline, ScreenlineGeometry } from "api/analytics/index.d";
import gateSegmentsPlaceholderSVG from "assets/svg/gate-segments-placeholder.svg";
import { Button, Checkbox, CheckboxProps, CircularProgress, IconButton, IconButtonProps } from "components_new";
import { LngLatLike, PaddingOptions } from "mapbox-gl";
import { FC, MutableRefObject, useCallback, useEffect, useState } from "react";

import { getBounds } from "features/map/utils";

import { RightSidebarPanel } from "components";

import { useAppDispatch, useAppSelector, usePrevious, usePrompt } from "hooks";

import { DataState } from "store/interfaces";
import {
  screenlinesActions,
  selectArePendingChanges,
  selectAreUnsavedChanges,
  selectScreenlinesLoading,
  selectSelectedScreenline,
} from "store/sections/screenlines";
import { datasetFoldersActions } from "store/sections/userFolders";

import { DeleteScreenlineDialog } from "./DeleteScreenlineDialog";
import { ImportScreenlinesDialog } from "./ImportScreenlinesDialog";
import { ScreenlineDetail } from "./ScreenlineDetail";
import { ScreenlineList } from "./ScreenlineList";
import { ScreenlineLoadDialog } from "./ScreenlineLoadDialog";
import { ScreenlinePlaceholder } from "./ScreenlinePlaceholder";
import { ScreenlineSaveDialog } from "./ScreenlineSaveDialog";
import { getScreenlinesBboxPolygon } from "./utils";

type DrawMode = "line" | "cordon";

const Container = styled("div")(({ theme }) => ({
  height: "calc(100% - 83px)",
  display: "grid",
  gridTemplateRows: "auto 110px auto auto minmax(100px, 1fr)",
  gridTemplateColumns: "358px",
  rowGap: theme.spacing(1),
  padding: `${theme.spacing(1)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
}));

export const StyledCheckbox = styled((props: CheckboxProps) => <Checkbox color="secondary" {...props} />)(
  ({ theme }) => ({
    padding: 0,
    "&:hover": {
      backgroudColor: "transparent",
    },
  }),
);

export interface ScreenlineEditorProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  draw: MutableRefObject<any | null>;
  setSelectedScreenlineId: ((screenlineId: string | null) => void) | null;
  setSelectedIntersectionId: ((intersectionId: string | null) => void) | null;
}

export const StyledIconButton = styled((props: IconButtonProps) => (
  <IconButton color="secondary" size="small" {...props} />
))(({ theme }) => ({
  height: "24px",
  width: "24px",
  border: `1px solid ${alpha(theme.palette.secondary.main, 0.5)}`,
  borderRadius: "6px",
  backgroundColor: theme.palette.common.white,

  "&:hover": {
    backgroundColor: alpha(theme.palette.secondary.light, 0.1),
    border: `1px solid ${theme.palette.secondary.main}`,
  },
}));

export const ScreenlineEditor: FC<ScreenlineEditorProps> = ({
  map,
  draw,
  setSelectedScreenlineId,
  setSelectedIntersectionId,
}) => {
  const dispatch = useAppDispatch();

  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);

  const roadClasses = useAppSelector((state) => state.filters.roadClasses);
  const availableRoadClasses = useAppSelector((state) => state.analytics.roadsMetadata.data?.roadClasses) || [];

  const isScreelineEditorOpen = useAppSelector((state) => state.screenlines.isScreelineEditorOpen);
  const isDrawScreenlineMode = useAppSelector((state) => state.screenlines.isDrawMode);
  const isLoadScreenlinesDialogOpen = useAppSelector((state) => state.screenlines.isLoadScreenlinesDialogOpen);
  const isSaveScreenlinesDialogOpen = useAppSelector((state) => state.screenlines.isSaveScreenlinesDialogOpen);
  const isImportScreenlinesDialogOpen = useAppSelector((state) => state.screenlines.isImportScreenlinesDialogOpen);

  const selectedScreenline = useAppSelector(selectSelectedScreenline);
  const screenlines = useAppSelector((state) => state.screenlines.screenlines);
  const previousScreenlines = usePrevious(screenlines);
  const draftScreenline = useAppSelector((state) => state.screenlines.draftScreenline);
  const draftFeature = useAppSelector((state) => state.screenlines.draftFeature);
  const loadedConfigDocument = useAppSelector((state) => state.datasetFolders.loadedConfigDocument);
  const arePendingChanges = useAppSelector(selectArePendingChanges);
  const screenlinesLoading = useAppSelector(selectScreenlinesLoading);
  const areUnsavedChanges = useAppSelector(selectAreUnsavedChanges);

  const [drawMenuAnchorEl, setDrawMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [screenlinesMenuAnchorEl, setScreenlinesMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isEditPropsMode, setIsEditPropsMode] = useState(false);
  const [isEditIntersectionsMode, setIsEditIntersectionsMode] = useState(false);
  const [screenlineToDelete, setScreenlineToDelete] = useState<Screenline | null>(null);
  const [updateConfigDocument] = useState(false);

  const loading = Boolean(screenlinesLoading || loadedConfigDocument.state === DataState.LOADING);
  const isDrawMenuOpen = Boolean(drawMenuAnchorEl);
  const isScreenlinesMenuOpen = Boolean(screenlinesMenuAnchorEl);
  const isEditGeometryMode = Boolean(selectedScreenline && draftFeature);
  const isEditScreenlineMode = isEditPropsMode || isEditGeometryMode || isEditIntersectionsMode;

  const showPlaceholder = isDrawScreenlineMode || screenlines.length === 0;

  usePrompt("Changes that you made may not be saved.", Boolean(arePendingChanges || areUnsavedChanges));

  const handleOpenDrawMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDrawMenuAnchorEl(event.currentTarget);
  };
  const handleCloseDrawMenu = () => {
    setDrawMenuAnchorEl(null);
  };

  const handleOpenScreenlinesMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setScreenlinesMenuAnchorEl(event.currentTarget);
  };
  const handleCloseScreenlinesMenu = () => {
    setScreenlinesMenuAnchorEl(null);
  };

  const handleSetPropsEditMode = (editMode: boolean) => {
    if (selectedScreenline) {
      dispatch(screenlinesActions.setDraftScreenline(selectedScreenline));
      setIsEditPropsMode(editMode);
    }
  };

  const handleSetEditGeometryMode = () => {
    if (selectedScreenline) {
      const featureId = draw.current?.add({
        id: selectedScreenline.id,
        type: "Feature",
        properties: {},
        geometry: selectedScreenline.geometry,
      });

      const feature = draw.current?.get(featureId);
      draw.current?.changeMode("simple_select", {
        featureIds: [featureId],
      });
      dispatch(screenlinesActions.setDraftFeature(feature));
      dispatch(screenlinesActions.setDraftScreenline(selectedScreenline));
      handleSetDrawMode(true);
    }
  };

  const handleSetEditIntersectionsMode = (editMode: boolean) => {
    if (selectedScreenline) {
      dispatch(screenlinesActions.setDraftScreenline(selectedScreenline));
      setIsEditIntersectionsMode(editMode);
    }
  };

  const handleSetDrawMode = (drawMode: boolean) => {
    if (!drawMode) draw.current?.deleteAll();
    dispatch(screenlinesActions.setDrawMode(drawMode));
  };

  const handleSelectDrawMode = (drawMode: DrawMode) => {
    if (drawMode === "line") {
      draw.current?.changeMode("draw_line_string");
    }
    if (drawMode === "cordon") {
      draw.current?.changeMode("draw_polygon");
    }

    handleCloseDrawMenu();
    handleSetDrawMode(true);
  };

  const handleDeselectDrawMode = () => {
    draw.current?.changeMode("simple_select");
    handleSetDrawMode(false);
  };

  const handleZoomOnPoint = useCallback(
    (lngLat: LngLatLike, zoom: number = 18) => {
      if (map) {
        map.current?.flyTo({
          center: lngLat,
          zoom,
        });
      }
    },
    [map],
  );

  const handleZoomOnBounds = useCallback(
    (
      geometry: ScreenlineGeometry,
      padding: number | PaddingOptions = { top: 80, bottom: 80, left: 380, right: 480 },
    ) => {
      const bounds = getBounds(geometry);
      map.current?.fitBounds(bounds, {
        padding,
      });
    },
    [map],
  );

  const handleConfirmScreenlineGeometry = () => {
    const geometry = draftFeature?.geometry as ScreenlineGeometry;

    if (timePeriod && geometry) {
      if (draftScreenline) {
        dispatch(
          screenlinesActions.updateScreenlineGeometry({
            screenline: draftScreenline,
            timePeriod,
            updatedGeometry: geometry,
            roadClasses: roadClasses || [],
            includeSegments: true,
          }),
        );
      } else {
        const id = parseInt([...(screenlines || [])].sort((a, b) => parseInt(b.id) - parseInt(a.id))[0]?.id || "0") + 1;
        dispatch(
          screenlinesActions.createScreenline({
            id: `${id}`,
            timePeriod,
            geometry: geometry,
            roadClasses: roadClasses || [],
          }),
        );
      }
    }
  };

  const handleOpenSaveDialog = () => {
    dispatch(screenlinesActions.setIsSaveScreenlineDialogOpen(true));
    setScreenlinesMenuAnchorEl(null);
  };

  const handleOpenLoadDialog = () => {
    dispatch(screenlinesActions.setIsLoadScreenlinesDialogOpen(true));
    setScreenlinesMenuAnchorEl(null);
  };

  const handleOpenImportDialog = () => {
    dispatch(screenlinesActions.setIsImportScreenlinesDialogOpen(true));
    setScreenlinesMenuAnchorEl(null);
  };

  const handleSaveScreenlines = () => {
    if (loadedConfigDocument.data) {
      const polygon = getScreenlinesBboxPolygon(screenlines);
      const { id } = loadedConfigDocument.data.configDocument;

      dispatch(
        datasetFoldersActions.editConfigDocumentPayload(id, {
          configPayload: screenlines,
          configSchemaVersion: "1.0",
          boundingBox: polygon,
        }),
      );
    }
  };

  const handleDeleteScreenline = (screenline: Screenline) => {
    setScreenlineToDelete(screenline);
  };

  const handleConfirmDeleteScreenline = () => {
    if (screenlineToDelete) {
      if (updateConfigDocument && loadedConfigDocument.data) {
        const { id } = loadedConfigDocument.data.configDocument;
        const updatedScreenlines = screenlines.filter((s) => s.id !== screenlineToDelete.id);

        // If all screenlines are deleted, use focus area bounding box
        const polygon =
          updatedScreenlines.length > 0
            ? getScreenlinesBboxPolygon(updatedScreenlines)
            : selectedFocusArea?.geometry
            ? bboxPolygon(bbox(selectedFocusArea.geometry)).geometry
            : undefined;

        dispatch(
          datasetFoldersActions.editConfigDocumentPayload(id, {
            configPayload: updatedScreenlines,
            configSchemaVersion: "1.0",
            boundingBox: polygon,
          }),
        );
      }

      dispatch(screenlinesActions.deleteScreenline(screenlineToDelete.id));
      setScreenlineToDelete(null);
    }
  };

  const handleToggleScreenlineVisibility = (screenline: Screenline) => {
    dispatch(screenlinesActions.editScreenline(screenline.id, { ...screenline, visible: !screenline.visible }));
  };

  useEffect(() => {
    const newScreenline = screenlines.find((s) => !previousScreenlines?.find((ps) => ps.id === s.id));
    if (newScreenline?.id) setSelectedScreenlineId?.(newScreenline.id);
  }, [previousScreenlines, screenlines, setSelectedScreenlineId]);

  useEffect(() => {
    if (!isScreelineEditorOpen) {
      setIsEditPropsMode(false);
      setIsEditIntersectionsMode(false);
    }
  }, [isScreelineEditorOpen]);

  const isDisabled = loading || isEditScreenlineMode || isEditGeometryMode || isEditPropsMode;

  return (
    <>
      {isSaveScreenlinesDialogOpen && <ScreenlineSaveDialog />}
      {isLoadScreenlinesDialogOpen && <ScreenlineLoadDialog />}
      {isImportScreenlinesDialogOpen && <ImportScreenlinesDialog />}
      {Boolean(screenlineToDelete) && (
        <DeleteScreenlineDialog
          screenlineToDelete={screenlineToDelete}
          isOpen={Boolean(screenlineToDelete)}
          onClose={() => setScreenlineToDelete(null)}
          handleConfirmDeleteScreenline={handleConfirmDeleteScreenline}
        />
      )}

      <RightSidebarPanel
        isOpen={isScreelineEditorOpen}
        title={"Screenlines"}
        onClose={() => {
          if ((!arePendingChanges && !areUnsavedChanges) || window.confirm("Changes that you made may not be saved."))
            dispatch(screenlinesActions.setScreelineEditorOpen(false));
        }}
        subtitle={
          <>
            <Grid container alignItems={"center"} item xs={"auto"} gap={1}>
              <Tooltip title="Open saved screenlines">
                <span>
                  <IconButton color="secondary" onClick={handleOpenLoadDialog} disabled={isDisabled}>
                    <Folder />
                  </IconButton>
                </span>
              </Tooltip>
              {loadedConfigDocument.data ? (
                <Tooltip title="Save">
                  <span>
                    <IconButton
                      color="secondary"
                      disabled={!areUnsavedChanges || !screenlines.length || isDisabled}
                      onClick={handleSaveScreenlines}
                    >
                      <Save />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : (
                <Tooltip title="Save as...">
                  <span>
                    <IconButton
                      color="secondary"
                      disabled={!screenlines.length || isDisabled}
                      onClick={handleOpenSaveDialog}
                    >
                      <SaveAs />
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              <Typography variant="subtitle2">{loadedConfigDocument.data?.configDocument.name}</Typography>
              {/* {loadedConfigDocument.data && (
                <FormControlLabel
                  control={
                    <StyledCheckbox
                      size="small"
                      sx={{ padding: "0 4px 0 11px" }}
                      checked={updateConfigDocument}
                      onChange={(e) => setUpdateConfigDocument(e.target.checked)}
                    />
                  }
                  label="Save document on edit"
                  slotProps={{ typography: { fontSize: 10 } }}
                />
              )} */}
            </Grid>

            <StyledIconButton onClick={handleOpenScreenlinesMenu} disabled={isDisabled}>
              <MoreVertOutlined fontSize="inherit" />
            </StyledIconButton>
            <Menu anchorEl={screenlinesMenuAnchorEl} open={isScreenlinesMenuOpen} onClose={handleCloseScreenlinesMenu}>
              <MenuItem disabled={!screenlines.length} onClick={handleOpenSaveDialog}>
                <ListItemIcon>
                  <SaveAs fontSize="inherit" color="secondary" />
                </ListItemIcon>
                <ListItemText>Save as...</ListItemText>
              </MenuItem>
              <MenuItem onClick={handleOpenImportDialog}>
                <ListItemIcon>
                  <FileUpload fontSize="inherit" color="secondary" />
                </ListItemIcon>
                <ListItemText>Import...</ListItemText>
              </MenuItem>
            </Menu>
          </>
        }
      >
        <Container>
          <Grid container justifyContent={"space-between"} alignItems={"center"}>
            <Grid container alignItems={"center"} gap={1} item xs={"auto"}>
              {isDrawScreenlineMode ? (
                <>
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    startIcon={<Clear fontSize="inherit" />}
                    onClick={() => handleSetDrawMode(false)}
                    sx={{ fontSize: 12 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    color="secondary"
                    startIcon={<Check fontSize="inherit" />}
                    disabled={!draftFeature || Boolean(draftScreenline && !arePendingChanges) || loading}
                    onClick={handleConfirmScreenlineGeometry}
                    sx={{ fontSize: 12 }}
                  >
                    Confirm
                  </Button>
                </>
              ) : (
                <>
                  <Tooltip
                    placement="left"
                    title="Add a new screenline"
                    disableHoverListener={loading}
                    disableFocusListener
                  >
                    <span>
                      <Button
                        startIcon={<Polyline fontSize="inherit" />}
                        color={"secondary"}
                        variant={isDrawScreenlineMode ? "contained" : "outlined"}
                        size={"small"}
                        disabled={isDisabled}
                        onClick={isDrawScreenlineMode ? handleDeselectDrawMode : handleOpenDrawMenu}
                        sx={{ fontSize: 12 }}
                      >
                        Add Screenline
                      </Button>
                    </span>
                  </Tooltip>
                  <Menu anchorEl={drawMenuAnchorEl} open={isDrawMenuOpen} onClose={handleCloseDrawMenu}>
                    <MenuItem onClick={() => handleSelectDrawMode("line")}>
                      <ListItemIcon>
                        <HorizontalRule fontSize="inherit" color="secondary" />
                      </ListItemIcon>
                      <ListItemText>Line</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => handleSelectDrawMode("cordon")}>
                      {" "}
                      <ListItemIcon>
                        <Crop54 fontSize="inherit" color="secondary" />
                      </ListItemIcon>
                      <ListItemText>Cordon</ListItemText>
                    </MenuItem>
                  </Menu>
                </>
              )}
              <Typography fontSize={11} fontWeight={500} textAlign={"right"} color={"text.secondary"}>
                {roadClasses?.length === availableRoadClasses.length
                  ? "using all road classes"
                  : `using ${roadClasses?.length} of ${availableRoadClasses.length} road classes`}
              </Typography>
            </Grid>

            {loading ? <CircularProgress color="secondary" size={20} /> : <div />}
          </Grid>

          {showPlaceholder ? (
            <ScreenlinePlaceholder
              isDrawScreenlineMode={isDrawScreenlineMode}
              isEditGeometryMode={isEditGeometryMode && selectedScreenline?.id === draftScreenline?.id}
              screenlinesLength={screenlines.length}
            />
          ) : (
            <>
              <ScreenlineList
                editMode={isEditScreenlineMode || isEditGeometryMode || isEditPropsMode}
                drawMode={isDrawScreenlineMode}
                loading={loading}
                screenlines={screenlines}
                selectedScreenline={selectedScreenline}
                setSelectedScreenlineId={setSelectedScreenlineId}
                handleZoomOnBounds={handleZoomOnBounds}
                handleDeleteScreenline={handleDeleteScreenline}
                handleToggleScreenlineVisibility={handleToggleScreenlineVisibility}
              />
              {selectedScreenline ? (
                <ScreenlineDetail
                  screenline={draftScreenline || selectedScreenline}
                  loading={loading}
                  isEditPropsMode={isEditPropsMode}
                  isEditGeometryMode={isEditGeometryMode}
                  isEditIntersectionsMode={isEditIntersectionsMode}
                  updateConfigDocument={updateConfigDocument}
                  handleSetPropsEditMode={handleSetPropsEditMode}
                  handleSetEditGeometryMode={handleSetEditGeometryMode}
                  handleSetEditIntersectionsMode={handleSetEditIntersectionsMode}
                  handleZoomOnBounds={handleZoomOnBounds}
                  handleZoomOnPoint={handleZoomOnPoint}
                  setSelectedIntersectionId={setSelectedIntersectionId}
                />
              ) : (
                <Grid container flexDirection={"column"} alignItems={"center"} gap={1} mt={4}>
                  <Box component={"img"} src={gateSegmentsPlaceholderSVG} alt={""} />
                  <Typography>No screenline selected</Typography>
                  <Typography color={"text.secondary"} variant="caption">
                    Select a screenline on the map or in the screenlines list
                  </Typography>
                </Grid>
              )}
            </>
          )}
        </Container>
      </RightSidebarPanel>
    </>
  );
};
