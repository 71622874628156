import styled from "@emotion/styled";
import { Avatar, Box, Typography } from "@mui/material";
import React, { FC } from "react";
import { Link } from "react-router-dom";

export interface EventItemProps {
  item: {
    event: string;
    url: string;
    title: string;
    subTitle: string;
    icon: React.ReactNode;
  };
  triggerGAEvent: (event: string) => void;
}

const MeetUsEventContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const MeetUsEventTitle = styled(Typography)`
  font-size: 0.9rem;
  font-weight: 600;
  a {
    color: var(--color-text);
  }
  a:hover {
    color: var(--color-text);
  }
`;

const MeetUsEventSubtitle = styled(Typography)`
  font-size: 0.75rem;
  font-weight: 400;
`;

const MeetUsEventIconAvatar = styled(Avatar)`
  background-color: #0067b01a;
  color: #0067b0;
`;

export const MeetUsEvent: FC<EventItemProps> = ({ item, triggerGAEvent }) => {
  return (
    <MeetUsEventContainer>
      <MeetUsEventIconAvatar variant="rounded">{item.icon}</MeetUsEventIconAvatar>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <MeetUsEventTitle>
          <Typography
            onClick={() => triggerGAEvent(item.event)}
            target={"_blank"}
            to={item.url}
            component={Link}
            sx={{ fontWeight: 500, textDecoration: "none" }}
          >
            {item.title}
          </Typography>
        </MeetUsEventTitle>
        <MeetUsEventSubtitle color="textSecondary">{item.subTitle}</MeetUsEventSubtitle>
      </Box>
    </MeetUsEventContainer>
  );
};
