import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { Box, Chip, ChipProps, Grid, Grow, Stack, Tooltip, Typography, styled } from "@mui/material";
import { useMemoryStore } from "api/MemoryStoreContext";
import { IntersectionDirection, SegmentIntersection } from "api/analytics/index.d";
import { MemoryStoreKeys } from "api/memoryStore";
import { DirectionIcon, IconButton } from "components_new";
import React, { ForwardedRef, forwardRef } from "react";

import { useAppDispatch, useAppSelector } from "hooks";

import { screenlinesActions, selectSelectedScreenline } from "store/sections/screenlines";

import { getIntersectionLabel } from "./utils";

interface IntersectionGroup {
  intersection: SegmentIntersection;
  candidate: boolean;
}

type IntersectionGroupType = IntersectionGroup[];

export interface ScreenlineIntersectionProps {
  intersectionGroup: IntersectionGroupType;
  leftLabel: string;
  rightLabel: string;
  selected: boolean;
  intersectionDirectionFilter: IntersectionDirection | undefined;
  editMode?: boolean;
}

const StyledChip = styled((props: ChipProps) => <Chip size="small" variant="outlined" {...props} />)(({ theme }) => ({
  paddingLeft: "4px",
  height: "auto",
  width: "100%",
  maxWidth: "150px",
  backgroundColor: "#fff !important",
  border: `1px solid ${theme.palette.secondary.light}`,
  color: theme.palette.text.secondary,

  "&.MuiChip-colorSuccess": {
    border: `1px solid ${theme.palette.candidate}`,
    color: theme.palette.grey[800],
    "& .MuiChip-deleteIcon": {
      color: `${theme.palette.candidate}`,
      "&:hover": {
        color: theme.palette.grey[800],
      },
    },
  },
  "&.MuiChip-colorWarning": {
    border: `1px solid ${theme.palette.screenline.line}`,
    color: theme.palette.grey[800],
    "& .MuiChip-deleteIcon": {
      color: `${theme.palette.screenline.line}`,
      "&:hover": {
        color: theme.palette.grey[800],
      },
    },
  },
}));

export const ScreenlineIntersection = forwardRef(
  (
    {
      intersectionGroup,
      leftLabel,
      rightLabel,
      selected,
      intersectionDirectionFilter,
      editMode,
    }: ScreenlineIntersectionProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const dispatch = useAppDispatch();
    const memoryStore = useMemoryStore();

    const roadMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
    const selectedScreenline = useAppSelector(selectSelectedScreenline);

    const { roadName, roadClass } = intersectionGroup[0].intersection;

    const areAllCandidates = intersectionGroup.every((ig) => ig.candidate);

    const addIntersections = (intersections: SegmentIntersection[]) => {
      if (selectedScreenline && intersections.length) dispatch(screenlinesActions.addIntersections(intersections));
    };

    const deleteIntersections = (intersections: SegmentIntersection[]) => {
      if (selectedScreenline && intersections.length) dispatch(screenlinesActions.deleteIntersections(intersections));
    };

    const handleAddOrDelete = (intersections: SegmentIntersection[], add: boolean) =>
      add ? addIntersections(intersections) : deleteIntersections(intersections);

    return (
      <Box ref={ref} padding={"4px 4px 8px 4px"} width={"100%"}>
        <Typography variant="body2" noWrap maxWidth={320} marginLeft={0.5}>
          {roadName || "No name"}{" "}
          <Typography variant="caption" fontStyle={"italic"} color={"text.secondary"} noWrap>
            - {roadMetadata.data?.roadClasses.find((r) => r.id === roadClass)?.label || ""}
          </Typography>
        </Typography>
        <Grid container alignItems={"center"} marginTop={"4px"} columnGap={0.5}>
          <Grid item>
            <Stack spacing={0.5} direction={"row"}>
              {[...intersectionGroup]
                .sort((a, b) => (a.intersection.intersection.direction === "left" ? 1 : -1))
                .map(({ intersection, candidate }, i) => {
                  const roadVolume = memoryStore
                    .getItem(MemoryStoreKeys.ROADS_SEGMENT_COUNTS)
                    .get(intersection.segmentId);

                  return (
                    <StyledChip
                      sx={{ borderWidth: selected ? "2px !important" : "1px !important" }}
                      key={i}
                      color={!editMode ? "default" : candidate ? "success" : "warning"}
                      icon={<DirectionIcon direction={intersection.segmentDirection} fontSize="inherit" />}
                      label={
                        <>
                          <Typography
                            noWrap
                            fontSize={12}
                            fontWeight={700}
                            sx={{
                              textDecoration:
                                intersectionDirectionFilter &&
                                intersectionDirectionFilter !== intersection.intersection.direction
                                  ? "line-through"
                                  : undefined,
                            }}
                          >
                            {roadVolume?.toLocaleString("en-US") || 0}
                          </Typography>
                          <Typography
                            noWrap
                            fontSize={9}
                            marginTop={-0.5}
                            sx={{
                              textDecoration:
                                intersectionDirectionFilter &&
                                intersectionDirectionFilter !== intersection.intersection.direction
                                  ? "line-through"
                                  : undefined,
                            }}
                          >
                            {getIntersectionLabel(intersection, leftLabel, rightLabel)}
                          </Typography>
                        </>
                      }
                      deleteIcon={
                        <Grow in={editMode && selected}>
                          <Tooltip
                            title={candidate ? "Add" : "Remove"}
                            arrow={false}
                            placement="left"
                            disableHoverListener
                          >
                            {candidate ? (
                              <AddCircleOutline fontSize="inherit" />
                            ) : (
                              <RemoveCircleOutline fontSize="inherit" />
                            )}
                          </Tooltip>
                        </Grow>
                      }
                      onDelete={() => handleAddOrDelete([intersection], candidate)}
                    />
                  );
                })}
            </Stack>
          </Grid>
          <Grid item xs={"auto"}>
            <Grow in={intersectionGroup.length > 1 && editMode && selected}>
              <IconButton
                onClick={() =>
                  handleAddOrDelete(
                    intersectionGroup.map((ig) => ig.intersection),
                    areAllCandidates,
                  )
                }
              >
                {areAllCandidates ? (
                  <AddCircleOutline
                    fontSize="medium"
                    sx={(theme) => ({
                      color: `${theme.palette.candidate}`,
                      "&:hover": { color: theme.palette.grey[800] },
                    })}
                  />
                ) : (
                  <RemoveCircleOutline
                    fontSize="medium"
                    sx={(theme) => ({
                      color: `${theme.palette.screenline.line}`,
                      "&:hover": { color: theme.palette.grey[800] },
                    })}
                  />
                )}
              </IconButton>
            </Grow>
          </Grid>
        </Grid>
      </Box>
    );
  },
);
