import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableCellProps as MuiTableCellProps,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  Paper,
  TableContainer,
  TableProps,
  styled,
} from "@mui/material";
import { FC } from "react";

export const BluePoint = styled("div")`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--color-secondary);
  margin: auto;
`;

export const SubTitle = styled("h4")`
  margin-bottom: 1rem;
`;

export const Description = styled("p")`
  margin-bottom: 1rem;
  color: var(--color-textSecondary);
`;

export const Footnote = styled("p")`
  margin-bottom: 1rem;
  color: var(--color-textSecondary);
  font-size: 12px;
`;

export const HeadTableCell = styled((props: MuiTableCellProps) => (
  <MuiTableCell variant="head" align="center" {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  "&:not(:last-of-type)": { borderRight: `1px solid ${theme.palette.divider}` },
}));

export const TableCell = styled((props: MuiTableCellProps) => <MuiTableCell variant="body" {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    "&:not(:last-of-type)": { borderRight: `1px solid ${theme.palette.divider}` },
  }),
);

export const FooterTableCell = styled((props: MuiTableCellProps) => <MuiTableCell variant="footer" {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.grey[50],
    borderBottom: 0,
    padding: 0,
  }),
);

export const TableRow = styled(MuiTableRow)(({ theme }) => ({
  "& th:first-of-type": {
    textAlign: "left",
  },
}));

export const TableBody = styled(MuiTableBody)``;

export const TableHead = styled(MuiTableHead)``;

const StyledTable = styled(MuiTable)(({ theme }) => ({}));

export const Table: FC<TableProps> = ({ children, ...props }) => (
  <TableContainer component={Paper} sx={(theme) => ({ border: `1px solid ${theme.palette.divider}`, marginBottom: 2 })}>
    <StyledTable size="small" {...props}>
      {children}
    </StyledTable>
  </TableContainer>
);
