import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { Dispatch, MutableRefObject, SetStateAction, useEffect } from "react";

import { ModuleData } from "features/map/ModuleManager";

import { useAppSelector } from "hooks";

import { MeasureType, SelectedVolume } from "types";

import { MapController } from "./MapController";
import { RoadsAnalyticsPanel } from "./RoadsAnalyticsPanel";

interface RoadsModuleComponentProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  layerManagerRef: MutableRefObject<LayerManager | null>;
  setRoadsModuleData: Dispatch<SetStateAction<ModuleData | null>>;
  updateRoadsModeCounts: MutableRefObject<(() => void) | null>;
  updateRoadsPopupCounts: MutableRefObject<
    ((selectedVolume: SelectedVolume | null, volumesProps?: any[]) => void) | null
  >;
  closeRoadsAnalyticsPanelRef: MutableRefObject<(() => void) | null>;
}

export const RoadsModuleComponent = ({
  map,
  layerManagerRef,
  setRoadsModuleData,
  updateRoadsModeCounts,
  updateRoadsPopupCounts,
  closeRoadsAnalyticsPanelRef,
}: RoadsModuleComponentProps) => {
  const measure = useAppSelector((state) => state.filters.measure);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);

  useEffect(() => {
    const closeRoadsAnalyticsPanel = closeRoadsAnalyticsPanelRef.current;

    if (timePeriod && closeRoadsAnalyticsPanel) {
      closeRoadsAnalyticsPanel();
    }

    return () => {
      if (closeRoadsAnalyticsPanel) {
        closeRoadsAnalyticsPanel();
      }

      setRoadsModuleData(null);
    };
  }, [timePeriod, setRoadsModuleData, closeRoadsAnalyticsPanelRef]);

  return (
    <>
      <MapController
        map={map}
        layerManagerRef={layerManagerRef}
        closeRoadsAnalyticsPanelRef={closeRoadsAnalyticsPanelRef}
        setRoadsModuleData={setRoadsModuleData}
        updateRoadsModeCounts={updateRoadsModeCounts}
        updateRoadsPopupCounts={updateRoadsPopupCounts}
      />

      <RoadsAnalyticsPanel
        isPedestriansMode={measure === MeasureType.PEDESTRIANS}
        closeRoadsAnalyticsPanelRef={closeRoadsAnalyticsPanelRef}
      />
    </>
  );
};
