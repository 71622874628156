import { UniqueIdentifier } from "@dnd-kit/core";
import { Api } from "api";
import {
  CatalogFolder,
  ConfigDocumentCreationRequest,
  ConfigDocumentPayloadUpdateRequest,
  ConfigDocumentUpdateRequest,
  GetConfigDocumentPayloadResponse,
  Screenline,
  SearchConfigDocumentsRequest,
} from "api/analytics/index.d";
import { produce } from "immer";
import { Reducer } from "redux";
import { all, call, getContext, put, select, takeLatest } from "redux-saga/effects";

import { mergeScreenlines, normalizeLoadedScreenlines } from "features/screenline/utils";

import {
  CatalogItem,
  CatalogItemType,
  ConfigDocument,
  CreateDatasetPayload,
  CustomZoningSelectorItemsResponse,
  Zoning,
} from "types";
import {
  CustomDataset,
  CustomDatasetRepository,
  DatasetFolder,
  DatasetFolders,
  FocusAreaItem,
  Permissions,
  PreparedZoningConfigRequest,
  ShapesInputFormat,
  UploadZoningResponse,
} from "types";

import { capitalize } from "utils/format";
import { reportAboutErrorState } from "utils/reports";

import { Action, ActionsUnion, createAction } from "../actionHelpers";
import { DataState, LoadingErrorData, ResponseError } from "../interfaces";
import { AnalyticsActionType, DatasetFoldersActionType, GlobalActionType, ScreenlinesActionType } from "./actionTypes";
import { globalActions } from "./global";

export interface DatasetFoldersState {
  folders: LoadingErrorData<DatasetFolders>;
  zoningUploading: LoadingErrorData<UploadZoningResponse>;
  zoningPreparing: LoadingErrorData<UploadZoningResponse>;
  zoningInfo: LoadingErrorData<Zoning>;
  customZoningSelectorFolders: LoadingErrorData<CustomZoningSelectorItemsResponse>;
  errorMessage: string | null;
  errorDialogMessage: string | null;
  loading: boolean;
  searchedConfigDocuments: LoadingErrorData<CatalogFolder[]>;
  loadedConfigDocument: LoadingErrorData<{ configDocument: ConfigDocument; payload: any }>;
}

interface ConfigDocumentLoadOptions {
  screenlines?: "replace" | "append";
}

export type DatasetFoldersAction = ActionsUnion<typeof datasetFoldersActions>;

export const datasetFoldersActions = {
  fetchFoldersStructure: () => createAction(DatasetFoldersActionType.FETCH_FOLDERS_STRUCTURE),
  fetchFoldersStructureSucceeded: (folders: CustomDatasetRepository, permissions: Permissions) =>
    createAction(DatasetFoldersActionType.FETCH_FOLDERS_STRUCTURE_SUCCEEDED, {
      folders,
      permissions,
    }),
  fetchFoldersStructureFailed: (error: ResponseError) =>
    createAction(DatasetFoldersActionType.FETCH_FOLDERS_STRUCTURE_FAILED, error),
  createDatasetFolder: (folderName: string) => createAction(DatasetFoldersActionType.CREATE_DATASET_FOLDER, folderName),
  createDatasetFolderSucceeded: (folder: DatasetFolder) =>
    createAction(DatasetFoldersActionType.CREATE_DATASET_FOLDER_SUCCEEDED, {
      folder,
    }),
  createDatasetFolderFailed: (errorMessage: string) =>
    createAction(DatasetFoldersActionType.CREATE_DATASET_FOLDER_FAILED, {
      errorMessage,
    }),
  renameDatasetFolder: (folderId: UniqueIdentifier, folderName: string) =>
    createAction(DatasetFoldersActionType.RENAME_DATASET_FOLDER, {
      folderId,
      folderName,
    }),
  renameDatasetFolderSucceeded: (folder: DatasetFolder) =>
    createAction(DatasetFoldersActionType.RENAME_DATASET_FOLDER_SUCCEEDED, folder),
  renameDatasetFolderFailed: (errorMessage: string) =>
    createAction(DatasetFoldersActionType.RENAME_DATASET_FOLDER_FAILED, {
      errorMessage,
    }),
  deleteDatasetFolder: (folderId: UniqueIdentifier) =>
    createAction(DatasetFoldersActionType.DELETE_DATASET_FOLDER, folderId),
  deleteDatasetFolderSucceeded: (folderId: UniqueIdentifier) =>
    createAction(DatasetFoldersActionType.DELETE_DATASET_FOLDER_SUCCEEDED, {
      folderId,
    }),
  deleteDatasetFolderFailed: (errorMessage: string) =>
    createAction(DatasetFoldersActionType.DELETE_DATASET_FOLDER_FAILED, {
      errorMessage,
    }),
  changeFolderIndex: (folderId: UniqueIdentifier, index: number) =>
    createAction(DatasetFoldersActionType.CHANGE_DATASET_FOLDER_INDEX, {
      folderId,
      index,
    }),
  addDatasetInFolder: (config: CreateDatasetPayload, openEditor: boolean) =>
    createAction(DatasetFoldersActionType.CREATE_DATASET_IN_FOLDER, { config, openEditor }),
  addDatasetInFolderSucceeded: (folderId: UniqueIdentifier, newDataset: CustomDataset) =>
    createAction(DatasetFoldersActionType.CREATE_DATASET_IN_FOLDER_SUCCEEDED, { folderId, newDataset }),
  addDatasetInFolderFailed: (errorMessage: string) =>
    createAction(DatasetFoldersActionType.CREATE_DATASET_IN_FOLDER_FAILED, {
      errorMessage,
    }),
  renameDataset: (datasetId: UniqueIdentifier, name: string, isComputed: boolean) =>
    createAction(DatasetFoldersActionType.RENAME_DATASET_IN_FOLDER, {
      datasetId,
      name,
      isComputed,
    }),
  renameDatasetSucceeded: (dataset: CustomDataset) =>
    createAction(DatasetFoldersActionType.RENAME_DATASET_IN_FOLDER_SUCCEEDED, dataset),
  renameDatasetFailed: (errorMessage: string) =>
    createAction(DatasetFoldersActionType.RENAME_DATASET_IN_FOLDER_FAILED, {
      errorMessage,
    }),
  deleteDataset: (datasetId: UniqueIdentifier, computedDatasetId: UniqueIdentifier | undefined) =>
    createAction(DatasetFoldersActionType.DELETE_DATASET_IN_FOLDER, { datasetId, computedDatasetId }),
  deleteDatasetSucceeded: (datasetId: UniqueIdentifier) =>
    createAction(DatasetFoldersActionType.DELETE_DATASET_IN_FOLDER_SUCCEEDED, {
      datasetId,
    }),
  changeCatalogItemIndex: (folderId: UniqueIdentifier, catalogItemId: UniqueIdentifier, index: number) =>
    createAction(DatasetFoldersActionType.CHANGE_CATALOG_ITEM_IN_FOLDER_INDEX, {
      folderId,
      catalogItemId,
      index,
    }),
  changeCatalogItemInFolderIndexSucceeded: (
    folderId: UniqueIdentifier,
    catalogItemId: UniqueIdentifier,
    catalogItems: CatalogItem[],
  ) =>
    createAction(DatasetFoldersActionType.CHANGE_CATALOG_ITEM_IN_FOLDER_INDEX_SUCCEEDED, {
      folderId,
      catalogItemId,
      catalogItems,
    }),
  changeCatalogItemInFolderIndexFailed: (errorMessage: string) =>
    createAction(DatasetFoldersActionType.CHANGE_CATALOG_ITEM_IN_FOLDER_INDEX_FAILED, { errorMessage }),
  copyDataset: (datasetId: UniqueIdentifier, config: CreateDatasetPayload, openEditor: boolean) =>
    createAction(DatasetFoldersActionType.COPY_DATASET_IN_FOLDER, {
      datasetId,
      config,
      openEditor,
    }),
  copyDatasetSucceeded: (folderId: UniqueIdentifier, items: CatalogItem[]) =>
    createAction(DatasetFoldersActionType.COPY_DATASET_IN_FOLDER_SUCCEEDED, {
      folderId,
      items,
    }),
  copyDatasetFailed: (errorMessage: string) =>
    createAction(DatasetFoldersActionType.COPY_DATASET_IN_FOLDER_FAILED, {
      errorMessage,
    }),
  clearErrorMessage: () => createAction(DatasetFoldersActionType.CLEAR_ERROR_MESSAGE),

  // Custom Zoning Actions
  uploadZoningShapefiles: (shapefiles: Blob, formats: ShapesInputFormat) =>
    createAction(DatasetFoldersActionType.UPLOAD_ZONING_SHAPEFILES, { shapefiles, formats }),
  uploadZoningShapefilesSucceeded: (response: UploadZoningResponse) =>
    createAction(DatasetFoldersActionType.UPLOAD_ZONING_SHAPEFILES_SUCCEEDED, response),
  uploadZoningShapefilesFailed: (error: ResponseError) =>
    createAction(DatasetFoldersActionType.UPLOAD_ZONING_SHAPEFILES_FAILED, error),
  prepareZoning: (config: PreparedZoningConfigRequest) => createAction(DatasetFoldersActionType.PREPARE_ZONING, config),
  prepareZoningSucceeded: (response: UploadZoningResponse) =>
    createAction(DatasetFoldersActionType.PREPARE_ZONING_SUCCEEDED, response),
  prepareZoningFailed: (error: ResponseError) => createAction(DatasetFoldersActionType.PREPARE_ZONING_FAILED, error),
  createZoning: (
    zoningId: string,
    folderId: string,
    name: string,
    description: string,
    openNewDatasetDialog: boolean,
  ) =>
    createAction(DatasetFoldersActionType.CREATE_ZONING, {
      zoningId,
      folderId,
      name,
      description,
      openNewDatasetDialog,
    }),
  createZoningSucceeded: (response: any) => createAction(DatasetFoldersActionType.CREATE_ZONING_SUCCEEDED, response),
  createZoningFailed: (errorMessage: string) =>
    createAction(DatasetFoldersActionType.CREATE_ZONING_FAILED, { errorMessage }),
  deleteZoning: () => createAction(DatasetFoldersActionType.DELETE_ZONING),
  deleteZoningSucceeded: () => createAction(DatasetFoldersActionType.DELETE_ZONING_SUCCEEDED),
  deleteZoningFailed: (error: ResponseError) => createAction(DatasetFoldersActionType.DELETE_ZONING_FAILED, error),
  deleteCustomZoning: (zoningId: UniqueIdentifier) =>
    createAction(DatasetFoldersActionType.DELETE_CUSTOM_ZONING, zoningId),
  deleteCustomZoningSucceeded: (zoningId: UniqueIdentifier) =>
    createAction(DatasetFoldersActionType.DELETE_CUSTOM_ZONING_SUCCEEDED, {
      zoningId,
    }),
  deleteCustomZoningFailed: (errorMessage: string) =>
    createAction(DatasetFoldersActionType.DELETE_CUSTOM_ZONING_FAILED, {
      errorMessage,
    }),
  getCustomZoningSelectorFolders: () => createAction(DatasetFoldersActionType.GET_CUSTOM_ZONING_SELECTOR_FOLDERS),
  getCustomZoningSelectorFoldersSucceeded: (folders: CustomZoningSelectorItemsResponse) =>
    createAction(DatasetFoldersActionType.GET_CUSTOM_ZONING_SELECTOR_FOLDERS_SUCCEEDED, folders),
  getCustomZoningSelectorFoldersFailed: (error: ResponseError) =>
    createAction(DatasetFoldersActionType.GET_CUSTOM_ZONING_SELECTOR_FOLDERS_FAILED, error),
  editZoning: (zoningItemId: string, name: string, description: string) =>
    createAction(DatasetFoldersActionType.EDIT_ZONING, { zoningItemId, name, description }),
  editZoningSucceeded: (response: any) => createAction(DatasetFoldersActionType.EDIT_ZONING_SUCCEEDED, response),
  editZoningFailed: (errorMessage: string) =>
    createAction(DatasetFoldersActionType.EDIT_ZONING_FAILED, { errorMessage }),
  getZoning: (zoningId: string) => createAction(DatasetFoldersActionType.GET_ZONING, zoningId),
  getZoningSucceeded: (response: Zoning) => createAction(DatasetFoldersActionType.GET_ZONING_SUCCEEDED, response),
  getZoningFailed: (error: ResponseError) => createAction(DatasetFoldersActionType.GET_ZONING_FAILED, error),
  clearZoningInfo: () => createAction(DatasetFoldersActionType.CLEAR_ZONING_INFO),

  createConfigDocument: (config: ConfigDocumentCreationRequest) =>
    createAction(DatasetFoldersActionType.CREATE_CONFIG_DOCUMENT, config),
  createConfigDocumentSucceeded: (response: ConfigDocument) =>
    createAction(DatasetFoldersActionType.CREATE_CONFIG_DOCUMENT_SUCCEEDED, response),
  createConfigDocumentFailed: (error: ResponseError) =>
    createAction(DatasetFoldersActionType.CREATE_CONFIG_DOCUMENT_FAILED, error),

  deleteConfigDocument: (documentId: UniqueIdentifier) =>
    createAction(DatasetFoldersActionType.DELETE_CONFIG_DOCUMENT, documentId),
  deleteConfigDocumentSucceeded: (configDocument: ConfigDocument) =>
    createAction(DatasetFoldersActionType.DELETE_CONFIG_DOCUMENT_SUCCEEDED, configDocument),
  deleteConfigDocumentFailed: (error: ResponseError) =>
    createAction(DatasetFoldersActionType.DELETE_CONFIG_DOCUMENT_FAILED, error),

  editConfigDocument: (configDocumentId: string, config: ConfigDocumentUpdateRequest) =>
    createAction(DatasetFoldersActionType.EDIT_CONFIG_DOCUMENT, { configDocumentId, config }),
  editConfigDocumentSucceeded: (configDocument: ConfigDocument) =>
    createAction(DatasetFoldersActionType.EDIT_CONFIG_DOCUMENT_SUCCEEDED, configDocument),
  editConfigDocumentFailed: (error: ResponseError) =>
    createAction(DatasetFoldersActionType.EDIT_CONFIG_DOCUMENT_FAILED, error),

  editConfigDocumentPayload: (configDocumentId: string, config: ConfigDocumentPayloadUpdateRequest) =>
    createAction(DatasetFoldersActionType.EDIT_CONFIG_DOCUMENT_PAYLOAD, { configDocumentId, config }),
  editConfigDocumentPayloadSucceeded: (configDocument: ConfigDocument) =>
    createAction(DatasetFoldersActionType.EDIT_CONFIG_DOCUMENT_PAYLOAD_SUCCEEDED, configDocument),
  editConfigDocumentPayloadFailed: (error: ResponseError) =>
    createAction(DatasetFoldersActionType.EDIT_CONFIG_DOCUMENT_PAYLOAD_FAILED, error),

  searchConfigDocuments: (searchQuery: SearchConfigDocumentsRequest) =>
    createAction(DatasetFoldersActionType.SEARCH_CONFIG_DOCUMENTS, searchQuery),
  searchConfigDocumentsSucceeded: (configFolders: CatalogFolder[]) =>
    createAction(DatasetFoldersActionType.SEARCH_CONFIG_DOCUMENTS_SUCCEEDED, configFolders),
  searchConfigDocumentsFailed: (error: ResponseError) =>
    createAction(DatasetFoldersActionType.SEARCH_CONFIG_DOCUMENTS_FAILED, error),

  loadConfigDocument: (configDocument: ConfigDocument, options?: ConfigDocumentLoadOptions) =>
    createAction(DatasetFoldersActionType.LOAD_CONFIG_DOCUMENT, { configDocument, options }),
  loadConfigDocumentSucceeded: (configDocument: ConfigDocument, payload: GetConfigDocumentPayloadResponse) =>
    createAction(DatasetFoldersActionType.LOAD_CONFIG_DOCUMENT_SUCCEEDED, { configDocument, payload }),
  loadConfigDocumentFailed: (error: ResponseError) =>
    createAction(DatasetFoldersActionType.LOAD_CONFIG_DOCUMENT_FAILED, error),
  resetLoadConfigDocument: () => createAction(DatasetFoldersActionType.RESET_LOAD_CONFIG_DOCUMENT),
};

const initialState: DatasetFoldersState = {
  folders: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  zoningUploading: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  zoningPreparing: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  customZoningSelectorFolders: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  zoningInfo: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  errorMessage: null,
  errorDialogMessage: null,
  loading: false,
  searchedConfigDocuments: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  loadedConfigDocument: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
};

const reducer: Reducer<DatasetFoldersState, DatasetFoldersAction> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DatasetFoldersActionType.FETCH_FOLDERS_STRUCTURE: {
        draft.folders = {
          state: DataState.LOADING,
          data: state.folders.data,
          error: null,
        };
        return;
      }
      case DatasetFoldersActionType.FETCH_FOLDERS_STRUCTURE_SUCCEEDED: {
        draft.folders = {
          state: DataState.AVAILABLE,
          data: action.payload,
          error: null,
        };
        return;
      }
      case DatasetFoldersActionType.FETCH_FOLDERS_STRUCTURE_FAILED: {
        draft.folders.state = DataState.ERROR;
        draft.folders.error = action.payload;

        return;
      }
      case DatasetFoldersActionType.CREATE_DATASET_FOLDER:
      case DatasetFoldersActionType.RENAME_DATASET_FOLDER:
      case DatasetFoldersActionType.CREATE_DATASET_IN_FOLDER:
      case DatasetFoldersActionType.RENAME_DATASET_IN_FOLDER:
      case DatasetFoldersActionType.COPY_DATASET_IN_FOLDER:
      case DatasetFoldersActionType.EDIT_ZONING: {
        draft.loading = true;
        draft.errorDialogMessage = null;
        return;
      }
      case DatasetFoldersActionType.CREATE_DATASET_FOLDER_SUCCEEDED: {
        const { folder } = action.payload;
        draft.loading = false;

        if (!draft.folders.data) return;

        draft.folders.data.folders[folder.folderId] = {
          folderName: folder.folderName,
          items: [],
          permissions: folder.permissions,
        };

        return;
      }
      case DatasetFoldersActionType.CREATE_DATASET_FOLDER_FAILED:
      case DatasetFoldersActionType.RENAME_DATASET_FOLDER_FAILED:
      case DatasetFoldersActionType.CREATE_DATASET_IN_FOLDER_FAILED:
      case DatasetFoldersActionType.RENAME_DATASET_IN_FOLDER_FAILED:
      case DatasetFoldersActionType.COPY_DATASET_IN_FOLDER_FAILED:
      case DatasetFoldersActionType.EDIT_ZONING_FAILED: {
        draft.loading = false;
        draft.errorDialogMessage = action.payload.errorMessage;
        return;
      }
      case DatasetFoldersActionType.EDIT_ZONING_SUCCEEDED: {
        draft.loading = false;

        return;
      }
      case DatasetFoldersActionType.RENAME_DATASET_FOLDER_SUCCEEDED: {
        const { folderId, folderName } = action.payload;

        draft.loading = false;
        if (!draft.folders.data) return;

        draft.folders.data.folders[folderId] = {
          ...draft.folders.data.folders[folderId],
          folderName,
        };

        return;
      }
      case DatasetFoldersActionType.DELETE_DATASET_FOLDER_SUCCEEDED: {
        const folderId = action.payload.folderId;

        if (!draft.folders.data) return;

        delete draft.folders.data.folders[folderId];

        return;
      }
      case DatasetFoldersActionType.DELETE_DATASET_FOLDER_FAILED: {
        draft.errorMessage = action.payload.errorMessage;

        return;
      }
      case DatasetFoldersActionType.CREATE_DATASET_IN_FOLDER_SUCCEEDED: {
        draft.loading = false;
        if (!draft.folders.data) return;

        if (!draft.folders.data.folders[action.payload.folderId]?.items) {
          draft.folders.data.folders[action.payload.folderId] = {
            ...draft.folders.data.folders[action.payload.folderId],
            items: [action.payload.newDataset],
          };
        } else {
          draft.folders.data.folders[action.payload.folderId].items.push(action.payload.newDataset);
        }

        return;
      }
      case DatasetFoldersActionType.RENAME_DATASET_IN_FOLDER_SUCCEEDED: {
        const { id, name, updatedAt, updatedBy } = action.payload;

        draft.loading = false;
        if (!draft.folders.data) return;

        Object.values(draft.folders.data.folders).forEach((folder) => {
          if (!folder.items) return;

          const dataset = folder.items.find((catalogItem) => catalogItem.id === id);

          if (dataset && dataset.itemType === CatalogItemType.Dataset) {
            dataset.name = name;
            dataset.updatedAt = updatedAt;
            dataset.updatedBy = updatedBy;
          }
        });

        return;
      }
      case DatasetFoldersActionType.DELETE_DATASET_IN_FOLDER_SUCCEEDED: {
        const { datasetId } = action.payload;

        if (!draft.folders.data) return;

        Object.values(draft.folders.data.folders).forEach((folder) => {
          if (!folder.items) return;

          const datasetIndex = folder.items.findIndex((catalogItem) => catalogItem.id === datasetId);

          if (datasetIndex !== -1) {
            folder.items.splice(datasetIndex, 1);
          }
        });

        return;
      }
      case DatasetFoldersActionType.CHANGE_CATALOG_ITEM_IN_FOLDER_INDEX_SUCCEEDED: {
        const { catalogItemId, folderId, catalogItems } = action.payload;

        if (!draft.folders.data) return;

        for (const [key, value] of Object.entries(draft.folders.data.folders)) {
          if (key !== folderId && value.items) {
            const catalogItemIndex = value.items.findIndex((item) => item.id === catalogItemId);

            if (catalogItemIndex !== -1) {
              value.items.splice(catalogItemIndex, 1);
            }
          }
        }

        draft.folders.data.folders[folderId] = {
          ...draft.folders.data.folders[folderId],
          items: catalogItems,
        };

        return;
      }
      case DatasetFoldersActionType.CHANGE_CATALOG_ITEM_IN_FOLDER_INDEX_FAILED: {
        draft.errorMessage = action.payload.errorMessage;
        draft.folders.data = state.folders.data ? { ...state.folders.data } : null;

        return;
      }
      case DatasetFoldersActionType.COPY_DATASET_IN_FOLDER_SUCCEEDED: {
        const { folderId, items } = action.payload;

        draft.loading = false;
        if (!draft.folders.data) return;

        draft.folders.data.folders[folderId] = {
          ...draft.folders.data.folders[folderId],
          items,
        };

        return;
      }
      case DatasetFoldersActionType.CLEAR_ERROR_MESSAGE: {
        draft.errorMessage = null;
        draft.errorDialogMessage = null;

        return;
      }
      case DatasetFoldersActionType.DELETE_CUSTOM_ZONING_SUCCEEDED: {
        const { zoningId } = action.payload;

        if (!draft.folders.data) return;

        Object.values(draft.folders.data.folders).forEach((folder) => {
          if (!folder.items) return;

          const zoningIndex = folder.items.findIndex((catalogItem) => catalogItem.id === zoningId);

          if (zoningIndex !== -1) {
            folder.items.splice(zoningIndex, 1);
          }
        });

        return;
      }
      case DatasetFoldersActionType.DELETE_CUSTOM_ZONING_FAILED: {
        draft.errorMessage = action.payload.errorMessage;

        return;
      }

      case DatasetFoldersActionType.UPLOAD_ZONING_SHAPEFILES: {
        draft.zoningUploading = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };

        return;
      }
      case DatasetFoldersActionType.UPLOAD_ZONING_SHAPEFILES_SUCCEEDED: {
        draft.zoningUploading = {
          state: DataState.AVAILABLE,
          data: action.payload,
          error: null,
        };

        return;
      }
      case DatasetFoldersActionType.UPLOAD_ZONING_SHAPEFILES_FAILED: {
        draft.zoningUploading = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };

        return;
      }
      case DatasetFoldersActionType.PREPARE_ZONING: {
        draft.zoningPreparing = {
          state: DataState.LOADING,
          data: state.zoningPreparing.data,
          error: null,
        };

        return;
      }
      case DatasetFoldersActionType.PREPARE_ZONING_SUCCEEDED: {
        draft.zoningPreparing = {
          state: DataState.AVAILABLE,
          data: action.payload,
          error: null,
        };

        return;
      }
      case DatasetFoldersActionType.PREPARE_ZONING_FAILED: {
        draft.zoningPreparing = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };

        return;
      }
      case DatasetFoldersActionType.DELETE_ZONING: {
        draft.errorDialogMessage = null;

        return;
      }
      case DatasetFoldersActionType.DELETE_ZONING_SUCCEEDED: {
        draft.zoningUploading = {
          state: DataState.EMPTY,
          data: null,
          error: null,
        };
        draft.zoningPreparing = {
          state: DataState.EMPTY,
          data: null,
          error: null,
        };

        return;
      }
      case DatasetFoldersActionType.CREATE_ZONING: {
        draft.loading = true;
        draft.errorDialogMessage = null;

        return;
      }
      case DatasetFoldersActionType.CREATE_ZONING_SUCCEEDED: {
        draft.loading = false;

        draft.zoningUploading = {
          state: DataState.EMPTY,
          data: null,
          error: null,
        };

        draft.zoningPreparing = {
          state: DataState.EMPTY,
          data: null,
          error: null,
        };

        return;
      }
      case DatasetFoldersActionType.CREATE_ZONING_FAILED: {
        draft.loading = false;
        draft.errorDialogMessage = action.payload.errorMessage;

        return;
      }
      case DatasetFoldersActionType.GET_CUSTOM_ZONING_SELECTOR_FOLDERS: {
        draft.customZoningSelectorFolders = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };

        return;
      }
      case DatasetFoldersActionType.GET_CUSTOM_ZONING_SELECTOR_FOLDERS_SUCCEEDED: {
        draft.customZoningSelectorFolders = {
          state: DataState.AVAILABLE,
          data: action.payload,
          error: null,
        };

        return;
      }
      case DatasetFoldersActionType.GET_CUSTOM_ZONING_SELECTOR_FOLDERS_FAILED: {
        draft.customZoningSelectorFolders = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };

        return;
      }
      case DatasetFoldersActionType.GET_ZONING: {
        draft.zoningInfo = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };

        return;
      }
      case DatasetFoldersActionType.GET_ZONING_SUCCEEDED: {
        draft.zoningInfo = {
          state: DataState.AVAILABLE,
          data: action.payload,
          error: null,
        };

        return;
      }
      case DatasetFoldersActionType.GET_ZONING_FAILED: {
        draft.zoningInfo = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };

        return;
      }
      case DatasetFoldersActionType.CLEAR_ZONING_INFO: {
        draft.zoningInfo = {
          state: DataState.EMPTY,
          data: null,
          error: null,
        };

        return;
      }
      case DatasetFoldersActionType.CREATE_CONFIG_DOCUMENT: {
        draft.loading = true;
        draft.errorDialogMessage = null;

        return;
      }
      case DatasetFoldersActionType.CREATE_CONFIG_DOCUMENT_SUCCEEDED: {
        draft.loading = false;

        return;
      }
      case DatasetFoldersActionType.CREATE_CONFIG_DOCUMENT_FAILED: {
        draft.loading = false;

        return;
      }
      case DatasetFoldersActionType.DELETE_CONFIG_DOCUMENT: {
        draft.loading = true;
        draft.errorDialogMessage = null;

        return;
      }
      case DatasetFoldersActionType.DELETE_CONFIG_DOCUMENT_SUCCEEDED: {
        draft.loading = false;
        const { id } = action.payload;

        if (!draft.folders.data) return;

        Object.values(draft.folders.data.folders).forEach((folder) => {
          if (!folder.items) return;

          const docIndex = folder.items.findIndex((catalogItem) => catalogItem.id === id);

          if (docIndex !== -1) {
            folder.items.splice(docIndex, 1);
          }
        });

        return;
      }
      case DatasetFoldersActionType.DELETE_CONFIG_DOCUMENT_FAILED: {
        draft.loading = false;

        return;
      }
      case DatasetFoldersActionType.EDIT_CONFIG_DOCUMENT: {
        draft.loading = true;
        draft.errorDialogMessage = null;

        return;
      }
      case DatasetFoldersActionType.EDIT_CONFIG_DOCUMENT_SUCCEEDED: {
        draft.loading = false;

        return;
      }
      case DatasetFoldersActionType.EDIT_CONFIG_DOCUMENT_FAILED: {
        draft.loading = false;

        return;
      }
      case DatasetFoldersActionType.EDIT_CONFIG_DOCUMENT_PAYLOAD: {
        draft.loading = true;
        draft.errorDialogMessage = null;

        return;
      }
      case DatasetFoldersActionType.EDIT_CONFIG_DOCUMENT_PAYLOAD_SUCCEEDED: {
        draft.loading = false;

        return;
      }
      case DatasetFoldersActionType.EDIT_CONFIG_DOCUMENT_PAYLOAD_FAILED: {
        draft.loading = false;

        return;
      }
      case DatasetFoldersActionType.SEARCH_CONFIG_DOCUMENTS: {
        draft.searchedConfigDocuments = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };

        return;
      }
      case DatasetFoldersActionType.SEARCH_CONFIG_DOCUMENTS_SUCCEEDED: {
        draft.searchedConfigDocuments = {
          state: DataState.AVAILABLE,
          data: action.payload,
          error: null,
        };

        return;
      }
      case DatasetFoldersActionType.SEARCH_CONFIG_DOCUMENTS_FAILED: {
        draft.searchedConfigDocuments = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };

        return;
      }
      case DatasetFoldersActionType.LOAD_CONFIG_DOCUMENT: {
        draft.loadedConfigDocument.state = DataState.LOADING;
        draft.loadedConfigDocument.error = null;

        return;
      }
      case DatasetFoldersActionType.LOAD_CONFIG_DOCUMENT_SUCCEEDED: {
        draft.loadedConfigDocument = {
          state: DataState.AVAILABLE,
          data: action.payload,
          error: null,
        };

        return;
      }
      case DatasetFoldersActionType.LOAD_CONFIG_DOCUMENT_FAILED: {
        draft.loadedConfigDocument = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };

        return;
      }
      case DatasetFoldersActionType.RESET_LOAD_CONFIG_DOCUMENT: {
        draft.loadedConfigDocument = {
          state: DataState.EMPTY,
          error: null,
          data: null,
        };

        return;
      }
      default:
        return state;
    }
  });

export default reducer;

function* fetchFoldersStructure(): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getDatasetFolders },
    } = api as Api;
    const { folders, permissions }: any = yield call(getDatasetFolders);

    yield put({
      type: DatasetFoldersActionType.FETCH_FOLDERS_STRUCTURE_SUCCEEDED,
      payload: { folders, permissions },
    });
  } catch (error) {
    reportAboutErrorState(error, DatasetFoldersActionType.FETCH_FOLDERS_STRUCTURE_FAILED);

    yield put({
      type: DatasetFoldersActionType.FETCH_FOLDERS_STRUCTURE_FAILED,
      payload: error,
    });
  }
}

function* createDatasetFolder(action: Action<string, string>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { addDatasetFolder },
    } = api as Api;
    const folder = yield call(addDatasetFolder, action.payload);

    yield put({
      type: DatasetFoldersActionType.CREATE_DATASET_FOLDER_SUCCEEDED,
      payload: { folder },
    });
  } catch (error: any) {
    reportAboutErrorState(error, DatasetFoldersActionType.CREATE_DATASET_FOLDER_FAILED);

    const errorMessage: string = error?.body?.what || "";
    yield put({
      type: DatasetFoldersActionType.CREATE_DATASET_FOLDER_FAILED,
      payload: { errorMessage },
    });
  }
}

function* renameDatasetFolder(action: Action<string, { folderId: UniqueIdentifier; folderName: string }>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { renameDatasetFolder },
    } = api as Api;
    const { folderId, folderName } = action.payload;
    const renamedFolder: any = yield call(renameDatasetFolder, folderId, folderName);

    yield put({
      type: DatasetFoldersActionType.RENAME_DATASET_FOLDER_SUCCEEDED,
      payload: {
        folderId: renamedFolder.folderId,
        folderName: renamedFolder.folderName,
      },
    });
  } catch (error: any) {
    reportAboutErrorState(error, DatasetFoldersActionType.RENAME_DATASET_FOLDER_FAILED);

    const errorMessage: string = error?.body?.what || "";

    yield put({
      type: DatasetFoldersActionType.RENAME_DATASET_FOLDER_FAILED,
      payload: { errorMessage },
    });
  }
}

function* deleteDatasetFolder(action: Action<string, UniqueIdentifier>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { deleteDatasetFolder },
    } = api as Api;
    const folderId = action.payload;
    const status = yield call(deleteDatasetFolder, folderId);

    if (status) {
      yield put({
        type: DatasetFoldersActionType.DELETE_DATASET_FOLDER_SUCCEEDED,
        payload: {
          folderId,
        },
      });
    } else {
      throw new Error("Failed to delete folder");
    }
  } catch (error: any) {
    reportAboutErrorState(error, DatasetFoldersActionType.DELETE_DATASET_FOLDER_FAILED);

    yield put({
      type: DatasetFoldersActionType.DELETE_DATASET_FOLDER_FAILED,
      payload: { errorMessage: error?.body?.what || "" },
    });
  }
}

function* changeDatasetFolderIndex(action: Action<string, { folderId: UniqueIdentifier; index: number }>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { changeFolderIndex },
    } = api as Api;
    const { folderId, index } = action.payload;
    const folders = yield call(changeFolderIndex, folderId, index);
    const permissions = yield select((state) => state.datasetFolders.folders.data.permissions);

    yield put({
      type: DatasetFoldersActionType.FETCH_FOLDERS_STRUCTURE_SUCCEEDED,
      payload: { folders, permissions },
    });
  } catch (error: any) {
    reportAboutErrorState(error, DatasetFoldersActionType.CHANGE_DATASET_FOLDER_INDEX_FAILED);

    yield put({
      type: DatasetFoldersActionType.CHANGE_DATASET_FOLDER_INDEX_FAILED,
      payload: error?.body?.what || "",
    });
  }
}

function* createDatasetInFolder(
  action: Action<string, { config: CreateDatasetPayload; openEditor: boolean }>,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { addDatasetInFolder },
    } = api as Api;
    const { folderId, licensedAreaId, timePeriod } = action.payload.config;

    const dataset: any = yield call(addDatasetInFolder, action.payload.config);

    yield put({
      type: DatasetFoldersActionType.CREATE_DATASET_IN_FOLDER_SUCCEEDED,
      payload: { folderId, newDataset: dataset },
    });

    if (action.payload.openEditor) {
      yield put(
        globalActions.setSelectedFocusAreaId({
          focusAreaId: licensedAreaId,
          timePeriod,
          redirectUrl: `/datasets/${dataset.id}/edit`,
        }),
      );
    }
  } catch (error: any) {
    reportAboutErrorState(error, DatasetFoldersActionType.CREATE_DATASET_IN_FOLDER_FAILED);

    const errorMessage: string = error?.body?.what || "";

    yield put({
      type: DatasetFoldersActionType.CREATE_DATASET_IN_FOLDER_FAILED,
      payload: { errorMessage },
    });
  }
}

function* renameDatasetInFolder(
  action: Action<string, { datasetId: UniqueIdentifier; name: string; isComputed: boolean }>,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { renameDataset },
    } = api as Api;
    const { datasetId, name, isComputed } = action.payload;

    const dataset: any = yield call(renameDataset, datasetId, name);

    yield put({
      type: DatasetFoldersActionType.RENAME_DATASET_IN_FOLDER_SUCCEEDED,
      payload: dataset,
    });

    if (isComputed) yield put({ type: AnalyticsActionType.FETCH_FOCUS_AREAS_AND_DATASETS });
  } catch (error: any) {
    reportAboutErrorState(error, DatasetFoldersActionType.RENAME_DATASET_IN_FOLDER_FAILED);

    const errorMessage: string = error?.body?.what || "";

    yield put({
      type: DatasetFoldersActionType.RENAME_DATASET_IN_FOLDER_FAILED,
      payload: { errorMessage },
    });
  }
}

function* deleteDatasetInFolder(
  action: Action<string, { datasetId: UniqueIdentifier; computedDatasetId: UniqueIdentifier }>,
): Generator {
  try {
    const { datasetId, computedDatasetId } = action.payload;
    const api = yield getContext("api");
    const {
      analyticsApi: { deleteDataset },
    } = api as Api;

    const status = yield call(deleteDataset, datasetId);

    const selectedFocusAreaId: any = yield select((state) => state.global.selectedFocusAreaId);
    const focusAreas: any = yield select((state) => state.analytics.focusAreasAndDatasets.data);
    const filteredFocusAreas = focusAreas.filter((area: FocusAreaItem) => area.id !== computedDatasetId);

    if (selectedFocusAreaId === computedDatasetId) {
      yield put({
        type: GlobalActionType.SET_SELECTED_FOCUS_AREA_ID,
        payload: {
          focusAreaId: filteredFocusAreas[0].id,
        },
      });
    }

    yield put({
      type: AnalyticsActionType.FETCH_FOCUS_AREAS_AND_DATASETS_SUCCEEDED,
      payload: { focusAreasAndDatasets: filteredFocusAreas },
    });

    if (status) {
      yield put({
        type: DatasetFoldersActionType.DELETE_DATASET_IN_FOLDER_SUCCEEDED,
        payload: {
          datasetId,
        },
      });
    } else {
      throw new Error("Failed to delete dataset");
    }
  } catch (error) {
    reportAboutErrorState(error, DatasetFoldersActionType.DELETE_DATASET_IN_FOLDER_FAILED);

    yield put({
      type: DatasetFoldersActionType.DELETE_DATASET_IN_FOLDER_FAILED,
      payload: error,
    });
  }
}

function* changeCatalogItemInFolderIndex(
  action: Action<string, { folderId: UniqueIdentifier; catalogItemId: UniqueIdentifier; index: number }>,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { changeCatalogItemIndex },
    } = api as Api;
    const { folderId, catalogItemId, index } = action.payload;
    const catalogItems = yield call(changeCatalogItemIndex, folderId, catalogItemId, index);

    yield put({
      type: DatasetFoldersActionType.CHANGE_CATALOG_ITEM_IN_FOLDER_INDEX_SUCCEEDED,
      payload: { catalogItems, catalogItemId, folderId },
    });
  } catch (error: any) {
    reportAboutErrorState(error, DatasetFoldersActionType.CHANGE_CATALOG_ITEM_IN_FOLDER_INDEX_FAILED);

    const errorMessage: string = error?.body?.msg || error?.body?.what || "";

    yield put({
      type: DatasetFoldersActionType.CHANGE_CATALOG_ITEM_IN_FOLDER_INDEX_FAILED,
      payload: { errorMessage },
    });
  }
}

function* copyDatasetInFolder(
  action: Action<
    string,
    {
      datasetId: UniqueIdentifier;
      config: CreateDatasetPayload;
      openEditor: boolean;
    }
  >,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { copyDataset },
    } = api as Api;
    const { datasetName, folderId, licensedAreaId, timePeriod } = action.payload.config;

    const { copiedDatasetId, items }: any = yield call(copyDataset, action.payload.datasetId, datasetName, timePeriod);

    if (action.payload.openEditor) {
      yield put(
        globalActions.setSelectedFocusAreaId({
          focusAreaId: licensedAreaId,
          timePeriod,
          redirectUrl: `/datasets/${copiedDatasetId}/edit`,
        }),
      );
    }

    yield put({
      type: DatasetFoldersActionType.COPY_DATASET_IN_FOLDER_SUCCEEDED,
      payload: { folderId, items },
    });
  } catch (error: any) {
    reportAboutErrorState(error, DatasetFoldersActionType.COPY_DATASET_IN_FOLDER_FAILED);

    const errorMessage: string = error?.body?.what || "";

    yield put({
      type: DatasetFoldersActionType.COPY_DATASET_IN_FOLDER_FAILED,
      payload: { errorMessage },
    });
  }
}

function* deleteCustomZoning(action: Action<string, UniqueIdentifier>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { deleteCustomZoning },
    } = api as Api;
    const zoningId = action.payload;
    yield call(deleteCustomZoning, String(zoningId));

    yield put({
      type: DatasetFoldersActionType.DELETE_CUSTOM_ZONING_SUCCEEDED,
      payload: {
        zoningId,
      },
    });
  } catch (error: any) {
    reportAboutErrorState(error, DatasetFoldersActionType.DELETE_CUSTOM_ZONING_FAILED);

    yield put({
      type: DatasetFoldersActionType.DELETE_CUSTOM_ZONING_FAILED,
      payload: { errorMessage: error?.body?.what || "" },
    });
  }
}

function* uploadZoningShapefiles(action: Action<string, { shapefiles: Blob; formats: ShapesInputFormat }>): Generator {
  try {
    const api = yield getContext("api");
    const { shapefiles, formats } = action.payload;
    const {
      analyticsApi: { uploadZoningShapefiles },
    } = api as Api;
    const zoningShapefilesResponse: UploadZoningResponse | unknown = yield call(
      uploadZoningShapefiles,
      shapefiles,
      formats,
    );

    yield put({
      type: DatasetFoldersActionType.UPLOAD_ZONING_SHAPEFILES_SUCCEEDED,
      payload: zoningShapefilesResponse,
    });
  } catch (e) {
    reportAboutErrorState(e, DatasetFoldersActionType.UPLOAD_ZONING_SHAPEFILES_FAILED);

    yield put({
      type: DatasetFoldersActionType.UPLOAD_ZONING_SHAPEFILES_FAILED,
      payload: e,
    });
  }
}

function* prepareZoning(action: Action<string, PreparedZoningConfigRequest>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { deleteZoning, prepareZoning },
    } = api as Api;
    const zoningPreparing: any = yield select((state) => state.datasetFolders.zoningPreparing.data);

    if (zoningPreparing?.zoning?.zoningId) {
      yield call(deleteZoning, zoningPreparing.zoning.zoningId);
    }

    const preparedZoningResponse: UploadZoningResponse | unknown = yield call(prepareZoning, action.payload);

    yield put({
      type: DatasetFoldersActionType.PREPARE_ZONING_SUCCEEDED,
      payload: preparedZoningResponse,
    });
  } catch (error: any) {
    reportAboutErrorState(error, DatasetFoldersActionType.PREPARE_ZONING_FAILED);

    yield put({
      type: DatasetFoldersActionType.PREPARE_ZONING_FAILED,
      payload: { errorMessage: error?.body?.what || "" },
    });
  }
}

function* deleteZoning(action: Action<string>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { deleteZoning },
    } = api as Api;
    const zoningUploading: any = yield select((state) => state.datasetFolders.zoningUploading.data);
    const zoningPreparing: any = yield select((state) => state.datasetFolders.zoningPreparing.data);

    let status = true;

    if (zoningUploading?.zoning?.zoningId) {
      status = status && Boolean(yield call(deleteZoning, zoningUploading.zoning.zoningId));
    }

    if (zoningPreparing?.zoning?.zoningId) {
      status = status && Boolean(yield call(deleteZoning, zoningPreparing.zoning.zoningId));
    }

    if (status) {
      yield put({
        type: DatasetFoldersActionType.DELETE_ZONING_SUCCEEDED,
      });
    } else {
      throw new Error("Failed to delete zoning");
    }
  } catch (e) {
    reportAboutErrorState(e, DatasetFoldersActionType.DELETE_ZONING_FAILED);

    yield put({
      type: DatasetFoldersActionType.DELETE_ZONING_FAILED,
      payload: e,
    });
  }
}

function* createZoning(
  action: Action<
    string,
    { zoningId: string; folderId: string; name: string; description: string; openNewDatasetDialog: boolean }
  >,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { createZoning, deleteZoning },
    } = api as Api;
    const { zoningId, folderId, name, description, openNewDatasetDialog } = action.payload;
    const zoning: any = yield call(createZoning, zoningId, folderId, name, description);
    const zoningUploading: any = yield select((state) => state.datasetFolders.zoningUploading.data);

    if (zoning && zoningUploading.zoning.zoningId) {
      yield call(deleteZoning, zoningUploading.zoning.zoningId);
    }

    yield put({
      type: DatasetFoldersActionType.CREATE_ZONING_SUCCEEDED,
      payload: {
        zoning,
      },
    });

    yield put({
      type: DatasetFoldersActionType.FETCH_FOLDERS_STRUCTURE,
    });

    if (openNewDatasetDialog) {
      yield put({
        type: GlobalActionType.SET_REDIRECT_URL,
        payload: `/datasets?folderId=${folderId}&zoningId=${zoning.id}&zoningName=${zoning.name}`,
      });
    }
  } catch (error: any) {
    reportAboutErrorState(error, DatasetFoldersActionType.CREATE_ZONING_FAILED);

    yield put({
      type: DatasetFoldersActionType.CREATE_ZONING_FAILED,
      payload: {
        errorMessage: error?.body?.what || "",
      },
    });
  }
}

function* editZoning(action: Action<string, { zoningItemId: string; name: string; description: string }>): Generator {
  try {
    const api = yield getContext("api");

    const {
      analyticsApi: { editZoning },
    } = api as Api;

    const { zoningItemId, name, description } = action.payload;

    yield call(editZoning, zoningItemId, name, description);

    yield put({
      type: DatasetFoldersActionType.EDIT_ZONING_SUCCEEDED,
    });

    yield put({
      type: DatasetFoldersActionType.FETCH_FOLDERS_STRUCTURE,
    });
  } catch (error: any) {
    reportAboutErrorState(error, DatasetFoldersActionType.EDIT_ZONING_FAILED);

    yield put({
      type: DatasetFoldersActionType.EDIT_ZONING_FAILED,
      payload: {
        errorMessage: error?.body?.what || "",
      },
    });
  }
}

function* getCustomZoningSelectorFolders(): Generator {
  try {
    const api = yield getContext("api");

    const {
      analyticsApi: { getCustomZoningSelectorList },
    } = api as Api;

    const folders = yield call(getCustomZoningSelectorList);

    yield put({
      type: DatasetFoldersActionType.GET_CUSTOM_ZONING_SELECTOR_FOLDERS_SUCCEEDED,
      payload: folders,
    });
  } catch (error: any) {
    reportAboutErrorState(error, DatasetFoldersActionType.GET_CUSTOM_ZONING_SELECTOR_FOLDERS_FAILED);

    yield put({
      type: DatasetFoldersActionType.GET_CUSTOM_ZONING_SELECTOR_FOLDERS_FAILED,
      payload: error,
    });
  }
}

function* fetchZoning(action: Action<string, string>): Generator {
  try {
    const api = yield getContext("api");

    const {
      analyticsApi: { getZoning },
    } = api as Api;

    const zoning = yield call(getZoning, action.payload);

    yield put({
      type: DatasetFoldersActionType.GET_ZONING_SUCCEEDED,
      payload: zoning,
    });
  } catch (error: any) {
    reportAboutErrorState(error, DatasetFoldersActionType.GET_ZONING_FAILED);

    yield put({
      type: DatasetFoldersActionType.GET_ZONING_FAILED,
      payload: error,
    });
  }
}

function* createConfigDocument(action: Action<string, ConfigDocumentCreationRequest>): Generator {
  try {
    const api = yield getContext("api");

    const {
      analyticsApi: { createConfigDocument },
    } = api as Api;

    const { configDocumentType, configDocumentName } = action.payload;

    const configDocument = yield call(createConfigDocument, action.payload);

    yield put({
      type: DatasetFoldersActionType.CREATE_CONFIG_DOCUMENT_SUCCEEDED,
      payload: configDocument,
    });

    const screenlines = yield select((state) => state.screenlines.screenlines);

    yield put({
      type: DatasetFoldersActionType.LOAD_CONFIG_DOCUMENT_SUCCEEDED,
      payload: { configDocument, payload: screenlines },
    });

    yield put({
      type: GlobalActionType.SET_TOAST_MESSAGE,
      payload: {
        content: `${capitalize(configDocumentType)} document "${configDocumentName}" has been created`,
        severity: "success",
      },
    });

    yield put({
      type: ScreenlinesActionType.SET_SAVE_SCREENLINE_DIALOG_OPEN,
      payload: false,
    });
  } catch (error: any) {
    reportAboutErrorState(error, DatasetFoldersActionType.CREATE_CONFIG_DOCUMENT_FAILED);

    yield put({
      type: GlobalActionType.SET_TOAST_MESSAGE,
      payload: {
        content: error.body?.what || "Failed to create config document",
        severity: "error",
      },
    });

    yield put({
      type: DatasetFoldersActionType.CREATE_CONFIG_DOCUMENT_FAILED,
      payload: error,
    });
  }
}

function* deleteConfigDocument(action: Action<string, UniqueIdentifier>): Generator {
  try {
    const api = yield getContext("api");

    const {
      analyticsApi: { deleteConfigDocument },
    } = api as Api;

    const configDocument = yield call(deleteConfigDocument, action.payload as string);

    const loadedConfigDocument: any = yield select((state) => state.datasetFolders.loadedConfigDocument.data);

    if (loadedConfigDocument?.configDocument?.id === action.payload) {
      yield put({
        type: DatasetFoldersActionType.RESET_LOAD_CONFIG_DOCUMENT,
      });
    }

    yield put({
      type: DatasetFoldersActionType.DELETE_CONFIG_DOCUMENT_SUCCEEDED,
      payload: configDocument,
    });
  } catch (error: any) {
    reportAboutErrorState(error, DatasetFoldersActionType.DELETE_CONFIG_DOCUMENT_FAILED);

    yield put({
      type: DatasetFoldersActionType.DELETE_CONFIG_DOCUMENT_FAILED,
      payload: error,
    });
  }
}

function* editConfigDocument(
  action: Action<string, { configDocumentId: string; config: ConfigDocumentUpdateRequest }>,
): Generator {
  try {
    const api = yield getContext("api");

    const {
      analyticsApi: { updateConfigDocument },
    } = api as Api;
    const { configDocumentId, config } = action.payload;

    const configDocument = yield call(updateConfigDocument, configDocumentId, config);

    yield put({
      type: DatasetFoldersActionType.EDIT_CONFIG_DOCUMENT_SUCCEEDED,
      payload: configDocument,
    });
    yield put({
      type: DatasetFoldersActionType.FETCH_FOLDERS_STRUCTURE,
    });
  } catch (error: any) {
    reportAboutErrorState(error, DatasetFoldersActionType.EDIT_CONFIG_DOCUMENT_FAILED);

    yield put({
      type: GlobalActionType.SET_TOAST_MESSAGE,
      payload: {
        content: error.body?.what || "Failed to edit config document",
        severity: "error",
      },
    });

    yield put({
      type: DatasetFoldersActionType.EDIT_CONFIG_DOCUMENT_FAILED,
      payload: error,
    });
  }
}

function* editConfigDocumentPayload(
  action: Action<string, { configDocumentId: string; config: ConfigDocumentPayloadUpdateRequest }>,
): Generator {
  try {
    const api = yield getContext("api");

    const {
      analyticsApi: { updateConfigDocumentPayload },
    } = api as Api;
    const { configDocumentId, config } = action.payload;

    const configDocument = yield call(updateConfigDocumentPayload, configDocumentId, config);

    yield put({
      type: DatasetFoldersActionType.EDIT_CONFIG_DOCUMENT_PAYLOAD_SUCCEEDED,
      payload: configDocument,
    });
    yield put({
      type: DatasetFoldersActionType.FETCH_FOLDERS_STRUCTURE,
    });
    yield put({
      type: DatasetFoldersActionType.LOAD_CONFIG_DOCUMENT,
      payload: { configDocument },
    });
  } catch (error: any) {
    reportAboutErrorState(error, DatasetFoldersActionType.EDIT_CONFIG_DOCUMENT_PAYLOAD_FAILED);

    yield put({
      type: DatasetFoldersActionType.EDIT_CONFIG_DOCUMENT_PAYLOAD_FAILED,
      payload: error,
    });
  }
}

function* searchConfigDocuments(action: Action<string, SearchConfigDocumentsRequest>): Generator {
  try {
    const api = yield getContext("api");

    const {
      analyticsApi: { searchConfigDocuments },
    } = api as Api;
    const { type, filterByLicensedArea, excludeEmptyFolders, folderId, timePeriod } = action.payload;

    const response: any = yield call(
      searchConfigDocuments,
      type,
      filterByLicensedArea,
      excludeEmptyFolders,
      folderId,
      timePeriod,
    );

    yield put({
      type: DatasetFoldersActionType.SEARCH_CONFIG_DOCUMENTS_SUCCEEDED,
      payload: response.folders,
    });
  } catch (error: any) {
    reportAboutErrorState(error, DatasetFoldersActionType.SEARCH_CONFIG_DOCUMENTS_FAILED);

    yield put({
      type: DatasetFoldersActionType.SEARCH_CONFIG_DOCUMENTS_FAILED,
      payload: error,
    });
  }
}

function* loadConfigDocumentPayload(
  action: Action<string, { configDocument: ConfigDocument; options?: ConfigDocumentLoadOptions }>,
): Generator {
  try {
    const api = yield getContext("api");

    const {
      analyticsApi: { getConfigDocumentPayload },
    } = api as Api;

    const { configDocument, options } = action.payload;

    const configDocumentPayload: any = yield call(getConfigDocumentPayload, configDocument.id);
    const normalizedLoadedScreenlines = normalizeLoadedScreenlines(configDocumentPayload.configPayload as Screenline[]);

    yield put({
      type: DatasetFoldersActionType.LOAD_CONFIG_DOCUMENT_SUCCEEDED,
      payload: { configDocument: action.payload.configDocument, payload: normalizedLoadedScreenlines },
    });

    const screenlines: any = yield select((state) => state.screenlines.screenlines);

    const appendToExistingScreenlines = options?.screenlines === "append";

    const newScreenlines = appendToExistingScreenlines
      ? mergeScreenlines(screenlines, normalizedLoadedScreenlines)
      : normalizedLoadedScreenlines;

    if (!appendToExistingScreenlines) {
      yield put({
        type: ScreenlinesActionType.RESET_SCREENLINE_COUNTS,
      });
    }

    yield put({
      type: ScreenlinesActionType.SET_SCREENLINES,
      payload: newScreenlines,
    });
  } catch (error: any) {
    reportAboutErrorState(error, DatasetFoldersActionType.LOAD_CONFIG_DOCUMENT_FAILED);

    yield put({
      type: DatasetFoldersActionType.LOAD_CONFIG_DOCUMENT_FAILED,
      payload: error,
    });
  }
}

export function* datasetFoldersSaga() {
  yield all([
    takeLatest(DatasetFoldersActionType.FETCH_FOLDERS_STRUCTURE, fetchFoldersStructure),
    takeLatest(DatasetFoldersActionType.CREATE_DATASET_FOLDER, createDatasetFolder),
    takeLatest(DatasetFoldersActionType.RENAME_DATASET_FOLDER, renameDatasetFolder),
    takeLatest(DatasetFoldersActionType.DELETE_DATASET_FOLDER, deleteDatasetFolder),
    takeLatest(DatasetFoldersActionType.CHANGE_DATASET_FOLDER_INDEX, changeDatasetFolderIndex),
    takeLatest(DatasetFoldersActionType.CREATE_DATASET_IN_FOLDER, createDatasetInFolder),
    takeLatest(DatasetFoldersActionType.RENAME_DATASET_IN_FOLDER, renameDatasetInFolder),
    takeLatest(DatasetFoldersActionType.DELETE_DATASET_IN_FOLDER, deleteDatasetInFolder),
    takeLatest(DatasetFoldersActionType.CHANGE_CATALOG_ITEM_IN_FOLDER_INDEX, changeCatalogItemInFolderIndex),
    takeLatest(DatasetFoldersActionType.COPY_DATASET_IN_FOLDER, copyDatasetInFolder),
    takeLatest(DatasetFoldersActionType.DELETE_CUSTOM_ZONING, deleteCustomZoning),
    takeLatest(DatasetFoldersActionType.UPLOAD_ZONING_SHAPEFILES, uploadZoningShapefiles),
    takeLatest(DatasetFoldersActionType.PREPARE_ZONING, prepareZoning),
    takeLatest(DatasetFoldersActionType.CREATE_ZONING, createZoning),
    takeLatest(DatasetFoldersActionType.DELETE_ZONING, deleteZoning),
    takeLatest(DatasetFoldersActionType.GET_CUSTOM_ZONING_SELECTOR_FOLDERS, getCustomZoningSelectorFolders),
    takeLatest(DatasetFoldersActionType.EDIT_ZONING, editZoning),
    takeLatest(DatasetFoldersActionType.GET_ZONING, fetchZoning),
    takeLatest(DatasetFoldersActionType.CREATE_CONFIG_DOCUMENT, createConfigDocument),
    takeLatest(DatasetFoldersActionType.DELETE_CONFIG_DOCUMENT, deleteConfigDocument),
    takeLatest(DatasetFoldersActionType.EDIT_CONFIG_DOCUMENT, editConfigDocument),
    takeLatest(DatasetFoldersActionType.EDIT_CONFIG_DOCUMENT_PAYLOAD, editConfigDocumentPayload),
    takeLatest(DatasetFoldersActionType.SEARCH_CONFIG_DOCUMENTS, searchConfigDocuments),
    takeLatest(DatasetFoldersActionType.LOAD_CONFIG_DOCUMENT, loadConfigDocumentPayload),
  ]);
}
