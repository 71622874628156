import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { useMemoryStore } from "api/MemoryStoreContext";
import { MemoryStoreKeys } from "api/memoryStore";
import { isEqual } from "lodash";
import find from "lodash/find";
import { Popup } from "mapbox-gl";
import { Dispatch, MutableRefObject, RefObject, SetStateAction, useEffect, useRef, useState } from "react";

import { ModuleData } from "features/map/ModuleManager";

import { useAppDispatch, useAppSelector, useStateRef } from "hooks";

import { DataState } from "store/interfaces";
import { filtersActions } from "store/sections/filters";

import { Measure, MeasureType, SelectedVolume, Volume } from "types";

import {
  useChangeShowRoadVolumes,
  useCloseRoadsAnalyticsPanel,
  useFilterRoadSegmentsByRange,
  useFilterRoadSegmentsByRoadClasses,
  useGetRoadSegmentIds,
  useGetRoadsVolumes,
  useGetSegmentsDetails,
  useGetVisibilityStatus,
  useGetVisibleRoadIds,
  useHighlightSelectedSegment,
  useSelectRoadVolume,
  useSelectRoadVolumeId,
  useUpdateFeatureStateForRoads,
} from "./RoadsControllerCallbacks";
import { getRoadsLayers } from "./map-data/layers";
import { getRoadsSources } from "./map-data/sources";

interface Props {
  map: MutableRefObject<mapboxgl.Map | null>;
  layerManagerRef: MutableRefObject<LayerManager | null>;
  closeRoadsAnalyticsPanelRef: RefObject<() => void>;
  setRoadsModuleData: Dispatch<SetStateAction<ModuleData | null>>;
  updateRoadsModeCounts: MutableRefObject<(() => void) | null>;
  updateRoadsPopupCounts: MutableRefObject<
    ((selectedVolume: SelectedVolume | null, volumesProps?: any[]) => void) | null
  >;
}

export const MapController = ({
  map,
  layerManagerRef,
  closeRoadsAnalyticsPanelRef,
  setRoadsModuleData,
  updateRoadsModeCounts,
  updateRoadsPopupCounts,
}: Props) => {
  const dispatch = useAppDispatch();
  const memoryStore = useMemoryStore();

  const [volumeProps, setVolumeProps] = useState<Volume[]>([]);
  const [layerName, setLayerName] = useState<string>("");

  //Global
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);

  //Screenlines
  const selectedScreenlineId = useAppSelector((state) => state.screenlines.selectedScreenlineId);
  const isScreelineEditorOpen = useAppSelector((state) => state.screenlines.isScreelineEditorOpen);

  // Roads and road volumes
  const measure = useAppSelector((state) => state.filters.measure);
  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
  const roadSegmentIds = useAppSelector((state) => state.analytics.roadSegmentIds);
  const roadsVolumes = useAppSelector((state) => state.analytics.roadsVolumes);
  const selectedRoadVolume = useAppSelector((state) => state.analytics.selectedRoadVolume);
  const roadSegmentsDetails = useAppSelector((state) => state.analytics.roadSegmentsDetails);
  const currentRoadFilters = useAppSelector((state) => state.filters.roadFilters);
  const selectedRoadClasses = useAppSelector((state) => state.filters.roadClasses);
  const roadsRange = useAppSelector((state) => state.analytics.roadsRange);
  const showRoadVolumes = useAppSelector((state) => state.map.showRoadVolumes);

  const mapboxVolumesPopupRef = useRef<Popup>(null);
  const maxSegmentVolumeRef = useRef<number>(1);
  const showRoadVolumesRef = useRef<boolean>(showRoadVolumes);

  const isRoadsDataAvailable = Boolean(
    roadsMetadata.state === DataState.AVAILABLE &&
      roadsMetadata.data?.tileService &&
      roadSegmentIds.state === DataState.AVAILABLE &&
      roadSegmentIds.data &&
      roadsVolumes.state === DataState.AVAILABLE &&
      roadsVolumes.data,
  );

  const blockClickEventRef = useStateRef(isScreelineEditorOpen);

  // Roads callbacks
  const getRoadSegmentIds = useGetRoadSegmentIds(selectedFocusArea, timePeriod);
  const getVisibleRoadIds = useGetVisibleRoadIds(memoryStore);
  const getRoadsVolumes = useGetRoadsVolumes(selectedFocusArea, timePeriod);
  const getSegmentsDetails = useGetSegmentsDetails(
    roadsMetadata.data,
    currentRoadFilters,
    selectedFocusArea,
    timePeriod,
  );
  const getVisibilityStatus = useGetVisibilityStatus(showRoadVolumesRef);
  const selectRoadVolume = useSelectRoadVolume();
  const selectRoadVolumeId = useSelectRoadVolumeId();
  const changeShowRoadVolumes = useChangeShowRoadVolumes(layerManagerRef, updateRoadsModeCounts);
  const updateFeatureStateForRoads = useUpdateFeatureStateForRoads(map, layerName);
  const highlightSelectedSegment = useHighlightSelectedSegment(map, selectedRoadVolume, updateFeatureStateForRoads);
  const closeRoadsAnalyticsPanel = useCloseRoadsAnalyticsPanel(
    map,
    selectedRoadVolume,
    mapboxVolumesPopupRef,
    selectRoadVolume,
    selectRoadVolumeId,
    updateFeatureStateForRoads,
  );
  const filterRoadSegmentsByRoadClasses = useFilterRoadSegmentsByRoadClasses(
    map,
    layerManagerRef,
    roadsMetadata.data?.tileService,
  );
  const filterRoadSegmentsByRange = useFilterRoadSegmentsByRange(map, layerManagerRef, roadsMetadata.data?.tileService);

  // Switch to first measure if currently selected is not available
  useEffect(() => {
    if (
      measure &&
      roadsMetadata.state === DataState.AVAILABLE &&
      !roadsMetadata.data?.measures.find((m) => m.columnName === measure)
    ) {
      const firstMeasure = roadsMetadata.data.measures[0].columnName;
      dispatch(filtersActions.setMeasure(firstMeasure as MeasureType));
    }
  }, [roadsMetadata.state, roadsMetadata.data?.measures, measure, dispatch]);

  // Fetch Segment IDs
  useEffect(() => {
    if (roadSegmentIds.state === DataState.EMPTY) {
      getRoadSegmentIds();
    }
  }, [roadSegmentIds.state, getRoadSegmentIds]);

  // Fetch roads volumes
  useEffect(() => {
    if (
      roadsVolumes.state !== DataState.LOADING &&
      roadsMetadata.state === DataState.AVAILABLE &&
      selectedFocusArea &&
      currentRoadFilters
    ) {
      if (
        roadsVolumes.state === DataState.EMPTY &&
        // Check if all dimensions are present in the filters, if not then updating will be done in the Filters component
        isEqual(
          Object.keys(currentRoadFilters),
          roadsMetadata.data.measures
            .find((d: Measure) => d.columnName === measure)
            ?.dimensions.filter((d: Measure) => d.enabled)
            .map((d: Measure) => d.columnName),
        )
      ) {
        getRoadsVolumes(currentRoadFilters, measure);
      }

      if (roadsVolumes.state === DataState.AVAILABLE) {
        maxSegmentVolumeRef.current = roadsVolumes.data?.maxVolume || 1;
        updateRoadsModeCounts.current?.();
      }
    }
  }, [
    map,
    measure,
    currentRoadFilters,
    roadsMetadata.state,
    roadsMetadata.data?.measures,
    roadsVolumes.state,
    roadsVolumes.data,
    selectedFocusArea,
    getRoadsVolumes,
    updateRoadsModeCounts,
  ]);

  // Close roads analytics panel on measure change
  useEffect(() => {
    if (measure) {
      closeRoadsAnalyticsPanelRef.current?.();
    }
  }, [measure, closeRoadsAnalyticsPanelRef]);

  // Update showRoadVolumes ref
  useEffect(() => {
    showRoadVolumesRef.current = showRoadVolumes;
  }, [showRoadVolumes]);

  // Fetch segment details for selected road volume
  useEffect(() => {
    if (
      selectedRoadVolume &&
      roadsVolumes.state === DataState.AVAILABLE &&
      roadSegmentsDetails.state !== DataState.LOADING &&
      roadSegmentsDetails.state !== DataState.ERROR
    ) {
      if (roadSegmentsDetails.data && find(roadSegmentsDetails.data, { segment_id: selectedRoadVolume.ftSegmentId })) {
        if (memoryStore.hasItem(MemoryStoreKeys.ROADS_SEGMENT_COUNTS)) {
          const segmentVolumes = memoryStore.getItem(MemoryStoreKeys.ROADS_SEGMENT_COUNTS);

          if (
            volumeProps.some(
              (v) =>
                v.volumeFT !== (segmentVolumes.get(v.fromToId) || 0) ||
                v.volumeTF !== (segmentVolumes.get(v.toFromId) || 0),
            )
          ) {
            updateRoadsPopupCounts.current?.(
              selectedRoadVolume,
              volumeProps.map((v) => ({
                ...v,
                volumeFT: segmentVolumes.get(v.fromToId),
                volumeTF: segmentVolumes.get(v.toFromId) || 0,
              })) as Volume[],
            );
          }
        }

        return;
      }

      getSegmentsDetails(selectedRoadVolume, measure);
      highlightSelectedSegment(selectedRoadVolume);
    }
  }, [
    memoryStore,
    measure,
    roadsVolumes.state,
    volumeProps,
    selectedRoadVolume,
    roadSegmentsDetails.state,
    roadSegmentsDetails.data,
    updateRoadsPopupCounts,
    getSegmentsDetails,
    highlightSelectedSegment,
  ]);

  // Update Roads analytics panel ref function
  useEffect(() => {
    (closeRoadsAnalyticsPanelRef as MutableRefObject<() => void>).current = closeRoadsAnalyticsPanel;
  }, [closeRoadsAnalyticsPanelRef, closeRoadsAnalyticsPanel]);

  // Close all roads popups on roads range change
  useEffect(() => {
    if (roadsRange) {
      selectRoadVolume(null);
      map.current?.fire("closeAllRoadsPopups");
    }
  }, [map, roadsRange, selectRoadVolume]);

  useEffect(() => {
    if (selectedScreenlineId || isScreelineEditorOpen) {
      closeRoadsAnalyticsPanelRef.current?.();
    }
  }, [dispatch, selectedScreenlineId, isScreelineEditorOpen, closeRoadsAnalyticsPanelRef]);

  // Set module data
  useEffect(() => {
    if (selectedFocusArea && isRoadsDataAvailable) {
      const roadsSources = getRoadsSources(roadsMetadata.data!.tileService);
      const roadsLayers = getRoadsLayers(roadsMetadata.data!.tileService.layerName);

      setRoadsModuleData((data) =>
        data
          ? data
          : {
              sources: roadsSources,
              layers: roadsLayers,
              data: {
                tileService: roadsMetadata.data!.tileService,
                roadClasses: roadsMetadata.data!.roadClasses.map((rc) => rc.id),
                selectedRoadClasses,
                maxSegmentVolumeRef,
                mapboxVolumesPopupRef,
                showRoadVolumesRef,
                blockClickEventRef,
                setVolumeProps,
                selectRoadVolume,
                selectRoadVolumeId,
                ids: getVisibleRoadIds(),
                getVisibilityStatus,
                changeShowRoadVolumes,
                filterRoadSegmentsByRoadClasses,
                filterRoadSegmentsByRange,
                volumesSize: roadsVolumes.data?.size ?? 0,
              },
            },
      );

      if (roadsMetadata.data!.tileService?.layerName) {
        setLayerName(roadsMetadata.data!.tileService.layerName);
      }
    }
  }, [
    roadsVolumes.data?.size,
    selectedRoadClasses,
    selectedFocusArea,
    isRoadsDataAvailable,
    roadsMetadata.data,
    setRoadsModuleData,
    maxSegmentVolumeRef,
    mapboxVolumesPopupRef,
    blockClickEventRef,
    setVolumeProps,
    selectRoadVolume,
    selectRoadVolumeId,
    getVisibleRoadIds,
    getVisibilityStatus,
    changeShowRoadVolumes,
    filterRoadSegmentsByRoadClasses,
    filterRoadSegmentsByRange,
  ]);

  return null;
};
