import { Add, Close, ExpandMore } from "@mui/icons-material";
import { Grid, Stack, Typography } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import { Button, DirectionIcon, IconButton } from "components_new";
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";

import { AndOrToggle } from "features/select-link/AndOrToggle";
import { ConfirmDeleteGroupDialog } from "features/select-link/ConfirmDeleteGroupDialog";
import { isLastSegmentInGroup, isLastSegmentsGroup, selectColorOrGenerate } from "features/select-link/utils";

import { FlexContainer } from "components";

import { SegmentsGroup, SelectLinkPredicateLogic, SelectedSegmentConfig } from "types";

import { segmentDirectionIconToSegmentDirection } from "utils/ui";

import { DeleteButton, ElementName, ElementsList, ListItem } from "./SelectLinkZonesConfigPanel";

const SegmentsContainer = styled("div")`
  height: auto;
  overflow-y: auto;
  height: calc(100% - 206px);
`;

const SegmentsGroupContainer = styled("div")`
  margin: auto auto 0.5rem auto;
`;

const StyledAccordion = styled(MuiAccordion)<{ selected: boolean }>`
  border-bottom: 0px;
  margin-bottom: 0.5rem;
  border: ${({ selected }) => (selected ? "1.5px solid var(--color-secondary)" : "1px solid var(--color-gray-300)")};
  border-radius: 8px;

  &.Mui-expanded {
    margin: 0;
    margin-bottom: 0.5rem;
  }
`;

const StyledAccordionSummary = styled(MuiAccordionSummary)`
  min-height: 32px;
  flex-direction: row-reverse;
  gap: 4px;
  padding-right: 8px;
  padding-left: 4px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;

  &.Mui-expanded {
    min-height: 24px;
    padding: 8px 8px 8px 4px;
  }

  & .MuiAccordionSummary-content {
    margin: 0;
    display: inline-block;

    &.Mui-expanded {
      margin: 0;
    }

    & .MuiTypography-root {
      font-size: 14px;
      font-weight: 500;
    }
  }
`;

const StyledAccordionDetails = styled(MuiAccordionDetails)`
  padding: 0 8px;
  border-top: 1px solid var(--color-gray-300);
`;

const AndOrToggleContainer = styled("div")`
  display: flex;
  justify-content: center;
`;

const ColoredCircle = styled("div")<{ color: string }>`
  width: 16px;
  height: 16px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
`;

const ListItemWrapper = styled(FlexContainer)`
  flex-direction: column;
`;

const AndOrLabel = styled("div")`
  font-size: 12px;
  font-weight: 600;
`;

const AddGroupButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  width: "100%",
}));

interface Props {
  disabled: boolean;
  activeGroupId: string;
  maxGroupId: number;
  selectedLinkGroups: SegmentsGroup[];
  predicateLogic: SelectLinkPredicateLogic;
  setActiveGroupId: Dispatch<SetStateAction<string>>;
  onAddNewSegmentsGroup: (group: SegmentsGroup, id: number) => void;
  onChangeActiveGroup: (groupId: string) => void;
  onDeleteSegmentsGroup: (groupId: string) => void;
  onZoomOnCoordinate: (lngLat: [number, number], zoom?: number) => void;
  onDeleteSelectedLink: (segment: SelectedSegmentConfig, segmentsGroupId: string) => void;
  onChangePredicateLogic: (predicateLogic: SelectLinkPredicateLogic) => void;
}

export const SelectLinkLinksConfigPanel = ({
  disabled,
  maxGroupId,
  selectedLinkGroups,
  predicateLogic,
  activeGroupId,
  setActiveGroupId,
  onAddNewSegmentsGroup,
  onChangeActiveGroup,
  onDeleteSegmentsGroup,
  onZoomOnCoordinate,
  onDeleteSelectedLink,
  onChangePredicateLogic,
}: Props) => {
  const [groupToDelete, setGroupToDelete] = useState<SegmentsGroup | null>(null);
  const [selectedSegment, setSelectedSegment] = useState<SelectedSegmentConfig | null>(null);

  const sortedGroups = useMemo(
    () => [...selectedLinkGroups].sort((groupA, groupB) => Number(groupA.groupName) - Number(groupB.groupName)),
    [selectedLinkGroups],
  );

  const handleAddNewSegmentsGroup = useCallback(() => {
    const color = selectColorOrGenerate(new Set(selectedLinkGroups.map(({ color }) => color)));
    const id = maxGroupId + 1;
    const newGroup = {
      groupName: `${id}`,
      segments: [],
      color,
    };
    setActiveGroupId(newGroup.groupName);
    onAddNewSegmentsGroup(newGroup, id);
  }, [maxGroupId, selectedLinkGroups, setActiveGroupId, onAddNewSegmentsGroup]);

  const handleDeleteSegmentsGroup = (e: React.SyntheticEvent, groupId: string) => {
    e.stopPropagation();

    const groupToDelete = selectedLinkGroups.find(({ groupName }) => groupName === groupId);

    if (groupToDelete?.segments.length) {
      setGroupToDelete(groupToDelete);
    } else {
      deleteSegmentsGroup(groupId);
    }
  };

  const updateActiveGroupId = (groupId: string): void => {
    setActiveGroupId(groupId);
    onChangeActiveGroup(groupId);
  };

  const onExpandGroup = (groupId: string, isExpanded: boolean) => {
    updateActiveGroupId(groupId);
  };

  const deleteSegmentsGroup = (groupId: string | null) => {
    if (groupId === null) return;

    onDeleteSegmentsGroup(groupId);
    if (activeGroupId === groupId) updateActiveGroupId("");

    setGroupToDelete(null);
  };

  const handleSelectSegment = useCallback(
    (link: SelectedSegmentConfig) => {
      setSelectedSegment(selectedSegment?.segmentId === link.segmentId ? null : link);
      onZoomOnCoordinate([link.lon, link.lat]);
    },
    [selectedSegment, onZoomOnCoordinate],
  );

  const handleSegmentDeleteButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    link: SelectedSegmentConfig,
    segmentsGroupId: string,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    onDeleteSelectedLink(link, segmentsGroupId);
  };

  return (
    <>
      {groupToDelete && (
        <ConfirmDeleteGroupDialog
          isOpen={Boolean(groupToDelete)}
          segmentsCount={groupToDelete.segments.length}
          onClose={() => setGroupToDelete(null)}
          deleteSegmentsGroup={() => deleteSegmentsGroup(groupToDelete.groupName)}
        />
      )}
      <>
        <AddGroupButton variant="outlined" startIcon={<Add />} onClick={handleAddNewSegmentsGroup} disabled={disabled}>
          New Segments Group
        </AddGroupButton>
        <SegmentsContainer>
          {sortedGroups.map((group) => {
            const selected = activeGroupId === group.groupName;

            return (
              <SegmentsGroupContainer key={`segments-group-${group.groupName}`}>
                <StyledAccordion
                  selected={selected}
                  expanded={true}
                  onChange={() => onExpandGroup(group.groupName, activeGroupId !== group.groupName)}
                >
                  <StyledAccordionSummary
                    id={group.groupName}
                    aria-controls={`group${group.groupName}-header`}
                    expandIcon={<ExpandMore sx={{ color: "transparent" }} />}
                    sx={{
                      backgroundColor: selected ? "#eff6ff" : "#fff",
                      transition: "all 0.3s ease-out",
                    }}
                  >
                    <Grid container alignItems={"center"} justifyContent={"space-between"}>
                      <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <ColoredCircle color={group.color} />
                        <Typography>
                          Group {group.groupName} ({group.segments.length})
                        </Typography>
                      </Stack>
                      <IconButton disabled={disabled} onClick={(e) => handleDeleteSegmentsGroup(e, group.groupName)}>
                        <Close sx={{ fontSize: 16 }} />
                      </IconButton>
                    </Grid>
                  </StyledAccordionSummary>
                  <StyledAccordionDetails>
                    {group.segments.length === 0 && (
                      <Typography fontSize={12} sx={{ padding: "4px" }}>
                        Add segments to the group by clicking them on the map and selecting a direction in the popup.
                      </Typography>
                    )}
                    {group.segments.length > 0 && (
                      <ElementsList>
                        {group.segments.map((link: SelectedSegmentConfig) => (
                          <ListItemWrapper key={`group-${group.groupName}-segment-${link.segmentId}`}>
                            <ListItem
                              key={link.segmentId}
                              isSelected={selectedSegment?.segmentId === link.segmentId}
                              onClick={() => handleSelectSegment(link)}
                            >
                              <FlexContainer>
                                <DirectionIcon
                                  direction={segmentDirectionIconToSegmentDirection[link.directionIcon]}
                                  fontSize="small"
                                  sx={{ marginRight: 1 }}
                                />
                                <ElementName>{link.streetName}</ElementName>
                              </FlexContainer>
                              {!disabled && (
                                <DeleteButton onClick={(e) => handleSegmentDeleteButtonClick(e, link, group.groupName)}>
                                  <Close sx={{ fontSize: 14 }} />
                                </DeleteButton>
                              )}
                            </ListItem>
                            {!isLastSegmentInGroup(group, link) &&
                              (sortedGroups.length === 1 ? (
                                <AndOrToggle
                                  value={predicateLogic}
                                  disabled={disabled}
                                  onChange={onChangePredicateLogic}
                                />
                              ) : (
                                <AndOrLabel>
                                  {predicateLogic === SelectLinkPredicateLogic.And ? "OR" : "AND"}
                                </AndOrLabel>
                              ))}
                          </ListItemWrapper>
                        ))}
                      </ElementsList>
                    )}
                  </StyledAccordionDetails>
                </StyledAccordion>
                {!isLastSegmentsGroup(sortedGroups, group) && (
                  <AndOrToggleContainer>
                    <AndOrToggle value={predicateLogic} disabled={disabled} onChange={onChangePredicateLogic} />
                  </AndOrToggleContainer>
                )}
              </SegmentsGroupContainer>
            );
          })}
        </SegmentsContainer>
      </>
    </>
  );
};
