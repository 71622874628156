import { FC } from "react";

import { BluePoint, FooterTableCell, HeadTableCell, Table, TableBody, TableCell, TableHead, TableRow } from "./Table";

export const LicenseDataTable: FC = () => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <HeadTableCell rowSpan={2} style={{ borderTopLeftRadius: "8px" }}>
            Data Content
          </HeadTableCell>
          <HeadTableCell colSpan={2}>Traffic</HeadTableCell>
          <HeadTableCell colSpan={4} style={{ borderTopRightRadius: "8px" }}>
            Traffic & OD
          </HeadTableCell>
        </TableRow>
        <TableRow>
          <HeadTableCell>Essentials</HeadTableCell>
          <HeadTableCell>All Access</HeadTableCell>
          <HeadTableCell>Essentials</HeadTableCell>
          <HeadTableCell>Essentials+</HeadTableCell>
          <HeadTableCell>Advanced</HeadTableCell>
          <HeadTableCell>All Access</HeadTableCell>
        </TableRow>
        <TableRow>
          <HeadTableCell>Subscription Length</HeadTableCell>
          <HeadTableCell>1 year</HeadTableCell>
          <HeadTableCell>1 year</HeadTableCell>
          <HeadTableCell>1 year</HeadTableCell>
          <HeadTableCell>1 year</HeadTableCell>
          <HeadTableCell>1 year</HeadTableCell>
          <HeadTableCell>1 year</HeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <FooterTableCell colSpan={7}>&nbsp;</FooterTableCell>
        </TableRow>
      </TableBody>
      <TableHead>
        <TableRow>
          <HeadTableCell colSpan={7}>AADT Volumes</HeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>All Roads AADT by Main Purpose (HBW, HBO, NHB)</TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Major Roads by Hour (midnight - 1am, …) & Main Purpose (HBW, HBO, NHB)</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            All Roads by Hour (midnight - 1am, …) & Detailed Purpose (H-W, W-H, W-O, O-W, O-H, H-O, NHB)
          </TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
          <TableCell>&nbsp;</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            All Roads by Hour (midnight - 1am, …) & Day Type (Monday - Thursday, Friday, Saturday, Sunday) & Detailed
            Purpose (H-W, W-H, W-O, O-W, O-H, H-O, NHB)
          </TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
        </TableRow>
        <TableRow>
          <FooterTableCell colSpan={7}>&nbsp;</FooterTableCell>
        </TableRow>
      </TableBody>

      <TableHead>
        <TableRow>
          <HeadTableCell colSpan={7}>AADT & Road Network Export</HeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>AADT & Routable OpenStreetMap Network </TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            AADT & Routable OpenStreetMap Network with modeling attributes (Lanes, Capacity, Speed, Facility Type, Area
            Type){" "}
          </TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
        </TableRow>
        <TableRow>
          <FooterTableCell colSpan={7}>&nbsp;</FooterTableCell>
        </TableRow>
      </TableBody>
      <TableHead>
        <TableRow>
          <HeadTableCell colSpan={7}>AADT Origin-Destinations</HeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Census Tract by Main Purpose (HBO, HBW, NHB)</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Block Group by Main Purpose (HBO, HBW, NHB)</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            Block Group by Part of Day (midnight - 6am, 6am - 10am, 10am - 3pm, 3pm - 7pm, 7pm - midnight) and detailed
            purpose (H-W, W-H, W-O, O-W, O-H, H-O, NHB)
          </TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
          <TableCell>&nbsp;</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            Block Group by Part of Day (midnight - 6am, 6am - 10am, 10am - 3pm, 3pm - 7pm, 7pm - midnight), Day Type
            (Monday - Thursday, Friday, Saturday, Sunday), Detailed Purpose (H-W, W-H, W-O, O-W, O-H, H-O, NHB)
          </TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
        </TableRow>
        <TableRow>
          <FooterTableCell colSpan={7}>&nbsp;</FooterTableCell>
        </TableRow>
      </TableBody>
      <TableHead>
        <TableRow>
          <HeadTableCell colSpan={7}>Origin-Destination Export </HeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>OD matrix export</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
        </TableRow>
        <TableRow>
          <FooterTableCell colSpan={7}>&nbsp;</FooterTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
