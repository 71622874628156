import { LayerManager, useLayerManager } from "@daturon/mapboxgl-layer-manager";
import { useMemoryStore } from "api/MemoryStoreContext";
import { MemoryStoreKeys } from "api/memoryStore";
import { isEqual } from "lodash";
import { AnyLayout, AnyPaint, Expression, Popup } from "mapbox-gl";
import { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  IntersectionPopupContentProps,
  ScreenlineGeometryPopupContentProps,
  ScreenlinePopupContentProps,
} from "features/screenline";
import { GeocodingSearch } from "features/search/GeocodingSearch";

import { ODPopupProps, RoadsHoverPopupProps, VolumePopupContentProps } from "components";

import { useAppDispatch, useAppSelector, useStateRef } from "hooks";

import { DataState } from "store/interfaces";
import { selectAreScreenlinesAllowed } from "store/permissionsSelectors";
import { exportActions } from "store/sections/export";

import { HoveredFlow, MapVisualizationType, RoadClassCategory, SelectLinkMode, SelectedVolume } from "types";

import { ProfileMode, profileTimeEnd, profileTimeStart } from "utils/profile";

import { MapControlPanel } from "./MapControlPanel";
import { CommonModuleComponent } from "./modules/common/CommonModuleComponent";
import { GatesModuleComponent } from "./modules/gates/GatesModuleComponent";
import { getGatesHandlers } from "./modules/gates/map-data/handlers";
import { ODModuleComponent } from "./modules/od/ODModuleComponent";
import { getODHandlers } from "./modules/od/map-data/od/handlers";
import { getODLayers } from "./modules/od/map-data/od/layers";
import { OD_ZONE_SOURCE_ID } from "./modules/od/map-data/od/sources";
import { getTopFlowsHandlers } from "./modules/od/map-data/top-flows/handlers";
import { initSVG } from "./modules/od/map-data/top-flows/sources";
import { RoadsModuleComponent } from "./modules/roads/RoadsModuleComponent";
import { getRoadHandlers } from "./modules/roads/map-data/handlers";
import {
  LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID,
  ROADS_HAIRLINES_LAYER_ID,
  ROADS_VOLUMES_LAYER_ID,
  getRoadsLayers,
} from "./modules/roads/map-data/layers";
import { ScreenlinesModuleComponent, getScreenlineHandler } from "./modules/screenlines";
import { SelectLinkModuleComponent } from "./modules/select-link/SelectLinkModuleComponent";
import { getSelectLinkZonesHandlers } from "./modules/select-link/map-data/zones/handlers";

type LayersSettings = Record<
  string,
  { filter: Expression | undefined; layout: AnyLayout | undefined; paint: AnyPaint | undefined }
>;

interface ModuleManagerProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  draw?: MutableRefObject<any | null>;
  ODPopupRef: MutableRefObject<HTMLDivElement | null>;
  setODPopupPropsRef: MutableRefObject<Dispatch<SetStateAction<ODPopupProps | null>> | null>;
  roadsPopupRef: MutableRefObject<HTMLDivElement | null>;
  setRoadsPopupRef: MutableRefObject<Dispatch<SetStateAction<RoadsHoverPopupProps | null>> | null>;
  roadsVolumesPopupRef: MutableRefObject<HTMLDivElement | null>;
  setRoadsVolumesPopupRef: MutableRefObject<Dispatch<SetStateAction<VolumePopupContentProps | null>> | null>;
  screenlinePopupRef?: MutableRefObject<HTMLDivElement | null>;
  setScreenlinePopupPropsRef?: MutableRefObject<Dispatch<SetStateAction<ScreenlinePopupContentProps | null>> | null>;
  intersectionPopupRef?: MutableRefObject<HTMLDivElement | null>;
  setIntersectionPopupPropsRef?: MutableRefObject<Dispatch<
    SetStateAction<IntersectionPopupContentProps | null>
  > | null>;
  screenlinGeometryPopupRef?: MutableRefObject<HTMLDivElement | null>;
  setScreenlineGeometryPopupPropsRef?: MutableRefObject<Dispatch<
    SetStateAction<ScreenlineGeometryPopupContentProps | null>
  > | null>;
  setVolumesLoading: Dispatch<SetStateAction<boolean>>;
}

export interface ModuleData {
  sources: {
    id: string;
    source: mapboxgl.AnySourceData;
  }[];
  layers: mapboxgl.Layer[];
  data?: any;
}

export const ModuleManager = ({
  map,
  draw,
  ODPopupRef,
  setODPopupPropsRef,
  roadsPopupRef,
  setRoadsPopupRef,
  roadsVolumesPopupRef,
  setRoadsVolumesPopupRef,
  screenlinePopupRef,
  setScreenlinePopupPropsRef,
  intersectionPopupRef,
  setIntersectionPopupPropsRef,
  screenlinGeometryPopupRef,
  setScreenlineGeometryPopupPropsRef,
  setVolumesLoading,
}: ModuleManagerProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const memoryStore = useMemoryStore();

  const [activeMode, setActiveMode] = useState<MapVisualizationType | null>(null);
  const [commonModuleData, setCommonModuleData] = useState<ModuleData | null>(null);
  const [ODModuleData, setODModuleData] = useState<ModuleData | null>(null);
  const [gatesModuleData, setGatesModuleData] = useState<ModuleData | null>(null);
  const [roadsModuleData, setRoadsModuleData] = useState<ModuleData | null>(null);
  const [screenlinesModuleData, setScreenlinesModuleData] = useState<ModuleData | null>(null);
  const [hoveredTopFlow, setHoveredTopFlow] = useState<HoveredFlow | null>(null);
  const [selectLinkMode, setSelectLinkMode] = useState<SelectLinkMode>(SelectLinkMode.LINKS);
  const [selectLinkConfigLinksModuleData, setSelectLinkConfigLinksModuleData] = useState<ModuleData | null>(null);
  const [selectLinkConfigZonesModuleData, setSelectLinkConfigZonesModuleData] = useState<ModuleData | null>(null);
  const [selectLinkResultsModuleData, setSelectLinkResultsModuleData] = useState<ModuleData | null>(null);

  const focusAreas = useAppSelector((state) => state.analytics.focusAreasAndDatasets);
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const selectedFocusAreaId = useAppSelector((state) => state.global.selectedFocusAreaId);
  const isDataset = Boolean(selectedFocusArea?.datasetId);
  const areScreenlinesAllowed = useAppSelector((state) =>
    selectAreScreenlinesAllowed(state, selectedFocusArea?.licensedAreaId),
  );
  const measure = useAppSelector((state) => state.filters.measure);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);

  const colorScheme = useAppSelector((state) => state.map.colorScheme);
  const newExport = useAppSelector((state) => state.export.newExport);

  const mode = useMemo(() => searchParams.get("mode") as MapVisualizationType, [searchParams]);

  const layerManagerRef: MutableRefObject<LayerManager | null> = useRef(null);
  const cleanHandlers: MutableRefObject<(() => void)[]> = useRef([]);
  const mapboxODZoneHoverPopupRef = useRef<Popup | null>(null);
  const updateODModeCounts = useRef<(() => void) | null>(null);
  const setHighlightedTopFlowRef = useRef<any>(null);
  const updateRoadsModeCounts = useRef<(() => void) | null>(null);
  const updateRoadsPopupCounts = useRef<((selectedVolume: SelectedVolume | null, volumesProps?: any[]) => void) | null>(
    null,
  );
  const setSelectedIntersectionIdRef = useRef<((intersection: string | null) => void) | null>(null);
  const setSelectedScreenlineIdRef = useRef<((screenlineId: string | null) => void) | null>(null);
  const closeRoadsAnalyticsPanelRef = useRef<() => void | null>(null);
  const closeScreenlineAnalyticsPanelRef = useRef<(() => void) | null>(null);
  const stubRef = useRef(null);
  const measureRef = useStateRef(measure);

  const removeAllUncommonSourcesAndLayers = useCallback(() => {
    if (layerManagerRef.current && commonModuleData) {
      const commonSources = commonModuleData.sources.map((s) => s.id);
      const commonLayers = commonModuleData.layers.map((l) => l.id);

      const uncommonSourceIds = layerManagerRef.current
        .getActiveCustomSourceIds()
        .filter((sourceId) => !commonSources.includes(sourceId));
      const uncommonLayerIds = layerManagerRef.current
        .getActiveCustomLayerIds()
        .filter((layerId) => !commonLayers.includes(layerId));

      layerManagerRef.current.removeLayers(uncommonLayerIds);
      layerManagerRef.current.removeSources(uncommonSourceIds);
    }
  }, [commonModuleData]);

  const cleanAfterSwitchingMode = useCallback(() => {
    if (cleanHandlers.current.length) {
      cleanHandlers.current.forEach((clean) => clean());
      cleanHandlers.current = [];
    }

    removeAllUncommonSourcesAndLayers();
  }, [removeAllUncommonSourcesAndLayers]);

  useEffect(() => {
    if (timePeriod) {
      setActiveMode(null);
      setRoadsModuleData(null);
      setODModuleData(null);
      setScreenlinesModuleData(null);
    }
  }, [timePeriod]);

  // Clean up after unmount
  useEffect(() => {
    return () => {
      if (cleanHandlers.current.length) {
        cleanHandlers.current.forEach((clean) => clean());
        cleanHandlers.current = [];
      }

      layerManagerRef.current = null;
    };
  }, []);

  // Redirect to dashboard if no selectedArea (dataset has been deleted)
  useEffect(() => {
    if (focusAreas.state === DataState.AVAILABLE && selectedFocusAreaId && !selectedFocusArea) {
      toast.error("The dataset has been deleted", {
        position: toast.POSITION.TOP_CENTER,
      });
      navigate("/dashboard");
      toast.clearWaitingQueue();
    }
  }, [focusAreas.state, selectedFocusArea, selectedFocusAreaId, navigate]);

  // Clean up after switching mode
  useEffect(() => {
    if (mode !== activeMode && activeMode !== MapVisualizationType.SELECT_LINK) {
      cleanAfterSwitchingMode();
    }
  }, [mode, activeMode, cleanAfterSwitchingMode]);

  // Update feature state on color scheme change
  useEffect(() => {
    if (updateODModeCounts.current) {
      updateODModeCounts.current();
    }
  }, [colorScheme]);

  // Render OD mode
  useEffect(() => {
    if (mode === MapVisualizationType.OD && commonModuleData && ODModuleData && map.current && activeMode !== mode) {
      if (isDataset && !gatesModuleData) return;

      /* eslint-disable react-hooks/rules-of-hooks */
      layerManagerRef.current = useLayerManager(
        map.current,
        [...ODModuleData.sources, ...commonModuleData.sources, ...(isDataset ? gatesModuleData!.sources : [])],
        [...ODModuleData.layers, ...commonModuleData.layers, ...(isDataset ? gatesModuleData!.layers : [])],
      );
      /* eslint-enable react-hooks/rules-of-hooks */

      const { cleanODHandlers, updateODCountsAndLayersVisibility } = getODHandlers(
        map.current!,
        ODModuleData.data?.ODTileservice,
        ODModuleData.data?.ids,
        ODModuleData.data?.ODCountsRef,
        gatesModuleData?.data?.gates?.data || [],
        ODModuleData.data?.ODSelectedZoneRef,
        ODModuleData.data?.queryTypeRef,
        ODModuleData.data?.colorSchemeRef,
        ODPopupRef,
        mapboxODZoneHoverPopupRef,
        ODModuleData.data?.ODZoningLevelBlockedZoom,
        setODPopupPropsRef,
        ODModuleData.data?.showZoneCountsRef,
        ODModuleData.data?.handleSelectZone,
        ODModuleData.data?.handleSetColorScale,
        ODModuleData.data?.handleSetZoningLevel,
      );

      cleanHandlers.current.push(cleanODHandlers);

      const { cleanTopFlowsHandlers, setHighlightedTopFlow } = getTopFlowsHandlers(
        map.current,
        ODModuleData.data?.zoningLevels,
        ODModuleData.data?.ODTileservice.layers[0].idField,
        ODModuleData.data?.queryTypeRef,
        mapboxODZoneHoverPopupRef,
        ODPopupRef,
        setODPopupPropsRef,
        setHoveredTopFlow,
      );

      setHighlightedTopFlowRef.current = setHighlightedTopFlow;
      cleanHandlers.current.push(cleanTopFlowsHandlers);

      if (isDataset) {
        const { cleanGatesHandlers } = getGatesHandlers(
          map.current,
          gatesModuleData?.data?.gates?.data || null,
          ODModuleData.data?.ODCountsRef,
          ODModuleData.data?.ODSelectedZoneRef,
          ODModuleData.data?.queryTypeRef,
          ODPopupRef,
          mapboxODZoneHoverPopupRef,
          setODPopupPropsRef,
        );

        cleanHandlers.current.push(cleanGatesHandlers);
      }

      const settings = {} as LayersSettings;

      getODLayers(ODModuleData.data?.ODTileservice).zoningLayers.forEach((layer) => {
        settings[layer.id] = {
          filter:
            layer.level && layer.idField
              ? ["in", layer.idField, ...Array.from(ODModuleData.data?.ids.get(layer.level).keys())]
              : undefined,
          layout: {
            ...layer.layout,
          },
          paint: layer.paint || undefined,
        };
      });

      initSVG(map.current);

      const beforeLayer = map.current?.getLayer("admin_country") || map.current?.getLayer("road-label");

      layerManagerRef.current
        .renderOrderedLayers(
          [...ODModuleData.layers, ...commonModuleData.layers, ...(isDataset ? gatesModuleData!.layers : [])].map(
            (l) => l.id,
          ),
          settings,
          beforeLayer?.id,
        )
        .then(() => {
          setActiveMode(mode);
        });

      updateODModeCounts.current = updateODCountsAndLayersVisibility;
      updateODModeCounts.current();
    }
  }, [
    map,
    activeMode,
    mode,
    isDataset,
    commonModuleData,
    ODModuleData,
    gatesModuleData,
    ODPopupRef,
    setODPopupPropsRef,
    setActiveMode,
    cleanAfterSwitchingMode,
    dispatch,
  ]);

  // Render Roads mode
  useEffect(() => {
    if (
      mode === MapVisualizationType.ROADS &&
      commonModuleData &&
      roadsModuleData &&
      map.current &&
      activeMode !== mode
    ) {
      if (isDataset && !gatesModuleData && !memoryStore.hasItem(MemoryStoreKeys.ROADS_SEGMENT_IDS_BY_ROAD_CLASS)) {
        return;
      }

      profileTimeStart(ProfileMode.ROADS_MODE_LAYER_MANAGER_OPERATIONS);

      /* eslint-disable react-hooks/rules-of-hooks */
      layerManagerRef.current = useLayerManager(
        map.current,
        [
          ...roadsModuleData.sources,
          ...commonModuleData.sources,
          ...(isDataset ? gatesModuleData!.sources : []),
          ...(screenlinesModuleData?.sources || []),
        ],
        [
          ...roadsModuleData.layers,
          ...commonModuleData.layers,
          ...(isDataset ? gatesModuleData!.layers : []),
          ...(screenlinesModuleData?.layers || []),
        ],
      );
      /* eslint-enable react-hooks/rules-of-hooks */

      if (isDataset) {
        const { cleanGatesHandlers } = getGatesHandlers(
          map.current,
          gatesModuleData?.data?.gates?.data || null,
          { current: { zones: new Map(), gates: new Map() } },
          { current: null },
          { current: undefined as any },
          ODPopupRef,
          mapboxODZoneHoverPopupRef,
          setODPopupPropsRef,
          roadsModuleData.data.tileService.layerName,
        );

        cleanHandlers.current.push(cleanGatesHandlers);
      }

      const { cleanRoadsHandlers, updateRoadsVolumes } = getRoadHandlers(
        map.current,
        measureRef,
        roadsModuleData.data.tileService,
        roadsModuleData.data.volumesSize,
        roadsModuleData.data.maxSegmentVolumeRef,
        roadsPopupRef,
        roadsVolumesPopupRef,
        roadsModuleData.data.mapboxVolumesPopupRef,
        setRoadsPopupRef,
        roadsModuleData.data.blockClickEventRef,
        roadsModuleData.data.setVolumeProps,
        setRoadsVolumesPopupRef,
        updateRoadsPopupCounts,
        setVolumesLoading,
        roadsModuleData.data.selectRoadVolume,
        roadsModuleData.data.selectRoadVolumeId,
      );

      cleanHandlers.current.push(cleanRoadsHandlers);

      if (
        screenlinesModuleData &&
        draw?.current &&
        screenlinePopupRef &&
        setScreenlinePopupPropsRef &&
        intersectionPopupRef &&
        setIntersectionPopupPropsRef &&
        screenlinGeometryPopupRef &&
        setScreenlineGeometryPopupPropsRef
      ) {
        const { cleanScreenlineHandlers, setClickedScreenline, setClickedIntersection } = getScreenlineHandler(
          map.current,
          draw.current,
          screenlinePopupRef,
          setScreenlinePopupPropsRef,
          intersectionPopupRef,
          setIntersectionPopupPropsRef,
          screenlinGeometryPopupRef,
          setScreenlineGeometryPopupPropsRef,
          screenlinesModuleData.data.mapboxScreenlineHoverPopupRef,
          screenlinesModuleData.data.mapboxIntersectionClickPopupRef,
          screenlinesModuleData.data.mapboxGeometryClickPopupRef,
          screenlinesModuleData.data.selectedScreenlineIdRef,
          screenlinesModuleData.data.selectedIntersectionIdRef,
          screenlinesModuleData.data.isEditScreenlineModeRef,
          screenlinesModuleData.data.screenlinesRef,
          screenlinesModuleData.data.screenlineValidationDataRef,
          screenlinesModuleData.data.isDrawModeRef,
          screenlinesModuleData.data.setSelectedScreenlineId,
          screenlinesModuleData.data.setSelectedIntersectionId,
          screenlinesModuleData.data.handleSetDraftFeature,
          screenlinesModuleData.data.handleSetDrawMode,
        );

        setSelectedIntersectionIdRef.current = setClickedIntersection;
        setSelectedScreenlineIdRef.current = setClickedScreenline;

        cleanHandlers.current.push(cleanScreenlineHandlers);
      }

      const settings = {} as LayersSettings;

      getRoadsLayers(roadsModuleData.data.tileService.layerName).forEach((layer) => {
        if (roadsModuleData.data?.tileService) {
          settings[layer.id] = {
            filter: undefined,
            layout: {
              ...layer.layout,
              visibility: roadsModuleData.data.getVisibilityStatus(layer.id),
            },
            paint: layer.paint || undefined,
          };
        }
      });

      const beforeLayer = map.current?.getLayer("admin_country") || map.current?.getLayer("road-label");

      profileTimeEnd(ProfileMode.ROADS_MODE_LAYER_MANAGER_OPERATIONS);
      profileTimeStart(ProfileMode.ROADS_MODE_PUT_LAYERS_ON_MAP);

      const status = layerManagerRef.current
        .renderOrderedLayers(
          [
            ...roadsModuleData.layers,
            ...(screenlinesModuleData?.layers || []),
            ...commonModuleData.layers,
            ...(isDataset ? gatesModuleData!.layers : []),
          ].map((l) => l.id),
          settings,
          beforeLayer?.id,
        )
        .then(() => {
          setActiveMode(mode);
        });

      status
        .then(() => {
          if (
            roadsModuleData.data?.filterRoadSegmentsByRoadClasses &&
            roadsModuleData.data.roadClasses &&
            roadsModuleData.data.selectedRoadClasses &&
            !isEqual(roadsModuleData.data.roadClasses, roadsModuleData.data.selectedRoadClasses)
          ) {
            roadsModuleData.data.filterRoadSegmentsByRoadClasses(roadsModuleData.data.selectedRoadClasses);
          }
          updateRoadsModeCounts.current = updateRoadsVolumes;
          updateRoadsModeCounts.current();
        })
        .finally(() => {
          profileTimeEnd(ProfileMode.ROADS_MODE_PUT_LAYERS_ON_MAP);

          setRoadsModuleData({
            ...roadsModuleData,
            data: { ...roadsModuleData.data, ids: null },
          });
        });
    }
  }, [
    memoryStore,
    activeMode,
    mode,
    isDataset,
    commonModuleData,
    roadsModuleData,
    gatesModuleData,
    screenlinesModuleData,
    setActiveMode,
    setVolumesLoading,
    map,
    draw,
    roadsPopupRef,
    setRoadsPopupRef,
    roadsVolumesPopupRef,
    setRoadsVolumesPopupRef,
    ODPopupRef,
    setODPopupPropsRef,
    cleanAfterSwitchingMode,
    screenlinePopupRef,
    setScreenlinePopupPropsRef,
    intersectionPopupRef,
    setIntersectionPopupPropsRef,
    screenlinGeometryPopupRef,
    setScreenlineGeometryPopupPropsRef,
    measureRef,
    dispatch,
  ]);

  // Switch to Select Link mode
  useEffect(() => {
    if (activeMode !== MapVisualizationType.SELECT_LINK && mode === null) {
      setActiveMode(MapVisualizationType.SELECT_LINK);
    }
  }, [mode, activeMode]);

  // Render Select Link mode (Select Link config)
  // @TODO implement Select Link mode
  useEffect(() => {
    if (
      commonModuleData &&
      selectLinkConfigLinksModuleData &&
      map.current &&
      activeMode === MapVisualizationType.SELECT_LINK &&
      mode === null &&
      memoryStore.hasItem(MemoryStoreKeys.ROADS_SEGMENT_IDS_BY_ROAD_CLASS)
    ) {
      cleanAfterSwitchingMode();

      /* eslint-disable react-hooks/rules-of-hooks */
      layerManagerRef.current = useLayerManager(
        map.current,
        [...selectLinkConfigLinksModuleData.sources, ...commonModuleData.sources],
        [...selectLinkConfigLinksModuleData.layers, ...commonModuleData.layers],
      );
      /* eslint-enable react-hooks/rules-of-hooks */

      const { cleanRoadsHandlers, updateRoadsVolumes } = getRoadHandlers(
        map.current,
        measureRef,
        selectLinkConfigLinksModuleData.data.roadsTileService,
        selectLinkConfigLinksModuleData.data.volumesSize,
        selectLinkConfigLinksModuleData.data.maxSegmentVolumeRef,
        roadsPopupRef,
        roadsVolumesPopupRef,
        selectLinkConfigLinksModuleData.data.mapboxVolumesPopupRef,
        setRoadsPopupRef,
        selectLinkConfigLinksModuleData.data.blockClickEventRef,
        selectLinkConfigLinksModuleData.data.setVolumeProps,
        setRoadsVolumesPopupRef,
        updateRoadsPopupCounts,
        setVolumesLoading,
        selectLinkConfigLinksModuleData.data.selectRoadVolume,
        undefined,
        selectLinkConfigLinksModuleData.data.selectRoadLink,
        true,
      );

      cleanHandlers.current.push(cleanRoadsHandlers);

      const settings = {} as LayersSettings;

      getRoadsLayers(selectLinkConfigLinksModuleData.data.roadsTileService.layerName).forEach((layer) => {
        if (selectLinkConfigLinksModuleData.data?.roadsTileService) {
          settings[layer.id] = {
            filter:
              layer.id === ROADS_HAIRLINES_LAYER_ID
                ? [
                    "in",
                    selectLinkConfigLinksModuleData.data.roadsTileService.fromToSegmentIdField,
                    ...selectLinkConfigLinksModuleData.data.ids,
                  ]
                : undefined,
            layout: {
              ...layer.layout,
              visibility: selectLinkConfigLinksModuleData.data.getVisibilityStatus(layer.id),
            },
            paint: layer.paint || undefined,
          };

          if (layer.id === LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID) {
            settings[layer.id].filter = [
              "in",
              selectLinkConfigLinksModuleData.data.roadsTileService.fromToSegmentIdField,
              ...(memoryStore.getItem(MemoryStoreKeys.ROADS_SEGMENT_IDS_BY_ROAD_CLASS)[
                RoadClassCategory.LIMITED_ACCESS
              ] || []),
            ];
          }

          if (layer.id === ROADS_VOLUMES_LAYER_ID) {
            settings[layer.id].filter = [
              "in",
              selectLinkConfigLinksModuleData.data.roadsTileService.fromToSegmentIdField,
              ...(memoryStore.getItem(MemoryStoreKeys.ROADS_SEGMENT_IDS_BY_ROAD_CLASS)[RoadClassCategory.OTHER] || []),
            ];
          }
        }
      });

      getODLayers(selectLinkConfigLinksModuleData.data?.ODTileservice, true).selectLinkSelectedZonesLayers.forEach(
        (layer) => {
          settings[layer.id] = {
            filter:
              layer.level && layer.idField
                ? [
                    "in",
                    layer.idField,
                    ...Array.from(selectLinkConfigLinksModuleData.data?.zoneIds.get(layer.level).keys()),
                  ]
                : undefined,
            layout: {
              ...layer.layout,
            },
            paint: layer.paint || undefined,
          };
        },
      );

      const beforeLayer = map.current?.getLayer("admin_country") || map.current?.getLayer("road-label");

      const status = layerManagerRef.current.renderOrderedLayers(
        [...selectLinkConfigLinksModuleData.layers, ...commonModuleData.layers].map((l) => l.id),
        settings,
        beforeLayer?.id,
      );

      status.then(() => {
        selectLinkConfigLinksModuleData.data.updateFeatureStateWithSelectedODs(OD_ZONE_SOURCE_ID);

        updateRoadsModeCounts.current = updateRoadsVolumes;
        updateRoadsModeCounts.current();

        if (
          selectLinkConfigLinksModuleData.data.segmentGroups &&
          selectLinkConfigLinksModuleData.data.segmentGroups.length > 0
        ) {
          selectLinkConfigLinksModuleData.data.updateFeatureStateWithSelectedLinks(
            selectLinkConfigLinksModuleData.data.segmentGroups,
          );
        }

        if (selectLinkConfigLinksModuleData.data.roadClasses) {
          selectLinkConfigLinksModuleData.data.filterRoadSegmentsByRoadClasses(
            selectLinkConfigLinksModuleData.data.roadClasses,
          );
        }
      });
    }
  }, [
    memoryStore,
    mode,
    activeMode,
    commonModuleData,
    selectLinkConfigLinksModuleData,
    map,
    roadsPopupRef,
    setRoadsPopupRef,
    roadsVolumesPopupRef,
    setRoadsVolumesPopupRef,
    measureRef,
    setVolumesLoading,
    cleanAfterSwitchingMode,
  ]);

  // Render Select Link mode (Select Zones config)
  // @TODO implement Select Link mode
  useEffect(() => {
    if (
      commonModuleData &&
      selectLinkConfigZonesModuleData &&
      map.current &&
      activeMode === MapVisualizationType.SELECT_LINK &&
      mode === null
    ) {
      cleanAfterSwitchingMode();

      /* eslint-disable react-hooks/rules-of-hooks */
      layerManagerRef.current = useLayerManager(
        map.current,
        [...selectLinkConfigZonesModuleData.sources, ...commonModuleData.sources],
        [...selectLinkConfigZonesModuleData.layers, ...commonModuleData.layers],
      );
      /* eslint-enable react-hooks/rules-of-hooks */

      const { cleanODHandlers, updateODCountsAndLayersVisibility } = getSelectLinkZonesHandlers(
        map.current!,
        selectLinkConfigZonesModuleData.data?.ODTileservice,
        selectLinkConfigZonesModuleData.data?.ids,
        selectLinkConfigZonesModuleData.data?.ODCountsRef,
        selectLinkConfigZonesModuleData.data?.queryTypeRef,
        selectLinkConfigZonesModuleData.data?.colorSchemeRef,
        ODPopupRef,
        mapboxODZoneHoverPopupRef,
        selectLinkConfigZonesModuleData.data?.ODZoningLevelBlockedZoom,
        setODPopupPropsRef,
        selectLinkConfigZonesModuleData.data?.showZoneCountsRef,
        selectLinkConfigZonesModuleData.data?.handleSetColorScale,
        selectLinkConfigZonesModuleData.data?.handleSetZoningLevel,
        selectLinkConfigZonesModuleData.data?.setSelectedZone,
      );

      cleanHandlers.current.push(cleanODHandlers);

      const settings = {} as LayersSettings;

      getODLayers(selectLinkConfigZonesModuleData.data?.ODTileservice, true).zoningLayers.forEach((layer) => {
        settings[layer.id] = {
          filter:
            layer.level && layer.idField
              ? ["in", layer.idField, ...Array.from(selectLinkConfigZonesModuleData.data?.ids.get(layer.level).keys())]
              : undefined,
          layout: {
            ...layer.layout,
          },
          paint: layer.paint || undefined,
        };
      });

      const beforeLayer = map.current?.getLayer("admin_country") || map.current?.getLayer("road-label");

      const status = layerManagerRef.current.renderOrderedLayers(
        [...selectLinkConfigZonesModuleData.layers, ...commonModuleData.layers].map((l) => l.id),
        settings,
        beforeLayer?.id,
      );

      status.then(() => {
        updateODModeCounts.current = updateODCountsAndLayersVisibility;
        updateODModeCounts.current();

        selectLinkConfigZonesModuleData.data.updateFeatureStateWithSelectedODs(OD_ZONE_SOURCE_ID);
      });
    }
  }, [
    mode,
    activeMode,
    commonModuleData,
    selectLinkConfigZonesModuleData,
    map,
    ODPopupRef,
    cleanAfterSwitchingMode,
    setODPopupPropsRef,
  ]);

  // Render Select Link mode (Results)
  // @TODO implement Select Link mode
  useEffect(() => {
    if (
      commonModuleData &&
      selectLinkResultsModuleData &&
      map.current &&
      activeMode === MapVisualizationType.SELECT_LINK &&
      mode === null &&
      memoryStore.hasItem(MemoryStoreKeys.ROADS_SEGMENT_IDS_BY_ROAD_CLASS)
    ) {
      cleanAfterSwitchingMode();

      /* eslint-disable react-hooks/rules-of-hooks */
      layerManagerRef.current = useLayerManager(
        map.current,
        [...selectLinkResultsModuleData.sources, ...commonModuleData.sources],
        [...selectLinkResultsModuleData.layers, ...commonModuleData.layers],
      );
      /* eslint-enable react-hooks/rules-of-hooks */

      const { cleanRoadsHandlers, updateRoadsVolumes } = getRoadHandlers(
        map.current,
        measureRef,
        selectLinkResultsModuleData.data.roadsTileService,
        selectLinkResultsModuleData.data.volumesSize,
        selectLinkResultsModuleData.data.maxSegmentVolumeRef,
        roadsPopupRef,
        stubRef,
        selectLinkResultsModuleData.data.mapboxVolumesPopupRef,
        setRoadsPopupRef,
        selectLinkResultsModuleData.data.blockClickEventRef,
        selectLinkResultsModuleData.data.setVolumeProps,
        setRoadsVolumesPopupRef,
        updateRoadsPopupCounts,
        setVolumesLoading,
        undefined,
        undefined,
        undefined,
        true,
        true,
      );

      cleanHandlers.current.push(cleanRoadsHandlers);

      const settings = {} as LayersSettings;

      getRoadsLayers(selectLinkResultsModuleData.data.roadsTileService.layerName, true).forEach((layer) => {
        if (selectLinkResultsModuleData.data?.roadsTileService) {
          settings[layer.id] = {
            filter: [
              "in",
              selectLinkResultsModuleData.data.roadsTileService.fromToSegmentIdField,
              ...selectLinkResultsModuleData.data.ids,
            ],
            layout: {
              ...layer.layout,
              visibility: selectLinkResultsModuleData.data.getVisibilityStatus(layer.id),
            },
            paint: layer.paint || undefined,
          };
        }
      });

      getODLayers(selectLinkResultsModuleData.data?.ODTileservice, true).selectLinkSelectedZonesLayers.forEach(
        (layer) => {
          settings[layer.id] = {
            filter:
              layer.level && layer.idField
                ? [
                    "in",
                    layer.idField,
                    ...Array.from(selectLinkResultsModuleData.data?.zoneIds.get(layer.level).keys()),
                  ]
                : undefined,
            layout: {
              ...layer.layout,
            },
            paint: layer.paint || undefined,
          };
        },
      );

      const beforeLayer = map.current?.getLayer("admin_country") || map.current?.getLayer("road-label");

      const status = layerManagerRef.current.renderOrderedLayers(
        [...selectLinkResultsModuleData.layers, ...commonModuleData.layers].map((l) => l.id),
        settings,
        beforeLayer?.id,
      );

      status.then(() => {
        selectLinkResultsModuleData.data.updateFeatureStateWithSelectedODs(OD_ZONE_SOURCE_ID);

        updateRoadsModeCounts.current = updateRoadsVolumes;
        updateRoadsModeCounts.current();

        if (
          selectLinkResultsModuleData.data.segmentGroups &&
          selectLinkResultsModuleData.data.segmentGroups.length > 0
        ) {
          selectLinkResultsModuleData.data.updateFeatureStateWithSelectedLinks(
            selectLinkResultsModuleData.data.segmentGroups,
          );
        }

        if (selectLinkResultsModuleData.data.roadClasses) {
          selectLinkResultsModuleData.data.filterRoadSegmentsByRoadClasses(
            selectLinkResultsModuleData.data.roadClasses,
          );
        }
      });
    }
  }, [
    memoryStore,
    mode,
    roadsPopupRef,
    activeMode,
    commonModuleData,
    selectLinkResultsModuleData,
    map,
    setRoadsPopupRef,
    setRoadsVolumesPopupRef,
    measureRef,
    cleanAfterSwitchingMode,
    setVolumesLoading,
  ]);

  // Handle new export job
  useEffect(() => {
    if (newExport.data) {
      toast.success("New export job added", {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(exportActions.clearNewExport());
      toast.clearWaitingQueue();
    }
  }, [newExport.data, dispatch]);

  // Handle new export job error
  useEffect(() => {
    if (newExport.error) {
      toast.error("Failed to add new export job", {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(exportActions.clearNewExport());
      toast.clearWaitingQueue();
    }
  }, [newExport.error, dispatch]);

  return (
    <>
      <CommonModuleComponent setCommonModuleData={setCommonModuleData} />
      {mode === MapVisualizationType.OD ? (
        <ODModuleComponent
          map={map}
          setODModuleData={setODModuleData}
          updateODModeCounts={updateODModeCounts}
          layerManagerRef={layerManagerRef}
          hoveredTopFlow={hoveredTopFlow}
          setHoveredTopFlow={setHighlightedTopFlowRef.current}
        />
      ) : null}
      {mode === MapVisualizationType.ROADS ? (
        <>
          <RoadsModuleComponent
            map={map}
            layerManagerRef={layerManagerRef}
            setRoadsModuleData={setRoadsModuleData}
            updateRoadsModeCounts={updateRoadsModeCounts}
            updateRoadsPopupCounts={updateRoadsPopupCounts}
            closeRoadsAnalyticsPanelRef={closeRoadsAnalyticsPanelRef}
          />
          {areScreenlinesAllowed && draw && (
            <ScreenlinesModuleComponent
              map={map}
              draw={draw}
              isModuleLoaded={activeMode === MapVisualizationType.ROADS}
              layerManagerRef={layerManagerRef}
              setScreenlinesModuleData={setScreenlinesModuleData}
              setSelectedScreenlineId={setSelectedScreenlineIdRef.current}
              setSelectedIntersectionId={setSelectedIntersectionIdRef.current}
              closeScreenlineAnalyticsPanelRef={closeScreenlineAnalyticsPanelRef}
            />
          )}
        </>
      ) : null}
      {isDataset ? <GatesModuleComponent setGatesModuleData={setGatesModuleData} /> : null}

      {activeMode === MapVisualizationType.SELECT_LINK ? (
        <SelectLinkModuleComponent
          map={map}
          selectLinkMode={selectLinkMode}
          layerManagerRef={layerManagerRef}
          selectLinkConfigLinksModuleData={selectLinkConfigLinksModuleData}
          isExportPermitted={
            selectLinkConfigLinksModuleData?.data?.isExportPermitted ||
            selectLinkConfigZonesModuleData?.data?.isExportPermitted ||
            selectLinkResultsModuleData?.data?.isExportPermitted
          }
          selectLinkConfigZonesModuleData={selectLinkConfigZonesModuleData}
          setSelectLinkConfigLinksModuleData={setSelectLinkConfigLinksModuleData}
          setSelectLinkConfigZonesModuleData={setSelectLinkConfigZonesModuleData}
          setSelectLinkResultsModuleData={setSelectLinkResultsModuleData}
          deleteSelectedZone={selectLinkConfigZonesModuleData?.data?.deleteSelectedZone}
          updateODModeCounts={updateODModeCounts}
          updateRoadsModeCounts={updateRoadsModeCounts}
          setSelectLinkMode={setSelectLinkMode}
        />
      ) : null}
      <MapControlPanel
        map={map}
        roadsModuleData={roadsModuleData || selectLinkConfigLinksModuleData || selectLinkResultsModuleData}
        ODModuleData={ODModuleData || selectLinkConfigZonesModuleData}
        selectLinkMode={activeMode === MapVisualizationType.SELECT_LINK ? selectLinkMode : null}
        setSelectLinkMode={setSelectLinkMode}
        screenlinesModuleData={screenlinesModuleData}
        isScreenlineModuleLoaded={activeMode === MapVisualizationType.ROADS}
      />
      <GeocodingSearch map={map} />
    </>
  );
};
